import * as admin from 'firebase-admin';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, getFirestore, query, where, getDocs, limit as firestoreLimit, doc, addDoc, Timestamp, orderBy } from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL
  } from "firebase/storage";
import axios from "axios";
import Carpooling from '../models/CarpoolingModel';
const serviceAccount = process.env.ACC_SERVICE ?? require('../service-account.json');

const firebaseConfig = {
    apiKey: "AIzaSyCrHFl6SWFGdt37XoWs-U0rn5U53aznK-w",
    authDomain: "cidadeinterativa-55ccd.firebaseapp.com",
    databaseURL: "https://cidadeinterativa-55ccd-default-rtdb.firebaseio.com",
    projectId: "cidadeinterativa-55ccd",
    storageBucket: "cidadeinterativa-55ccd.appspot.com",
    messagingSenderId: "38415114161",
    appId: "1:38415114161:web:fee3422c82024ea0d72790",
    measurementId: "G-95QLH9BJNJ"
  };

const app = initializeApp(serviceAccount);
const db = getFirestore(app);
const storage = getStorage(app);


  

class CarpoolingServices{


    async GetAllCarpooling(limit: number): Promise<Carpooling[] | null> {

        

        try {
            const carpoolingRef = collection(db, "Carpooling");
            
            const q = query(carpoolingRef, orderBy("dateTime", "desc"));
            const querySnapshot = await getDocs(q);
            
            if (querySnapshot.empty) {
                return null;
            }

            // Use Promise.all to wait for all promises to resolve
            const carpoolings = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                

                return {
                    id: doc.id,
                    driverUid: data.driverUid,  // Parece correto assumindo que 'userId' é o UID do motorista
                    carLicensePlate: data.carLicensePlate,  // Suposição de que 'carLicensePlate' está armazenado como tal
                    description: data.description,  // Descrição do carpooling, previamente 'category'
                    passengers: data.passengers,  // Lista de passageiros, previamente 'subCategory'
                    rules: data.rules,  // Regras do carpooling, previamente 'description'
                    status: data.status,  // Status do carpooling, previamente 'location'
                    toWhere: data.toWhere,  // Destino do carpooling, previamente 'coordinates'
                    vehicleModel: data.vehicleModel,  // Modelo do veículo, previamente 'status'
                    dateTime: data.dateTime,  // Data e hora, previamente 'priority'
                };
            }));

            return carpoolings;

        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }
    }

}


export default CarpoolingServices;