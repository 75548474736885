import ProblemReportDetail from "../models/ProblemReportModel";
import * as admin from 'firebase-admin';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, getFirestore, query, where, getDocs, limit as firestoreLimit, updateDoc, documentId, doc, arrayUnion, orderBy } from "firebase/firestore";
import UserServices from "./UserServices.ts";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { subDays } from 'date-fns';
const serviceAccount = process.env.ACC_SERVICE ?? require('../service-account.json');


const firebaseConfig = {
    apiKey: "AIzaSyCrHFl6SWFGdt37XoWs-U0rn5U53aznK-w",
    authDomain: "cidadeinterativa-55ccd.firebaseapp.com",
    databaseURL: "https://cidadeinterativa-55ccd-default-rtdb.firebaseio.com",
    projectId: "cidadeinterativa-55ccd",
    storageBucket: "cidadeinterativa-55ccd.appspot.com",
    messagingSenderId: "38415114161",
    appId: "1:38415114161:web:fee3422c82024ea0d72790",
    measurementId: "G-95QLH9BJNJ"
  };

const app = initializeApp(serviceAccount);
const db = getFirestore(app);
const storage = getStorage(app);

async function uploadFileAndGetURL(file: File): Promise<string> {
    
    const storageRef = ref(storage, 'solvingProblems/' + file.name+Date.now());
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  }
  

class ProblemReportServices {
    async GetAllReports(limit: number): Promise {
        const userServices = new UserServices();
        try {
            const reportsRef = collection(db, "ProblemReports");
            const solvingPeopleRef = collection(db, "SolvingProblems");
    
            const q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return null;
            }
    
            const reports = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                const solvingQuery = query(solvingPeopleRef, where("docRef", "==", doc.id));
                const solvingQuerySnapshot = await getDocs(solvingQuery);
    
                let docs = [];
                if (!solvingQuerySnapshot.empty) {
                    docs = solvingQuerySnapshot.docs.reduce((acc, doc) => {
                        return acc.concat(doc.data().peoplesSolving);
                    }, []);
                }
    
                // Fetch user data for each UID
                const usersData = await Promise.all(docs.map(async uid => {
                    const userData = await userServices.getUserById(uid);
                    return userData;
                }));
    
                return {
                    id: doc.id,
                    userId: data.userId,
                    title: data.title,
                    category: data.category,
                    subCategory: data.subCategory,
                    description: data.description,
                    location: data.location,
                    coordinates: data.coordinates,
                    status: data.status,
                    priority: data.priority,
                    deadLine: data.deadLine,
                    mainImage: data.mainImage,
                    images: data.images,
                    createdAt: data.createdAt?.toDate() ?? null,
                    creatorData: await userServices.getUserById(data.userId),
                    peopleSolving: {
                        count: docs.length || 0,
                        usersData: usersData // Dados dos usuários resolvendo os problemas
                    }
                };
            }));
    
            return reports;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }
    }

    async getReportsByUserId(uid): Promise<ProblemReportDetail[] | null> {
        const userServices = new UserServices();

        

        try {
            const reportsRef = collection(db, "ProblemReports");
            const solvingPeopleRef = collection(db, "SolvingProblems");
            const q = query(reportsRef, where("userId", "==", uid));
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                
                return null;
            }

            // Use Promise.all to wait for all promises to resolve
            const reports = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                const solvingQuery = query(solvingPeopleRef, where("docRef", "==", doc.id));
                const solvingQuerySnapshot = await getDocs(solvingQuery);
                
                
                let docs = [];
                if (!solvingQuerySnapshot.empty) {
                    docs = solvingQuerySnapshot.docs.reduce((acc, doc) => {
                        return acc.concat(doc.data().peoplesSolving);
                    }, []);
                }
    
                // Fetch user data for each UID
                const usersData = await Promise.all(docs.map(async uid => {
                    const userData = await userServices.getUserById(uid);
                    return userData;
                }));

                return {
                    id: doc.id,
                    userId: data.userId,
                    title: data.title,
                    category: data.category,
                    subCategory: data.subCategory,
                    description: data.description,
                    location: data.location,
                    coordinates: data.coordinates,
                    status: data.status,
                    priority: data.priority,
                    deadLine: data.deadLine,
                    mainImage: data.mainImage,
                    images: data.images,
                    creatorData: await userServices.getUserById(data.userId),
                    createdAt: data.createdAt?.toDate() ?? null,
                    peopleSolving: {
                        count: docs.length || 0,
                        usersData: usersData
                    }
                };
            }));

            return reports;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }
    }

    async GetAllReportsInRangeDate(limit, range) {
        const userServices = new UserServices();
    
        const daysAgo = range;
        const dateLimit = new Date();
        dateLimit.setDate(dateLimit.getDate() - daysAgo);
    
        try {
            const reportsRef = collection(db, "ProblemReports");
            const solvingPeopleRef = collection(db, "SolvingProblems");
            
            const q = query(
                reportsRef,
                orderBy("createdAt", "desc"),
                where("createdAt", ">=", dateLimit),
                firestoreLimit(limit)
            );
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return null;
            }
    
            const reports = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();
                const solvingQuery = query(solvingPeopleRef, where("docRef", "==", doc.id));
                const solvingQuerySnapshot = await getDocs(solvingQuery);
    
                let docs = [];
                if (!solvingQuerySnapshot.empty) {
                    docs = solvingQuerySnapshot.docs.reduce((acc, doc) => {
                        return acc.concat(doc.data().peoplesSolving);
                    }, []);
                }
    
                // Fetch user data for each UID
                const usersData = await Promise.all(docs.map(async uid => {
                    const userData = await userServices.getUserById(uid);
                    return userData;
                }));
    
                return {
                    id: doc.id,
                    userId: data.userId,
                    title: data.title,
                    category: data.category,
                    subCategory: data.subCategory,
                    description: data.description,
                    location: data.location,
                    coordinates: data.coordinates,
                    status: data.status,
                    priority: data.priority,
                    deadLine: data.deadLine,
                    mainImage: data.mainImage,
                    images: data.images,
                    createdAt: data.createdAt?.toDate() ?? null,
                    creatorData: await userServices.getUserById(data.userId),
                    peopleSolving: {
                        count: docs.length || 0,
                        usersData: usersData
                    }
                };
            }));
    
            return reports;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }
    }
    


    async getMonthlyReports(month: number, category: string): Promise<ProblemReportDetail[] | null> {
        // aqui porra
        try {
            const reportsRef = collection(db, "ProblemReports");
            const currentYear = new Date().getFullYear();
            const startOfMonth = new Date(currentYear, month, 1);
            const endOfMonth = new Date(currentYear, month + 1, 0, 23, 59, 59);
            const userServices = new UserServices();
    
            const solvingPeopleRef = collection(db, "SolvingProblems");
            let q;
            if (category === "Todas") {
                q = query(reportsRef, where("createdAt", ">=", startOfMonth), where("createdAt", "<=", endOfMonth), orderBy("createdAt", "desc"));
            } else {
                q = query(reportsRef, where("createdAt", ">=", startOfMonth), where("createdAt", "<=", endOfMonth), orderBy("createdAt", "desc"), where("category", "==", category));
            }
    
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                
                return null;
            }
    
            const reports = await Promise.all(querySnapshot.docs.map(async doc => {
                const data = doc.data();


                const solvingQuery = query(solvingPeopleRef, where("docRef", "==", doc.id));
                const solvingQuerySnapshot = await getDocs(solvingQuery);
    
                let docs = [];
                if (!solvingQuerySnapshot.empty) {
                    docs = solvingQuerySnapshot.docs.reduce((acc, doc) => {
                        return acc.concat(doc.data().peoplesSolving);
                    }, []);
                }
    
                // Fetch user data for each UID
                const usersData = await Promise.all(docs.map(async uid => {
                    const userData = await userServices.getUserById(uid);
                    return userData;
                }));
                
                return {
                    id: doc.id,
                    userId: data.userId,
                    title: data.title,
                    category: data.category,
                    subCategory: data.subCategory,
                    description: data.description,
                    location: data.location,
                    coordinates: data.coordinates,
                    status: data.status,
                    priority: data.priority,
                    deadLine: data.deadLine,
                    mainImage: data.mainImage,
                    images: data.images,
                    createdAt: data.createdAt?.toDate() ?? null, // Convert Timestamp to Date
                    creatorData: await userServices.getUserById(data.userId),
                    peopleSolving: {
                        count: docs.length || 0,
                        usersData: usersData
                    }
                };
            }));
    
            return reports;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }
    }


    async SolveProblem(problemId: string, file: File, solution: string){

        try {
            
            
            const videoURL = await uploadFileAndGetURL(file);
            
            const solvingPeopleRef = collection(db, "SolvingProblems");
            
            const solvingQuery = query(solvingPeopleRef, where("docRef", "==", problemId)); 
            
                const solvingQuerySnapshot = await getDocs(solvingQuery);

                
                

                if (!solvingQuerySnapshot.empty) {
                    
                    
                    const problemDocRef = doc(db, 'SolvingProblems', solvingQuerySnapshot.docs[0].id);
                    const problemDoc = doc(db, 'ProblemReports', problemId);

                    const newSolution = {
                        uid: '1', // substitua generateUID() pela sua função para gerar um UID ou use algum identificador único
                        description: solution,
                        videoUrl: videoURL
                    };

                    
            
                    // Atualize o documento com a nova solução
                    await updateDoc(problemDocRef, {
                        solutions: arrayUnion(newSolution)
                    });

                    await updateDoc(problemDoc, {
                        status: 2
                    });
                    
                    
                    return { status: true };
                }
                return {status: false}
        } catch (e) {
            console.error("Error during official announcement: ", e);
            return { status: false };
        }

    }
}

export default ProblemReportServices;