import React, { useState } from "react";
import styles from "./style.module.css";
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import AiChat from "../../screens/AIQueriesScreen/components/AiChat.tsx";
import AdminServices from "../../Services/AdminServices.ts";
import CircularProgress from '@mui/material/CircularProgress';


const adminService = new AdminServices();
const Aside = ({ adminData }) => {
  const [showChat, setShowChat] = useState();

  const [age, setAge] = useState("null");
  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState('');
  const [didFetch, setDidFetch] = useState(false);
  const [numbers, setNumbers] = useState(Array);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");



  const sendAnnouncement = async () => {

    setIsLoading(true);

    const fileInput = document.getElementById("file");
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];


      try {
        const res = await adminService.urgentAnnouncement(title, date, time, file)
        if (res.status) {
          alert("Emergência acionada")
          setIsLoading(false);
        } else {
          alert("não foi possível enviar emergência")
          setIsLoading(false);
        }

      } catch (e) {
        alert("não foi possível enviar emergência")
        setIsLoading(false);
      }

    } else {
      alert("selecione alguma imagem!");
    }





  }


  return (
    <aside className={styles.container}>
      <div className={styles.user}>
        <img src="./assets/icon.svg" alt="" />
        <p>{adminData.points} Pontos</p>
        <img src="./assets/notification.svg" alt="" />
        <div className={styles.userPhoto}>
          <img src="./assets/photo.svg" alt="" />
        </div>
      </div>

      {/* <div className={styles.default}>
        <p>Alertas de Emergência</p>
        <a href="">Ver todos</a>
      </div>

      <div className={styles.emergency}>
        <img src="./assets/replace.svg" alt="" />
        <div>
          <p>Tempestade se aproxima na cidade</p>
          <div className={styles.date}>
            <span>
              <img src="./assets/clock.svg" alt="" /> 14:20
            </span>
            <span>
              <img src="./assets/calendar.svg" alt="" />
              10/10/24
            </span>
          </div>
        </div>
        <div className={styles.alert}>
          <img src="./assets/alert.svg" alt="" width={20} />
        </div>
      </div>

      <div className={styles.default}>
        <p>Moderar Eventos</p>
        <a href="">Ver todos</a>
      </div>
      <img src="./assets/events.svg" alt="" width={330} />

      <div className={styles.default}>
        <p>últimas mensagens</p>
        <a href="">Ver todos</a>
      </div>

      <div className={styles.message}>
        <img src="./assets/message.svg" alt="" />
        <div>
          <p>Rafael Santos</p>
          <div className={styles.date}>
            <span>Olá, gostaria de saber sobre o chamado...</span>
          </div>
        </div>
        <img src="./assets/chevron.svg" width={15} height={15} alt="" />
      </div>

      <div className={styles.message}>
        <img src="./assets/message.svg" alt="" />
        <div>
          <p>Rafael Santos</p>
          <div className={styles.date}>
            <span>Olá, gostaria de saber sobre o chamado...</span>
          </div>
        </div>
        <img src="./assets/chevron.svg" width={15} height={15} alt="" />
      </div>

      <div className={styles.acess}>
        <p>Acesso Rápido</p>
      </div> */}

      <Box sx={{mt: 10,display: "flex", flexDirection: "column", alignItems: "center", width: 220, p:2, backgroundColor: "#F4F4F4", borderRadius: 4}}>
        <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
          Comunicado Urgente
        </Typography>



        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", mt: 5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 3,
              width: 300,
              mb: 3
            }}
          >
            <Stack sx={{ m: 0 }}>
              <Typography sx={{fontSize: 12}}>Comunicado</Typography>
              <input value={title} onChange={(h) => setTitle(h.target.value)} className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='título do comunicado' />
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <input style={{height: "15px"}} type="date" value={date} onChange={(handle) => setDate(handle.target.value)} />
                <input style={{height: "15px", width: "65px", fontSize: 12}}type="time" value={time} onChange={(handle) => setTime(handle.target.value)} />
              </Stack>
            </Stack>


          </Box>








        </Box>




        <Stack sx={{ mb: 5, width: 185, display: "flex", alignItems: "flex-start" }}>
          <input id="file"  className={styles.sendMsgInput} type="file" />
        </Stack>

        {isLoading && <CircularProgress />}
        {!isLoading &&
          <Stack direction="column" spacing={2} sx={{ width: '80%', alignItems: "center",}}>

            <Button
              variant="contained"
              onClick={() => {
                sendAnnouncement();

              }}
              sx={{
                bgcolor: 'yellowgreen',
                ':hover': { bgcolor: 'darkolivegreen' },
                borderRadius: 20,
                textTransform: 'none',
                fontSize: 12,
                fontWeight: 'regular',
                paddingX: 4,
                height: 30,
                width: 200,
              }}
            >
              Enviar comunicado
            </Button>
          </Stack>
        }

      </Box>

      <div className={styles.points}>
        <p>Total de Pontos Acumulados</p>
        <div className={styles.circle}>
          <img src="./assets/emote2.svg" height={30} alt="" />
          <p>{adminData.points}</p>
          <span>Pontos</span>
        </div>
        <div className={styles.week}>
          <div>
            <span>Ultima semana</span>
            <div className={styles.status}>
              <p>72.1%</p>
              <img src="./assets/status.svg" alt="" />
            </div>

          </div>
          <div>
            <img src="./assets/combined.svg" width={100} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.infos}>
        <div className={styles.insights}>
          <div>
            <span>Cadastro completo</span>
            <p>{adminData.fullRegisters}</p>
          </div>
          <img src="./assets/status.svg" height={20} alt="" />
        </div>

        <div className={styles.insights}>
          <div>
            <span>Chamadas</span>
            <p>{adminData.calls}</p>
          </div>
          <img src="./assets/status.svg" height={20} alt="" />
        </div>

        <div className={styles.insights}>
          <div>
            <span>Reports</span>
            <p>{adminData.reports}</p>
          </div>
          <img src="./assets/status.svg" height={20} alt="" />
        </div>

        <div className={styles.insights}>
          <div>
            <span>Eventos Criados</span>
            <p>{adminData.createdEvents}</p>
          </div>
          <img src="./assets/status.svg" height={20} alt="" />
        </div>

        <div className={styles.insights}>
          <div>
            <span>Caronas Oferecidas</span>
            <p>{adminData.carpooling}</p>
          </div>
          <img src="./assets/status.svg" height={20} alt="" />
        </div>
      </div>


      {!showChat &&
        <div onClick={() => setShowChat(true)} className={styles.doubts}>
          <div style={{marginRight: 10, width: 200}} className={styles.ia}>
            <div className={styles.robot}>
              <img width={25} src="./assets/robot.svg" alt="" />
            </div>
            <p>Fale com a nossa I.A</p>
            <h2>Consultar I.A.</h2>
          </div>

          <div className={styles.middleColumn}></div> {/* Coluna do meio */}

          <div style={{marginLeft: 10, width: 170}} className={styles.ia}>
            <div className={styles.answer}>
              <img src="./assets/answer.svg" alt="" />
            </div>
            <p>Tire suas dúvidas</p>
            <h2>Consultar I.A.</h2>
          </div>
        </div>
      }


      {
        showChat && <AiChat />
      }


    </aside>
  );
};

export default Aside;
