import * as admin from 'firebase-admin';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, updateEmail, updatePassword  } from 'firebase/auth';
import {deleteDoc, collection, getFirestore, query, where, getDocs, limit as firestoreLimit, doc, addDoc, Timestamp, orderBy, FieldValue, updateDoc, arrayUnion, setDoc, getDoc } from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL
  } from "firebase/storage";
import axios from "axios";
import UserServices from './UserServices.ts';
const serviceAccount = process.env.ACC_SERVICE ?? require('../service-account.json');

const app = initializeApp(serviceAccount);
const db = getFirestore(app);
const auth = getAuth();
const storage = getStorage(app);


function calculateAge(birthdate) {

    try{
        const birthDateParts = birthdate.split("/");
        const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
    
        // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
        }
    
        return age;
    }catch(e){
        return false;
    }
    
  }


async function uploadFileAndGetURL(file: File): Promise<string> {
    
    const storageRef = ref(storage, 'announcements/' + file.name+Date.now());
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  }
  

class AdminServices{


    async GetGeneralData(userId){

        let generalData = {
            points: 0,
            fullRegisters: 0,
            users: 0,
            female: 0,
            male: 0,
            calls: 14,
            reports: 0,
            createdEvents: 0,
            carpooling: 0,
            carpoolingService: null,
            categories: [],
            highlights: [],
            currentSecretariat: null,
        };

        try {

            const userSecretariatRef = doc(collection(db, "adminUsers"), userId);
    
            try {
                const docSnapshot = await getDoc(userSecretariatRef);
                if (docSnapshot.exists()) {
                    // O documento existe, você pode acessar os dados assim:
                    const userData = docSnapshot.data();
                    generalData.currentSecretariat = userData.secretariat;
                } else {
                    console.log("O documento não existe.");
                }
            } catch (error) {
                console.error("Erro ao obter os dados do documento:", error);
            }
//-------------------------------------------------------------------
            const usersRef = collection(db, "users");
            
            const q = query(usersRef);
    
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return null;
            }
//------------------------------------------------------------------
            const adminRef = collection(db, "AdminInfo");
                        
            const qAdmin = query(adminRef);

            const queryAdminSnapshot = await getDocs(qAdmin);

//------------------------------------------------------------------
            const categoryRef = collection(db, "Secretariats");
                        
            const qCategory = query(categoryRef);

            const queryCategoryRefSnapshot = await getDocs(qCategory);

//------------------------------------------------------------------
            const reportsRef = collection(db, "ProblemReports");
            
            const qProblem = query(reportsRef);
    
            const queryProblemSnapshot = await getDocs(qProblem);
//------------------------------------------------------------------
            const eventsRef = collection(db, "CityEvents");
            
            const qEvent = query(eventsRef);
    
            const queryEventSnapshot = await getDocs(qEvent);
//------------------------------------------------------------------
            const carpoolingRef = collection(db, "Carpooling");
                        
            const qCarpooling = query(carpoolingRef);

            const queryCarpoolingSnapshot = await getDocs(qCarpooling);
//------------------------------------------------------------------


            

            if (queryProblemSnapshot.empty) {
                
            }else{
                generalData.reports += queryProblemSnapshot.docs.length;
            }

            if (queryEventSnapshot.empty) {
                
            }else{
                generalData.createdEvents += queryEventSnapshot.docs.length;
            }

            if (queryCarpoolingSnapshot.empty) {
                
            }else{
                generalData.carpooling += queryCarpoolingSnapshot.docs.length;
            }

            if (queryAdminSnapshot.empty) {
                
            }else{
                generalData.carpoolingService = queryAdminSnapshot.docs[0].data().carpooling
                generalData.highlights = queryAdminSnapshot.docs[0].data().highlights
            }

            if (!queryCategoryRefSnapshot.empty) {
                // Extrair subcategorias de cada documento encontrado
                const extractedCategories = queryCategoryRefSnapshot.docs.map(( doc) => {
                    
                    return doc.id;
                }, []);
            
                
                if (extractedCategories.length > 0) {
                    generalData.categories = extractedCategories;
                }
            }
            

            
    
            querySnapshot.docs.forEach(doc => {
                const data = doc.data();
                generalData.users += 1;
                generalData.points += data.points;
                generalData.female += data.gender === "Feminino";
                generalData.male += data.gender === "Masculino";
                generalData.fullRegisters += data.registerStep === 10 ? 1 : 0;

            });



            console.log("generaldata");
            console.log(generalData);
    
            return generalData;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }

    }


    async updateHighlightTitle(index, title) {
        try {
            // Referência para a coleção "AdminInfo"
            const adminRef = collection(db, "AdminInfo");
    
            // Consulta para obter os documentos da coleção "AdminInfo"
            const qAdmin = query(adminRef);
    
            // Executa a consulta
            const queryAdminSnapshot = await getDocs(qAdmin);
    
            // Obtém o primeiro documento retornado pela consulta
            const adminDoc = queryAdminSnapshot.docs[0];
    
            // Verifica se o documento existe
            if (adminDoc.exists()) {
                // Obtém os dados do documento
                const adminData = adminDoc.data();
                
                // Obtém os destaques do documento
                const highlights = adminData.highlights;
    
                // Atualiza o título do destaque no índice especificado
                highlights[index].title = title;
    
                // Atualiza o documento com os destaques modificados
                await updateDoc(adminDoc.ref, { highlights });
    
                // Retorna um objeto indicando sucesso
                return { status: true };
            } else {
                // Se o documento não existir, retorna um objeto indicando falha
                return { status: false, message: "O documento 'AdminInfo' não foi encontrado." };
            }
        } catch (error) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro ao atualizar título do destaque: ", error);
            return { status: false, error };
        }
    }


    async updateHighlightImage(index, file: File){
        try {
            // Realiza o upload do arquivo e obtém a URL
            const imageUrl = file ? await uploadFileAndGetURL(file) : "";
            const adminRef = collection(db, "AdminInfo");
    
            // Consulta para obter os documentos da coleção "AdminInfo"
            const qAdmin = query(adminRef);
    
            // Executa a consulta
            const queryAdminSnapshot = await getDocs(qAdmin);
    
            // Obtém o primeiro documento retornado pela consulta
            const adminDoc = queryAdminSnapshot.docs[0];
    
            // Verifica se o documento existe
            if (adminDoc.exists()) {
                // Obtém os dados do documento
                const adminData = adminDoc.data();
                
                // Obtém os destaques do documento
                const highlights = adminData.highlights;
    
                // Atualiza o título do destaque no índice especificado
                highlights[index].imageUrl = imageUrl;
    
                // Atualiza o documento com os destaques modificados
                await updateDoc(adminDoc.ref, { highlights });
    
                // Retorna um objeto indicando sucesso
                return { status: true, imageUrl };
            } else {
                // Se o documento não existir, retorna um objeto indicando falha
                return { status: false, message: "O documento 'AdminInfo' não foi encontrado." };
            }
        } catch (e) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro durante o anúncio oficial: ", e);
            return { status: false };
        }
    }

    async addHighlight(){
        try {
            // Referência para a coleção "AdminInfo"
            const adminRef = collection(db, "AdminInfo");
    
            // Consulta para obter os documentos da coleção "AdminInfo"
            const qAdmin = query(adminRef);
    
            // Executa a consulta
            const queryAdminSnapshot = await getDocs(qAdmin);
    
            // Obtém o primeiro documento retornado pela consulta
            const adminDoc = queryAdminSnapshot.docs[0];
    
            // Verifica se o documento existe
            if (adminDoc.exists()) {
                // Obtém os dados do documento
                const adminData = adminDoc.data();
                
                // Obtém os destaques do documento
                const highlights = adminData.highlights;
    
                // Atualiza o título do destaque no índice especificado
                highlights.push({title: "",imageUrl: ""})
    
                // Atualiza o documento com os destaques modificados
                await updateDoc(adminDoc.ref, { highlights });
    
                // Retorna um objeto indicando sucesso
                return { status: true };
            } else {
                // Se o documento não existir, retorna um objeto indicando falha
                return { status: false, message: "O documento 'AdminInfo' não foi encontrado." };
            }
        } catch (error) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro ao atualizar título do destaque: ", error);
            return { status: false, error };
        }
    }
    



    async getAgeGenderData(limit, category){

        const userServices = new UserServices();

        const gender = {
            women: 0,
            men: 0,
            womenOlderThan25: 0,
            menOlderThan25: 0,
            womenLessThan25: 0,
            menLessThan25: 0,
            others: 0
        }

        try {
            const reportsRef = collection(db, "ProblemReports");

            
            let q;

            if(category !== "Todas"){
                q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"), where("category", "==", category));

            }else{
                q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"));
            }

            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                
                return null;
            }

            

            const userPromises = querySnapshot.docs.map(doc => {
                const data = doc.data();
                return userServices.getUserById(data.userId).then(user => {
                    if (!user || calculateAge(user.birthday) === false) return;
            
                    if (user.gender === "Feminino") {
                        gender.women += 1;
                        let age = calculateAge(user.birthday);
                        if (age > 25) {
                            gender.womenOlderThan25 += 1;
                        } else {
                            
                            gender.womenLessThan25 += 1;
                        }
                    } else if (user.gender === "Masculino") {
                        gender.men += 1;
                        let age = calculateAge(user.birthday);
                        if (age > 25) {
                            gender.menOlderThan25 += 1;
                        } else {
                            gender.menLessThan25 += 1;
                        }
                    } else {
                        gender.others += 1;
                    }
                });
            });


            await Promise.all(userPromises);


            return gender;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }

    }


    async getSecretariatsData(limit, categories) {
        try {
          const reportsRef = collection(db, "ProblemReports");
          const q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"));
          const querySnapshot = await getDocs(q);
      
          if (querySnapshot.empty) {
            
            return null;
          }
      
          // Inicialize o categoriesObject com todas as categorias em zero
          const categoriesObject = categories.reduce((obj, category) => {
            obj[category] = 0;
            return obj;
          }, {});
      
          // Agora, itere sobre cada documento e incremente a contagem da categoria apropriada
          querySnapshot.docs.forEach(doc => {
            const data = doc.data();
            if (categories.includes(data.category)) {
              categoriesObject[data.category] += 1;
            }
          });
      
          return categoriesObject;
        } catch (error) {
          console.error("Erro ao buscar relatórios:", error);
          return null;
        }
      }


      async getGeneralDataToChart(limit, category) {
        try {
          const reportsRef = collection(db, "ProblemReports");
          let q;
          const currentYear = new Date().getFullYear(); // Obtém o ano atual
      
          if (category === "Todas") {
            q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"));
          } else {
            q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"), where("category", "==", category));
          }
      
          const querySnapshot = await getDocs(q);
      
          if (querySnapshot.empty) {
            
            return null;
          }
      
          // Inicializa um objeto com arrays para cada status por mês
          let monthlyStatus = Array.from({ length: 12 }, () => ({
            aberto: 0,
            andamento: 0,
            finalizado: 0
          }));
      
          // Itera sobre cada documento e incrementa o contador do mês e status apropriado
          querySnapshot.docs.forEach(doc => {
            const data = doc.data();
            const createdAt = data.createdAt.toDate(); // Converte o Timestamp para um objeto Date
            const month = createdAt.getMonth(); // Obtém o mês como um número (0-11)
            const year = createdAt.getFullYear(); // Obtém o ano
      
            // Verifica se o ano do relatório é o ano atual antes de incrementar os contadores
            if (year === currentYear) {
              if (data.status === 0) {
                monthlyStatus[month].aberto += 1;
              } else if (data.status === 1) {
                monthlyStatus[month].andamento += 1;
              } else if (data.status === 2) { // Ajuste para garantir que estejamos lidando com o valor esperado para finalizados
                monthlyStatus[month].finalizado += 1;
              }
            }
          });
      
          return monthlyStatus;
        } catch (error) {
          console.error("Erro ao buscar relatórios:", error);
          return null;
        }
      }


      async getOnGoingDataToChart(limit, category) {
        try {
          const reportsRef = collection(db, "ProblemReports");
          let q;
          if (category === "Todas") {
            q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"));
          } else {
            q = query(reportsRef, firestoreLimit(limit), orderBy("createdAt", "desc"), where("category", "==", category));
          }
      
          const querySnapshot = await getDocs(q);
          if (querySnapshot.empty) {
            
            return null;
          }
      
          const onGoingData = {
            num: 0,
            notSolved: 0,
            solvedByAdmin: 0,
            onGoing: 0,
            solvedByUser: 0,
            open: 0
          };
      
          for (const doc of querySnapshot.docs) {
            const data = doc.data();
                onGoingData.num += 1;
            if (data.status !== 2) {
              onGoingData.notSolved += 1;
              if (data.status === 1) {
                onGoingData.onGoing += 1;
              } else if (data.status === 0) {
                onGoingData.open += 1;
              }
            } else {
              const solvingPeopleRef = collection(db, "SolvingProblems");
              const solvingQuery = query(solvingPeopleRef, where("docRef", "==", doc.id));
              const solvingQuerySnapshot = await getDocs(solvingQuery);
      
              if (!solvingQuerySnapshot.empty) {
                const solutions = solvingQuerySnapshot.docs[0].data().solutions;
                if (Array.isArray(solutions)) {
                  let solvedByAdmin = solutions.some(solution => solution.uid === '1');
                  if (solvedByAdmin) {
                    onGoingData.solvedByAdmin += 1;
                  } else {
                    onGoingData.solvedByUser += 1;
                  }
                }
              }
            }
          }
      
          return onGoingData;
        } catch (error) {
          console.error("Erro ao buscar relatórios:", error);
          return null;
        }
      }
      
      
      async getRaceRegistersDataToChart() {
        const race = {
            branca: 0,
            preta: 0,
            parda: 0,
            amarela: 0,
            indigena: 0,
            outra: 0
        };
    
        try {
            const usersRef = collection(db, "users");
    
            // Consulta para a raça branca
            const qWhite = query(usersRef, where("race", "==", "Branca"));
            const whiteSnapshot = await getDocs(qWhite);
            race.branca = whiteSnapshot.size;
    
            // Consulta para a raça preta
            const qBlack = query(usersRef, where("race", "==", "Preta"));
            const blackSnapshot = await getDocs(qBlack);
            race.preta = blackSnapshot.size;
    
            // Consulta para a raça parda
            const qBrown = query(usersRef, where("race", "==", "Parda"));
            const brownSnapshot = await getDocs(qBrown);
            race.parda = brownSnapshot.size;
    
            // Consulta para a raça amarela
            const qYellow = query(usersRef, where("race", "==", "Amarela"));
            const yellowSnapshot = await getDocs(qYellow);
            race.amarela = yellowSnapshot.size;
    
            // Consulta para a raça indígena
            const qIndigenous = query(usersRef, where("race", "==", "Indígena"));
            const indigenousSnapshot = await getDocs(qIndigenous);
            race.indigena = indigenousSnapshot.size;
    
            // Consulta para a raça outra
            const qOther = query(usersRef, where("race", "==", "Outra"));
            const otherSnapshot = await getDocs(qOther);
            race.outra = otherSnapshot.size;
    
            console.log("Dados da raça:", race);
            return {status: true, races: race};
        } catch (error) {
            console.error("Erro ao obter dados da raça:", error);
            return null;
        }
    }


    async getEducationRegistersDataToChart() {
        const education = {
            analfabeto: 0,
            ens_fund_inc: 0,
            ens_fund_com: 0,
            ens_med_inc: 0,
            ens_med_com: 0,
            ens_tecnico: 0,
            ens_sup_inc: 0,
            ens_sup_com: 0,
            pos_grad_sup: 0
        };
    
        try {
            const usersRef = collection(db, "users");
            const qAnalfabeto = query(usersRef, where("education", "==", "Analfabeto"));
            const qEnsFundInc = query(usersRef, where("education", "==", "Ensino Fundamental Incompleto"));
            const qEnsFundCom = query(usersRef, where("education", "==", "Ensino Fundamental Completo"));
            const qEnsMedInc = query(usersRef, where("education", "==", "Ensino Médio Incompleto"));
            const qEnsMedCom = query(usersRef, where("education", "==", "Ensino Médio Completo"));
            const qEnsTecnico = query(usersRef, where("education", "==", "Ensino Técnico"));
            const qEnsSupInc = query(usersRef, where("education", "==", "Ensino Superior Incompleto"));
            const qEnsSupCom = query(usersRef, where("education", "==", "Ensino Superior Completo"));
            const qPosGradSup = query(usersRef, where("education", "==", "Pós-Graduação ou Superior"));
    
            const [analfabetoSnapshot, ensFundIncSnapshot, ensFundComSnapshot, ensMedIncSnapshot, ensMedComSnapshot, ensTecnicoSnapshot, ensSupIncSnapshot, ensSupComSnapshot, posGradSupSnapshot] = await Promise.all([
                getDocs(qAnalfabeto),
                getDocs(qEnsFundInc),
                getDocs(qEnsFundCom),
                getDocs(qEnsMedInc),
                getDocs(qEnsMedCom),
                getDocs(qEnsTecnico),
                getDocs(qEnsSupInc),
                getDocs(qEnsSupCom),
                getDocs(qPosGradSup)
            ]);
    
            education.analfabeto = analfabetoSnapshot.size;
            education.ens_fund_inc = ensFundIncSnapshot.size;
            education.ens_fund_com = ensFundComSnapshot.size;
            education.ens_med_inc = ensMedIncSnapshot.size;
            education.ens_med_com = ensMedComSnapshot.size;
            education.ens_tecnico = ensTecnicoSnapshot.size;
            education.ens_sup_inc = ensSupIncSnapshot.size;
            education.ens_sup_com = ensSupComSnapshot.size;
            education.pos_grad_sup = posGradSupSnapshot.size;
    
            return education;
        } catch (error) {
            console.error("Error fetching education data:", error);
            return education;
        }
    }


    async getEmploymentRegistersDataToChart() {
        const employmentSit = {
            desempregado: 0,
            empregado: 0,
            autonomo: 0,
            empresario: 0,
        };
    
        try {
            const usersRef = collection(db, "users");
    
            // Query para usuários desempregados
            const qDesempregado = query(usersRef, where("employmentSituation", "==", "Desempregado"));
            const desempregadoSnapshot = await getDocs(qDesempregado);
            employmentSit.desempregado = desempregadoSnapshot.size;
    
            // Query para usuários empregados
            const qEmpregado = query(usersRef, where("employmentSituation", "==", "Empregado"));
            const empregadoSnapshot = await getDocs(qEmpregado);
            employmentSit.empregado = empregadoSnapshot.size;
    
            // Query para usuários autônomos
            const qAutonomo = query(usersRef, where("employmentSituation", "==", "Autônomo"));
            const autonomoSnapshot = await getDocs(qAutonomo);
            employmentSit.autonomo = autonomoSnapshot.size;
    
            // Query para usuários empresários
            const qEmpresario = query(usersRef, where("employmentSituation", "==", "Empresário"));
            const empresarioSnapshot = await getDocs(qEmpresario);
            employmentSit.empresario = empresarioSnapshot.size;
    
            return employmentSit;
        } catch (error) {
            console.error("Erro ao recuperar dados de situação de emprego:", error);
            throw error;
        }
    }

    async getAgeRegistersDataToChart() {
        const ageRange = {
            "10_to_15": 0,
            "16_to_20": 0,
            "21_to_30": 0,
            "31_to_40": 0,
            "41_to_50": 0,
            "51_to_60": 0,
            "61_to_70": 0,
            "above_70": 0
        };
    
        try {
            const usersRef = collection(db, "users");
            const querySnapshot = await getDocs(usersRef);
    
            querySnapshot.forEach((doc) => {
                const userData = doc.data();
                const { birthday } = userData;
    
                // Verifica se a data de nascimento não é nula ou vazia
                if (birthday) {
                    // Calcula a idade com base na data de nascimento
                    const age = calculateAge(birthday);
    
                    // Distribui a idade na faixa etária correspondente
                    if (age >= 10 && age <= 15) {
                        ageRange["10_to_15"]++;
                    } else if (age >= 16 && age <= 20) {
                        ageRange["16_to_20"]++;
                    } else if (age >= 21 && age <= 30) {
                        ageRange["21_to_30"]++;
                    } else if (age >= 31 && age <= 40) {
                        ageRange["31_to_40"]++;
                    } else if (age >= 41 && age <= 50) {
                        ageRange["41_to_50"]++;
                    } else if (age >= 51 && age <= 60) {
                        ageRange["51_to_60"]++;
                    } else if (age >= 61 && age <= 70) {
                        ageRange["61_to_70"]++;
                    } else {
                        ageRange["above_70"]++;
                    }
                }
            });
    
            return ageRange;
        } catch (error) {
            console.error("Erro ao recuperar dados de idade:", error);
            throw error;
        }
    }
      

    async suspendCarpoolingService(action: boolean){


        try{

            const adminRef = collection(db, "AdminInfo");
            const adminQuery = query(adminRef);
            const adminQuerySnapshot = await getDocs(adminQuery);

    
            if (!adminQuerySnapshot.empty) {
                const adminDocRef = doc(db, 'AdminInfo', adminQuerySnapshot.docs[0].id);
    
                // Update the document with the new message
                await updateDoc(adminDocRef, {
                    "carpooling": action
                });
            }

            return {status: true}

        }catch(e){
            
            return {status:false, error: e}
        }


    }

    async directMessage(numbers: Array<string>, message: string){


        const filteredNumbers = numbers.filter((number, index, self) => {
            return self.indexOf(number) === index;
          });



        try{
            
            const res = await axios.post("https://ci.cidadeinter.com.br/admin/directMsg", {
                numbers: filteredNumbers,
                message
            })
        
            if(res.status === 200){
                return {status: true, message: "mensagem enviada com sucesso!"}
            }else{
                return {status: false, message: "msg não enviada!"}
            }
        }catch(e){
            return {status: false}
        }



    }


    async officialAnnouncement(users: Array<string>, announcementBody: Object, file?: File){


        try {
            // Realiza o upload do arquivo e obtém a URL
            const iconPhotoURL = file ? await uploadFileAndGetURL(file) : "";
        
            // Combina os detalhes do comunicado com a URL do ícone e a data de criação
            const AnnouncementBody = {
                ...announcementBody,
                iconPhoto: iconPhotoURL,
                createdAt: Timestamp.now()
            };
        
            // Adiciona um novo documento à coleção "Comunicados"
            const docRef = await addDoc(collection(db, 'Comunicados'), AnnouncementBody);
        
            // Para cada usuário na lista, cria uma referência ao comunicado na coleção "ComunicadosRefs"
            const promises = users.map(uid => {
                const userAnnouncementBody = { uid, docRefId: docRef.id };
                return addDoc(collection(db, 'ComunicadosRefs'), userAnnouncementBody);
            });
        
            // Espera que todas as promessas sejam resolvidas antes de retornar
            await Promise.all(promises);
        
            // Retorna um objeto indicando sucesso
            return { status: true };
        } catch (e) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro durante o anúncio oficial: ", e);
            return { status: false };
        }

        


    }

    async updateOfficialAnnouncement(docId, announcementBody, file) {
        try {
            // Realiza o upload do arquivo e obtém a URL
            const iconPhotoURL = file ? await uploadFileAndGetURL(file) : "";
    
            // Combina os detalhes do comunicado com a URL do ícone e a data de criação
            const announcementData = {
                ...announcementBody,
                iconPhoto: iconPhotoURL,
                createdAt: Timestamp.now()
            };
    
            // Cria uma referência para o documento
            const docRef = doc(collection(db, 'Comunicados'), docId);
    
            // Verifica se o documento existe
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                // Se o documento existir, atualize-o
                await updateDoc(docRef, announcementData);
            } else {
                // Se o documento não existir, adicione-o como um novo documento
                await setDoc(docRef, announcementData);
            }
    
            // Retorna um objeto indicando sucesso
            return { status: true };
        } catch (error) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro durante o anúncio oficial: ", error);
            return { status: false };
        }
    }

    async getOfficialAnnouncements() {
        try {
            // Obtém uma referência para a coleção "announcements" no Firestore
            const announcementsCollectionRef = collection(db, 'Comunicados');
    
            // Realiza a consulta para obter todos os documentos na coleção
            const querySnapshot = await getDocs(announcementsCollectionRef);
    
            // Inicializa uma array para armazenar os anúncios recuperados
            const officialAnnouncements = [];
    
            // Itera sobre os documentos recuperados
            querySnapshot.forEach(doc => {
                // Obtém os dados do documento
                const announcementData = doc.data();
    
                // Adiciona os dados do anúncio à array de anúncios
                officialAnnouncements.push({
                    id: doc.id,
                    announcement: announcementData.announcement,
                    contact: announcementData.contact,
                    createdAt: announcementData.createdAt.toDate(),
                    faq: announcementData.faq,
                    iconPhoto: announcementData.iconPhoto,
                    location: announcementData.location,
                    title: announcementData.title
                });
            });
    
            // Retorna a array de anúncios
            return officialAnnouncements;
        } catch (error) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro ao obter os anúncios oficiais: ", error);
            throw error;
        }
    }


    async deleteComunicate(docId) {
        try {
            // Referência para a coleção 'ComunicadosRefs'
            const collectionRef = collection(db, 'ComunicadosRefs');
            const comunicateDocRef = collection(db, 'Comunicados');
    
            // Consulta para obter os documentos com 'docRefId' igual a 'docId'
            const q = query(collectionRef, where('docRefId', '==', docId));
    
            // Executa a consulta
            const querySnapshot = await getDocs(q);
    
            // Exclui os documentos retornados pela consulta
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
    
            // Agora, exclui o documento específico com o ID 'docId'
            const specificDocRef = doc(comunicateDocRef, docId);
            await deleteDoc(specificDocRef);
    
            return {status: true}
        } catch (error) {
            console.error('Erro ao excluir documentos:', error);
            return {status: false}
        }
    }


    async urgentAnnouncement(title: string,date: string, time: string, file?: File){


        try {
            
            
            const iconPhotoURL = file? await uploadFileAndGetURL(file): "";
            
            
                const userAnnouncementBody = { title, imageUrl: iconPhotoURL, date, time };
                
                await addDoc(collection(db, 'EmergencyAlert'), userAnnouncementBody);
            
            
            return { status: true };
        } catch (e) {
            console.error("Error during official announcement: ", e);
            return { status: false };
        }

        


    }



    async createCityService(ServiceBody: Object, iconFile: File, bannerFile: File){


        try {
            
            
            const iconImageURL = iconFile? await uploadFileAndGetURL(iconFile): "";
            const bannerImageUrl = bannerFile? await uploadFileAndGetURL(bannerFile): "";
            
            const cityServiceBody = { ...ServiceBody, iconImageURL, bannerImageUrl, createdAt: Timestamp.now() };
            await addDoc(collection(db, 'CityServices'), cityServiceBody);
            
            return { status: true };
        } catch (e) {
            console.error("Error during official announcement: ", e);
            return { status: false };
        }

        


    }


    async getCityServices(){
        try {
            // Obtém uma referência para a coleção "announcements" no Firestore
            const cityServicesCollectionRef = collection(db, 'CityServices');
    
            // Realiza a consulta para obter todos os documentos na coleção
            const querySnapshot = await getDocs(cityServicesCollectionRef);
    
            // Inicializa uma array para armazenar os anúncios recuperados
            const cityServices = [];
    
            // Itera sobre os documentos recuperados
            querySnapshot.forEach(doc => {
                // Obtém os dados do documento
                const cityServiceData = doc.data();
    
                // Adiciona os dados do anúncio à array de anúncios
                cityServices.push({
                    id: doc.id,
                    content: cityServiceData.content,
                    contact: cityServiceData.contact,
                    createdAt: cityServiceData.createdAt.toDate(),
                    faq: cityServiceData.faq,
                    iconPhoto: cityServiceData.iconImageURL,
                    bannerImage: cityServiceData.bannerImageUrl,
                    location: cityServiceData.location,
                    title: cityServiceData.title,
                    workTime: cityServiceData.workTime
                });
            });
    
            // Retorna a array de anúncios
            return cityServices;
        } catch (error) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro ao obter os anúncios oficiais: ", error);
            throw error;
        }
    }

    async updateCityService(docId, ServiceBody, iconFile, bannerFile){

        try {
            // Realiza o upload do arquivo e obtém a URL
            const iconPhotoURL = iconFile ? await uploadFileAndGetURL(iconFile) : "";
            const bannerPhotoURL = bannerFile ? await uploadFileAndGetURL(bannerFile) : "";
    
            // Combina os detalhes do comunicado com a URL do ícone e a data de criação
            const ServiceData = {
                ...ServiceBody,
                iconImageURL: iconPhotoURL,
                bannerImageUrl: bannerPhotoURL,
                createdAt: Timestamp.now()
            };
    
            // Cria uma referência para o documento
            const docRef = doc(collection(db, 'CityServices'), docId);
    
            // Verifica se o documento existe
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                // Se o documento existir, atualize-o
                await updateDoc(docRef, ServiceData);
            } else {
                // Se o documento não existir, adicione-o como um novo documento
                await setDoc(docRef, ServiceData);
            }
    
            // Retorna um objeto indicando sucesso
            return { status: true };
        } catch (error) {
            // Captura e trata qualquer erro que ocorra durante o processo
            console.error("Erro durante update de cityService: ", error);
            return { status: false };
        }
        
    }

    async deleteCityService(docId){
        try {
            // Referência para a coleção 'ComunicadosRefs'
            const collectionRef = collection(db, 'CityServices');
    
            // Agora, exclui o documento específico com o ID 'docId'
            const specificDocRef = doc(collectionRef, docId);
            await deleteDoc(specificDocRef);
    
            return {status: true}
        } catch (error) {
            console.error('Erro ao excluir documentos:', error);
            return {status: false}
        }
    }

    async createNotice(NoticeBody: Object, bannerFile: File){


        try {
            
            
            const bannerImageUrl = bannerFile? await uploadFileAndGetURL(bannerFile): "";
            
            const NoticeServiceBody = { ...NoticeBody, bannerImageUrl, createdAt: Timestamp.now() };
            await addDoc(collection(db, 'Notices'), NoticeServiceBody);
            
            return { status: true };
        } catch (e) {
            console.error("Error during NoticeServiceBody: ", e);
            return { status: false };
        }

        


    }


    async createSecretariat(name: string, subCategories: Array<string>){


        try {
            
            const newDocRef = doc(collection(db, "Secretariats"), name);

            await setDoc(newDocRef, {subs: subCategories});
            
            return { status: true };
        } catch (e) {
            console.error("Error during NoticeServiceBody: ", e);
            return { status: false };
        }

        


    }




    async createUser(name, email, password, secretariat) {
        try {
          // Cria usuário no Firebase Authentication
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
          // Pega o UID do usuário registrado
          const uid = userCredential.user.uid;
      
          // Define os detalhes do usuário na coleção 'adminUsers' do Firestore
          const userDocRef = doc(db, 'adminUsers', uid);
          await setDoc(userDocRef, {
            name,
            email,
            secretariat,
            // Você pode adicionar mais campos conforme necessário
          });
      
          return {status: true}
        } catch (error) {
            console.error('Erro ao criar o usuário:', error.message);
            return {status: false}
        }
      }

      async updateUser(uid, name, email, password, secretariat) {
        try {
            
            if (email) {
                try {
                    await updateEmail(auth, uid, email);
                } catch (error) {
                    console.error('Erro ao atualizar o e-mail:', error.message);
                }
            }

            if (password) {
                try {
                    await updatePassword(auth, uid, password);
                } catch (error) {
                    console.error('Erro ao atualizar o senha:', error.message);
                }
            }

    
            // Cria um objeto com as informações que serão atualizadas no Firestore
            const updateData = {};
            if (email) updateData.email = email;
            if (name) updateData.name = name;
            if (secretariat) updateData.secretariat = secretariat;
    
            // Atualiza informações no Firestore
            if (Object.keys(updateData).length > 0) {
                const userDocRef = doc(db, 'adminUsers', uid);
                await updateDoc(userDocRef, updateData);
            }
    
            return {status: true}
        } catch (error) {
            console.error('Erro ao atualizar o usuário:', error.message);
            return {status: false}
        }
    }


    async getAllSecretariats(){
        const secretariatRef = collection(db, "Secretariats");
            
            try{
                const q = query(secretariatRef);
    
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    
                    return null;
                }
    
                const categories = querySnapshot.docs.map((doc)=>{
                    const data = doc.data();
    
                    data["category"] = doc.id;
                    return data;
                })
    
                return categories;
            }catch(e){
                return false
            }

    }

    async getIAChat(){

            try {
                const IAConversationRef = collection(db, "IAConversation");
                
                const q = query(IAConversationRef, where("uid", "==", "1"));
                const querySnapshot = await getDocs(q);
                
                if (querySnapshot.empty) {
                    
                    return null;
                }
    
                const msgs = querySnapshot.docs[0].data().msgs;
    
                return msgs;
    
            } catch (error) {
                console.error("Erro ao buscar relatórios:", error);
                return null;
            }
        }

    async sendMsgToIa(msg: string){

        try {
            const userRef = collection(db, "IAConversation");
            const userQuery = query(userRef, where("uid", "==", "1"));
            const usersQuerySnapshot = await getDocs(userQuery);

            
    
            if (!usersQuerySnapshot.empty) {
                const userDocRef = doc(db, 'IAConversation', usersQuerySnapshot.docs[0].id);
    
                // Update the document with the new message
                await updateDoc(userDocRef, {
                    "msgs": arrayUnion({
                        "datetime": Timestamp.now(), // Use client-side timestamp
                        "senderType": "user",
                        "msg": msg
                    })
                });
    
                // Make a POST request to your server endpoint
                await axios.post("https://ci.cidadeinter.com.br/chat/chatAdminIA", {
                    "uid": "1",
                    "msg": msg
                });
            }
    
            return { status: true };
        } catch (e) {
            console.log(e);
            return { status: false };
        }

    }


    async clearChat(){


        try{

            const userRef = collection(db, "IAConversation");
            const userQuery = query(userRef, where("uid", "==", "1"));
            const usersQuerySnapshot = await getDocs(userQuery);

            
    
            if (!usersQuerySnapshot.empty) {
                const userDocRef = doc(db, 'IAConversation', usersQuerySnapshot.docs[0].id);
    
                // Update the document with the new message
                await updateDoc(userDocRef, {
                    "msgs": []
                });
            }

        }catch(e){

        }


    }


    async getNeighborhood() {
        try {
            const snapshot = await getDocs(collection(db, "Neighborhoods"));
            if (!snapshot.empty) {
                return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            } else {
                return [];
            }
        } catch (error) {
            console.error('Erro ao buscar documentos:', error);
            throw error;
        }
    }


    async getDocumentsByIds(documentIds: Array<string>) {
        const documents = [];
        const userServices = new UserServices();
        const solvingPeopleRef = collection(db, "SolvingProblems");
        try {
            for (const docId of documentIds) {
                const docRef = doc(db, 'ProblemReports', docId);
                const docSnapshot = await getDoc(docRef);
                const solvingQuery = query(solvingPeopleRef, where("docRef", "==", docId));
                const solvingQuerySnapshot = await getDocs(solvingQuery);
                
                
                let docs = [];
                if (!solvingQuerySnapshot.empty) {
                    docs = solvingQuerySnapshot.docs.reduce((acc, doc) => {
                        return acc.concat(doc.data().peoplesSolving);
                    }, []);
                }

                
                // Fetch user data for each UID
                const usersData = await Promise.all(docs.map(async uid => {
                    const userData = await userServices.getUserById(uid);
                    return userData;
                }));
                
                
                if (docSnapshot.exists()) {
                    const userData = await userServices.getUserById(docSnapshot.data().userId);
                    documents.push({ 
                        id: docId,
                        peopleSolving: {
                            count: documentIds.length || 0,
                            usersData: usersData
                        },
                        ...docSnapshot.data(),
                        creatorData: userData,
                        }
                    );
                }
            }

            return documents;
        } catch (error) {
            console.error('Erro ao buscar documentos:', error);
            throw error;
        }
    }


}


export default AdminServices;