import React, { useEffect, useState } from 'react';
import { Typography, Chip } from "@mui/material";
import AdminServices from '../../../Services/AdminServices.ts';

const adminService = new AdminServices();

export default function NeighborhoodSearch({ generalData, handleReturnProblems }) {
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [currentNeighborhood, setCurrentNeighborhood] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [showSearchResults, setShowSearchResults] = useState(false); // Inicialmente definido como false
    const [problems, setProblems] = useState([]);

    useEffect(() => {
        fetchNeighborhood();
    }, []);

    const fetchNeighborhood = async () => {
        const neighborhoodsGet = await adminService.getNeighborhood();
        setNeighborhoods(neighborhoodsGet);
    };

    useEffect(() => {
        handleReturnProblems(problems);
    }, [problems]);

    // Função para atualizar o termo de pesquisa
    const handleSearchTermChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);
        setCurrentNeighborhood(""); // Limpar o bairro selecionado ao iniciar a pesquisa
        // Exibir os resultados da pesquisa apenas se houver algo digitado
        setShowSearchResults(!!term);
    };

    // Função para lidar com a seleção de um bairro
    const handleNeighborhoodClick = async (neighborhoodId) => {
        setCurrentNeighborhood(neighborhoodId);
        setShowSearchResults(false); // Esconder os resultados da pesquisa ao selecionar um bairro

        try {
            // Buscar os problemas associados ao bairro selecionado
            const neighborhoodData = neighborhoods.find(neighborhood => neighborhood.id === neighborhoodId);
            if (neighborhoodData) {
                const problemsData = await adminService.getDocumentsByIds(neighborhoodData.problemsId);
                if(generalData.currentSecretariat === "all"){
                    setProblems(problemsData || []);
                  }else{
                    setProblems(prev=>{
                      const data = [...problemsData || []];
                      return data.filter(e=>e.category === generalData.currentSecretariat);
                    });
                  }
            }
        } catch (error) {
            console.error('Erro ao buscar problemas:', error);
        }
    };

    // Função para limpar o bairro selecionado
    const clearSelectedNeighborhood = () => {
        setCurrentNeighborhood("");
        setShowSearchResults(false); // Esconder os resultados da pesquisa ao limpar o bairro selecionado
    };

    // Filtrar bairros com base no termo de pesquisa
    const filteredNeighborhoods = neighborhoods.filter(neighborhood =>
        neighborhood.id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div style={{ marginLeft: 10 }}>
            <input
                style={{ outline: "none" }}
                type="text"
                placeholder="Pesquisar bairro..."
                value={searchTerm}
                onChange={handleSearchTermChange}
            />
            {currentNeighborhood && (
                <Chip
                    label={currentNeighborhood}
                    onDelete={clearSelectedNeighborhood}
                    style={{ margin: '5px' }}
                />
            )}
            {/* Renderizar os resultados da pesquisa somente se houver algo digitado */}
            {showSearchResults && (
                <div style={{ overflowY: "auto", border: "1px solid black", position: "absolute", height: 120, width: 169, backgroundColor: "white" }}>
                    {filteredNeighborhoods.map(neighborhood => (
                        <Typography
                            key={neighborhood.id}
                            sx={{ ":hover": { backgroundColor: "grey", cursor: "pointer" } }}
                            onClick={() => handleNeighborhoodClick(neighborhood.id)}
                        >
                            {neighborhood.id}
                        </Typography>
                    ))}
                </div>
            )}

        </div>
    );
}
