import React from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import styles from './style.module.css';

const Navlink = ({ login, generalData }) => {
  const auth = getAuth();

  const handleLogout = () => {
    signOut(auth).then(() => {
      window.location.reload();
      console.log('Deslogado com sucesso!');
    }).catch((error) => {
      // An error happened.
      console.error('Erro ao deslogar:', error);
    });
  };

  if (login) {
    return (
      <div className={styles.navlink}>
        {/* Conteúdo para quando o usuário está logado */}
      </div>
    );
  } else {
    return (
      <div className={styles.navlink}>
        <div>
          <p><img src="./assets/backlog.svg" alt="" />Relatórios</p>
          <ul>
            <li><Link to="/problemas-relatados">• Problemas relatados</Link></li>
            {generalData.currentSecretariat === "all" &&
              <>
                <li><Link to="/carona-solidaria">• Carona Solidária</Link></li>
                <li><Link to="/cadastros">• Cadastros</Link></li>
                <li><Link to="/cityServices">• Serviços Municipais</Link></li>
                <li><Link to="/cadastrar-destaques">• Cadastrar Destaques</Link></li>
              </>
            }
          </ul>
        </div>
        <div>
          <p><img src="./assets/sprint.svg" alt="" />Problemas relatados</p>
          <ul>
            <li><Link to="/problemas-abertos">• Em aberto</Link></li>
            <li><Link to="/problemas-em-andamento">• Em andamento</Link></li>
            <li><Link to="/por-bairro">• Por bairro</Link></li>
            <li><Link to="/relatorios-especificos">• Relatórios específicos</Link></li>
          </ul>
        </div>
        <div>
          <p><img src="./assets/report.svg" alt="" />Informativos</p>
          <ul>
            <li><Link to="/comunicado-urgente">• Comunicado urgente</Link></li>
            <li><Link to="/comunicado-oficial">• Comunicado oficial</Link></li>
            <li><Link to="/mensagens-diretas">• Mensagens diretas</Link></li>
            <li><Link to="/comunicados-enviados">• Comunicados enviados</Link></li>
          </ul>
        </div>
        <div>
          <p><img src="./assets/pages.svg" alt="" />Cadastros</p>
          <ul>
            <li><Link to="/cadastros">• Ver todos</Link></li>
            {generalData.currentSecretariat === "all" &&
              <li><Link to="/suspender-usuario">• Suspender</Link></li>
            }
          </ul>
        </div>
        {generalData.currentSecretariat === "all" &&
          <>
            <div>
              <p><img src="./assets/car.svg" alt="" />Carona Solidária</p>
              <ul>
                <li><Link to="/suspender-motorista">• Suspender motorista</Link></li>
                <li><Link to="/suspender-carona-solidaria">• Suspender serviço</Link></li>
              </ul>
            </div>
            <div>
              <p><img src="./assets/focus.svg" alt="" />Serviços Municipais</p>
              <ul>
                <li><Link to="/servicos">• Serviços</Link></li>
                <li><Link to="/registrar-servico-municipal">• Cadastrar serviços</Link></li>
                <li><Link to="">• Suspender</Link></li>
                <li><Link to="/cadastrar-noticias">• Cadastrar Notícias</Link></li>
              </ul>
            </div>
            <div>
              <p><img src="./assets/user.svg" alt="" />Usuário</p>
              <ul>
                <li><Link to="/registrar-secretaria">• Cadastrar Secretaria</Link></li>
                <li><Link to="/editar-secretaria">• Editar Secretaria</Link></li>
                <li><Link to="/cadastrar-usuario">• Cadastrar Usuário</Link></li>
                <li><Link to="/editar-usuario">• Editar Usuário</Link></li>
              </ul>
            </div>
          </>
        }
        <div className={styles.back}>
          <span className={styles.logout}>Log Out</span>
          <p onClick={handleLogout}><Link to="/home"><img src="./assets/logout.svg" alt="" />Sair</Link></p>
        </div>
      </div>
    );
  }
}

export default Navlink;
