import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';




const createDoughnutData = (value, color) => ({
  labels: [value >= 100 ? 'Resolvidos' : ' '], // Se o valor for 100%, label é 'Resolvidos', caso contrário, nenhuma label
  datasets: [
    {
      data: [value, 100 - value], // Valor e o restante até completar 100%
      backgroundColor: [color, 'rgba(0, 0, 0, 0.1)'], // Cor do valor e cor para o restante
      borderWidth: 0, // Sem bordas
    },
  ],
});

const options2 = {
  responsive: true,
  cutout: '70%', // Aumenta o espaço do centro para mais destaque
  plugins: {
    legend: {
      display: false, // Esconde a legenda do gráfico
    },
    tooltip: {
      enabled: false, // Desativa os tooltips
    },
  },
  maintainAspectRatio: false, // Permite que o gráfico de rosca seja redimensionado verticalmente
};



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20, // Arredondar os cantos
    position: 'relative',
    backgroundColor: '#383C40',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white', // Cor do texto
    padding: '5px 15px 5px 6px', // Espaçamento interno do Select
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Sobrescrever as bordas padrões
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    // Estilos para o ícone do dropdown

  },
}));




export default function CityServicesScreen({ generalData }) {



  return (
    <Container>

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
        {/* <Search /> */}
        <div className={styles.user}>
          <img src="./assets/icon.svg" alt="" />
          <p>{generalData.points} Pontos</p>
          <img src="./assets/notification.svg" alt="" />
          <div className={styles.userPhoto}>
            <img src="./assets/photo.svg" alt="" />
          </div>
        </div>
      </Box>

      <Box sx={{ mt: 10 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
          Serviços mais acessados
        </Typography>


        <div className={styles.problems}>
          <div className={styles.grid}>
            <Problems color={0} percentage={`10%`} number={10} content={"Infraestrutura"} />
            <Problems color={1} percentage={`25%`} number={25} content={"Abastecimento "} />
            <Problems color={2} percentage={`13%`} number={13} content={"Defesa Civil  "} />
            <Problems color={3} percentage={`12%`} number={12} content={"Obras"} />
            <Problems color={4} percentage={`5%`} number={5} content={"Segurança "} />
            <Problems color={5} percentage={`30%`} number={30} content={"Meio Ambiente"} />
            <Problems color={6} percentage={`1%`} number={1} content={"Saúde "} />
            <Problems color={7} percentage={`2%`} number={2} content={"Educação "} />
            <Problems color={8} percentage={`1%`} number={1} content={"IPTU "} />
          </div>
        </div>

      </Box>

    </Container>
  );
}