import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import * as XLSX from 'xlsx';
import { ReactComponent as  ExcelIcon} from "../../assets/excel.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search"
import FilterField from './components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import UserRegisterComponent from './components/UserRegisterComponent.tsx';
import RegisterCard from './components/RegisterCard.tsx';




const createDoughnutData = (value, color) => ({
  labels: [value >= 100 ? 'Resolvidos' : ' '], // Se o valor for 100%, label é 'Resolvidos', caso contrário, nenhuma label
  datasets: [
    {
      data: [value, 100 - value], // Valor e o restante até completar 100%
      backgroundColor: [color, 'rgba(0, 0, 0, 0.1)'], // Cor do valor e cor para o restante
      borderWidth: 0, // Sem bordas
    },
  ],
});

const options2 = {
  responsive: true,
  cutout: '70%', // Aumenta o espaço do centro para mais destaque
  plugins: {
    legend: {
      display: false, // Esconde a legenda do gráfico
    },
    tooltip: {
      enabled: false, // Desativa os tooltips
    },
  },
  maintainAspectRatio: false, // Permite que o gráfico de rosca seja redimensionado verticalmente
};



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20, // Arredondar os cantos
    position: 'relative',
    backgroundColor: '#383C40',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white', // Cor do texto
    padding: '5px 15px 5px 6px', // Espaçamento interno do Select
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Sobrescrever as bordas padrões
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    // Estilos para o ícone do dropdown

  },
}));



interface Filter {
  field: string;
  value: string | boolean;
}

interface User {
  id: string;
  name: string;
  phone: string;
  gender: string;
  birthday: string;
  // Add other user properties as needed
}


export default function Registers({ generalData }) {
  const [age, setAge] = useState("null");
  const [expanded, setExpanded] = useState(false);
  const [gender, setGender] = useState("null");
  const [maritalStatus, setMaritalStatus] = useState("null");
  const [children, setChildren] = useState("null");
  const [familyIncome, setFamilyIncome] = useState("null");
  const [govProgram, setGovProgram] = useState("null");
  const [cadUnico, setCadUnico] = useState("null");
  const [healthProfessional, setHealthProfessional] = useState("null");
  const [preIllnesses, setPreIllnesses] = useState("null");
  const [deficiency, setDeficiency] = useState("null");
  const [employmentStatus, setEmploymentStatus] = useState("null");
  const [race, setRace] = useState(null);
  const [education, setEducation] = useState("null");
  const [filters, setFilters] = useState<Filter[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [didFetch, setDidFetch] = useState(false);
  const [disabled, setDisabled] = useState(true);



  const userServices = new UserServices();


  function calculateAge(birthdate) {
    console.log(birthdate)
    

    const birthDateParts = birthdate.split("/");
    const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }


  const numbers = ["null"];
  for (let i = 1; i <= 100; i++) {
    numbers.push(`${i}`);
  }


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };




  const filterByAge = () => {
    console.log("aniveer")
    if (age === "10 a 15 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 10 && age <= 15;
        });
        return newData;
      });
    } else if (age === "16 a 20 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 16 && age <= 20;
        });
        return newData;
      });
    } else if (age === "21 a 30 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 21 && age <= 30;
        });
        return newData;
      });
    } else if (age === "31 a 40 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 31 && age <= 40;
        });
        return newData;
      });
    } else if (age === "41 a 50 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 41 && age <= 50;
        });
        return newData;
      });
    } else if (age === "51 a 60 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 51 && age <= 60;
        });
        return newData;
      });
    } else if (age === "61 a 70 anos") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age >= 61 && age <= 70;
        });
        return newData;
      });
    } else if (age === "acima de 70") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const age = calculateAge(e.birthday);
          return age > 70;
        });
        return newData;
      });
    }
  };

  const filterByIncome = () => {

    if (familyIncome === "até 1 salário") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const income = parseInt(e.income);
          return income <= 1412;
        });
        return newData;
      });
    } else if (familyIncome === "1 a 2 salários") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const income = parseInt(e.income);
          return income >= 1412 && income <= (1412*2);
        });
        return newData;
      });
    } else if (familyIncome === "3 a 4 salários") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const income = parseInt(e.income);
          return income >= (1412*3) && income <= (1412*4);
        });
        return newData;
      });
    } else if (familyIncome === "acima de 5 salários") {
      setUsers((prevUsers) => {
        const data = [...prevUsers];
        const newData = data.filter((e) => {
          const income = parseInt(e.income);
          return income >= (1412*5);
        });
        return newData;
      });
    } 


    
  };

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setAge(event.target.value);

  };
  const handleAge = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setAge(value);

  };

  const handleFamilyIncome = (event: SelectChangeEvent)=>{
    setFamilyIncome(event.target.value)
  }

  const handleGender = (event: SelectChangeEvent) => {
    const newGender = event.target.value as string;
    setGender(newGender);

    // Atualize o array de filtros
    if (newGender !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "gender"),
        { field: "gender", value: newGender }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "gender")
      );
    }
  }

  const handleEducation = (event: SelectChangeEvent) => {
    const newEducation = event.target.value as string;
    setEducation(newEducation);

    // Atualize o array de filtros
    if (newEducation !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "education"),
        { field: "education", value: newEducation }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "education")
      );
    }
  }

  const handleRace = (event: SelectChangeEvent) => {
    const newRace = event.target.value as string;
    setRace(newRace);

    // Atualize o array de filtros
    if (newRace !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "race"),
        { field: "race", value: newRace }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "race")
      );
    }
  }

  const handleExcel = ()=>{
    const workbook = XLSX.utils.book_new();

    // Criando uma planilha
    const headers = getHeaders(users); // Obtém os cabeçalhos dinamicamente
    const rows = users.map(item => headers.map(header => item[header] || '')); // Mapeia dinamicamente os valores para cada cabeçalho
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

    // Ajustando a largura das colunas
    const cols = headers.map(header => ({ wch: 20 })); // Define a largura padrão de 20 para todas as colunas
    worksheet['!cols'] = cols;

    // Adicionando a planilha ao livro de trabalho
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Convertendo o workbook para um Blob
    const workbookBlob = new Blob([XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })], { type: 'application/octet-stream' });

    // Criando uma URL para o Blob
    const workbookURL = URL.createObjectURL(workbookBlob);

    // Criando um link de download
    const downloadLink = document.createElement('a');
    downloadLink.href = workbookURL;
    downloadLink.setAttribute('download', 'dados.xlsx');

    // Simulando um clique no link para iniciar o download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Removendo o link após o download
    document.body.removeChild(downloadLink);
  }

  const getHeaders = (data) => {
    const allHeaders = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => allHeaders.add(key));
    });
    return Array.from(allHeaders);
  };

  const handleChildren = (event: SelectChangeEvent) => {
    const newChildren = event.target.value as string;
    setChildren(newChildren);

    if (newChildren !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "children"),
        { field: "children", value: newChildren }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "children")
      );
    }

  }

  // const handleAge = (event: SelectChangeEvent) => {
  //   const newAge = event.target.value as string;
  //   setAge(newAge);

  //   if (newAge !== "null") {
  //     setFilters((currentFilters) => [
  //       ...currentFilters.filter((filter) => filter.field !== "age"),
  //       { field: "age", value: newAge }
  //     ]);
  //   } else {
  //     // Remove o filtro de gender se o valor for "null"
  //     setFilters((currentFilters) =>
  //       currentFilters.filter((filter) => filter.field !== "age")
  //     );
  //   }

  // }

  const handleGovProgram = (event: SelectChangeEvent) => {
    const newGovProgram = event.target.value as string;
    setGovProgram(newGovProgram);

    if (newGovProgram !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "govProgram"),
        { field: "govProgram", value: newGovProgram }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "govProgram")
      );
    }



  }

  const handleCadUnico = (event: SelectChangeEvent) => {
    const newCadUnico = event.target.value as string;



    setCadUnico(newCadUnico);


    if (newCadUnico !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "cadunico"),
        { field: "cadunico", value: newCadUnico === "Sim" }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "cadunico")
      );
    }

  }

  const handleHealthProfessional = (event: SelectChangeEvent) => {
    const newHealthProfessional = event.target.value as string;
    setHealthProfessional(newHealthProfessional);

    if (newHealthProfessional !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "healthProfessional"),
        { field: "healthProfessional", value: newHealthProfessional }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "healthProfessional")
      );
    }


  }

  const handlePreIllnesses = (event: SelectChangeEvent) => {
    const newPreIllnesses = event.target.value as string;
    setPreIllnesses(newPreIllnesses);

    if (newPreIllnesses !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "preIllnesses"),
        { field: "preIllnesses", value: newPreIllnesses }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "preIllnesses")
      );
    }



  }

  const handleDeficiency = (event: SelectChangeEvent) => {
    const Deficiency = event.target.value as string;
    setDeficiency(Deficiency);

    if (Deficiency !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "deficiency"),
        { field: "deficiency", value: Deficiency }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "deficiency")
      );
    }

  }


  const handleMaritalStatus = (event: SelectChangeEvent) => {
    const newMaritalStatus = event.target.value as string;
    setMaritalStatus(newMaritalStatus)

    if (newMaritalStatus !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "maritalStatus"),
        { field: "maritalStatus", value: newMaritalStatus }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "maritalStatus")
      );
    }
  }

  const handleEmploymentStatus = (event: SelectChangeEvent) => {
    const newEmploymentStatus = event.target.value as string;
    setEmploymentStatus(newEmploymentStatus)

    if (newEmploymentStatus !== "null") {
      setFilters((currentFilters) => [
        ...currentFilters.filter((filter) => filter.field !== "employmentSituation"),
        { field: "employmentSituation", value: newEmploymentStatus }
      ]);
    } else {
      // Remove o filtro de gender se o valor for "null"
      setFilters((currentFilters) =>
        currentFilters.filter((filter) => filter.field !== "employmentSituation")
      );
    }
  }

  const fetchRegisters = async () => {

    try {
      const getUsers = await userServices.getUserFilter(filters);
      setUsers(getUsers);
      filterByAge()
      filterByIncome();
    } catch (error) {
      console.error('Failed to fetch users:', error);
      // Optionally handle the error, e.g., by setting an error state or an empty array
      setUsers([]);
    }

  }



  if (!didFetch) {
    fetchRegisters();
    setDidFetch(true);
  }



  return (
    <Container>

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
        {/* <Search /> */}
        <div className={styles.user}>
          <img src="./assets/icon.svg" alt="" />
          <p>{generalData.points} Pontos</p>
          <img src="./assets/notification.svg" alt="" />
          <div className={styles.userPhoto}>
            <img src="./assets/photo.svg" alt="" />
          </div>
        </div>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 5, marginLeft: 2 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 32 }}>
          Relatório de cadastros
        </Typography>
        <Box sx={{ display: "none", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 19 }}>
            Cadastros  realizados  no período.
          </Typography>
          <Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-customized-select-label"></InputLabel>

              <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 4px" }}>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={age}
                  onChange={handleChange}
                  input={<BootstrapInput
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={CalendarIcon} height="19" alt="Calendar" />
                      </InputAdornment>
                    }
                  />} // Utiliza o novo componente de input
                  IconComponent={ArrowDownIcon} // Ícone personalizado
                  sx={{
                    // Sobrescrever estilos específicos para o ícone
                    '& .MuiSvgIcon-root': {
                      color: 'lime', // Cor do ícone
                    },
                    '&:focus': {
                      backgroundColor: 'black', // Mantém o fundo escuro quando focado
                    },
                  }}
                >
                  <MenuItem value={"week"}>Jan - 2024</MenuItem>
                  <MenuItem value={"mounth"}>Feb - 2024</MenuItem>
                  <MenuItem value={"year"}>Mar - 2024</MenuItem>
                </Select>
              </Stack>
            </FormControl>


            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-customized-select-label"></InputLabel>

              <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 4px" }}>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={age}
                  onChange={handleChange}
                  input={<BootstrapInput
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={CalendarIcon} height="19" alt="Calendar" />
                      </InputAdornment>
                    }
                  />} // Utiliza o novo componente de input
                  IconComponent={ArrowDownIcon} // Ícone personalizado
                  sx={{
                    // Sobrescrever estilos específicos para o ícone
                    '& .MuiSvgIcon-root': {
                      color: 'lime', // Cor do ícone
                    },
                    '&:focus': {
                      backgroundColor: 'black', // Mantém o fundo escuro quando focado
                    },
                  }}
                >
                  <MenuItem value={"week"}>Jan - 2024</MenuItem>
                  <MenuItem value={"mounth"}>Feb - 2024</MenuItem>
                  <MenuItem value={"year"}>Mar - 2024</MenuItem>
                </Select>
              </Stack>
            </FormControl>

          </Box>
        </Box>
        <Stack></Stack>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <RegisterCard generalData={generalData} icon={"/assets/icon _mobile phone.svg"} number={users.length} title={"Efetuados"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} categories={generalData.categories} allowExpand={true} />
        <Card icon={"/assets/problemSolvedIcon.svg"} number={0} title={"Completos"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
        <Card icon={"/assets/fireIcon.svg"} number={0} title={"Inativos"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
        <Card icon={"/assets/waitingIcon.svg"} number={users.length} title={"Ativos"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
      </Box>

      <Box sx={{ marginTop: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>

        <Box sx={{ width: 600, mb: 5, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 19 }}>
            Selecione os campos
          </Typography>
          <FormControl variant="standard" sx={{ ml: 2, minWidth: 120 }}>
            <InputLabel shrink htmlFor="bootstrap-input"></InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={disabled}
              onChange={(e) => {
                if (e.target.value) {
                  setDisabled(e.target.value)
                  setFilters([])
                  fetchRegisters()
                } else {
                  setFilters([])
                  fetchRegisters()
                  setDisabled(e.target.value)
                }
              }}
              input={<BootstrapInput />}
              sx={{
                '& .MuiSvgIcon-root': {
                  color: 'black', // Cor do ícone preta
                },
              }}
            >
              <MenuItem value={true}>
                Todos
              </MenuItem>
              <MenuItem value={false}>
                Filtrar
              </MenuItem>

            </Select>
          </FormControl>
        </Box>



        {

          !disabled &&
          <>

            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 700, justifyContent: "flex-start" }}>

              <FilterField disabled={disabled} options={["null", "Feminino", "Masculino"]} value={gender} handleChange={handleGender} title={"Gênero"}></FilterField>
              <FilterField disabled={disabled} options={["null", "Solteiro", "Casado", "União estável", "Viuvo(a)"]} value={maritalStatus} handleChange={handleMaritalStatus} title={"Estado cívil"}></FilterField>
              <FilterField disabled={disabled} options={["null", "1", "2", "3", "4+"]} value={children} handleChange={handleChildren} title={"Filhos"}></FilterField>
              <FilterField disabled={disabled} options={["null","10 a 15 anos", "16 a 20 anos", "21 a 30 anos", "31 a 40 anos", "41 a 50 anos", "51 a 60 anos", "61 a 70 anos", "acima de 70"]} value={age} handleChange={handleAge} title={"Idade"}></FilterField>
              <FilterField disabled={disabled} options={["null", "1 salário", "1 a 2 salários", "3 a 4 salários", "acima de 5 salários"]} value={familyIncome} handleChange={handleFamilyIncome} title={"Renda Familiar"}></FilterField>
              {/* <FilterField disabled={disabled} options={["null", "Bolsa família", "Casa verde amarela"]} value={govProgram} handleChange={handleGovProgram} title={"Programa gov"}></FilterField> */}
              <FilterField disabled={disabled} options={["null", "Sim", "Não"]} value={cadUnico} handleChange={handleCadUnico} title={"CAD Único"}></FilterField>
              <FilterField disabled={disabled} options={["null", "Sim", "Não"]} value={healthProfessional} handleChange={handleHealthProfessional} title={"Profissional da saúde"}></FilterField>
              <FilterField disabled={disabled} options={["null", "Diabetes", "Hipertensão", "Soropositivo"]} value={preIllnesses} handleChange={handlePreIllnesses} title={"Doença pré-existente"}></FilterField>
              {/* <FilterField disabled={disabled} options={["null", "Cadeirante", "Cego", "Outra"]} value={deficiency} handleChange={handleDeficiency} title={"Possui deficiência?"}></FilterField> */}
              <FilterField disabled={disabled} options={["null", "Desempregado", "Empregado"]} value={employmentStatus} handleChange={handleEmploymentStatus} title={"Situação de trabalho"}></FilterField>
              <FilterField disabled={disabled} options={["null", "Branca", "Preta", "Parda", "Amarela", "Indígena", "Outra"]} value={race} handleChange={handleRace} title={"Etnia"}></FilterField>
              <FilterField disabled={disabled} options={["null", 'Analfabeto',
                'Ensino Fundamental Incompleto',
                'Ensino Fundamental Completo',
                'Ensino Médio Incompleto',
                'Ensino Médio Completo',
                'Ensino Técnico',
                'Ensino Superior Incompleto',
                'Ensino Superior Completo',
                'Pós-Graduação ou Superior']} value={education} handleChange={handleEducation} title={"Educação"}></FilterField>
              
            </Box>




          </>
        }
        <Button onClick={fetchRegisters} sx={{ backgroundColor: "#D3F26A", color: "black", mt: 3, borderRadius: "10px" }}>Aplicar filtros</Button>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
          Após sua busca foram encontrados esses registros
        </Typography>
        
        <Stack sx={{ width: "95%",display: "flex", flexDirection: "row-reverse", alignItems: "center", cursor: "pointer"}}>
        <Typography onClick={handleExcel} sx={{":hover":{borderBottom: "1px solid black"}}}>Exportar Excel</Typography>
        <ExcelIcon height={25} />
      </Stack>

        {users && users.map((e) => {
          return (
            <UserRegisterComponent userInfo={e}/>
          )
        })

        }

      </Box>

    </Container>
  );
}