import * as admin from 'firebase-admin';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, getFirestore, query, where, getDocs, limit as firestoreLimit, updateDoc, doc, orderBy } from "firebase/firestore";

const serviceAccount = process.env.ACC_SERVICE ?? require('../service-account.json');


const app = initializeApp(serviceAccount);
const db = getFirestore(app);
  

class UserServices{


    async getUserById(uid: string){

        try {
            const usersRef = collection(db, "users");
            
            const q = query(usersRef, where("id", "==", uid));
    
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                
                return null;
            }
    
            const user = querySnapshot.docs[0];
             
            const userData = user.data();
        
            // Retornar os dados do usuário
            return userData;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }

    }


    async getUsersByIds(ids: Array<string>) {
        try {
            const db = getFirestore();
            const usersRef = collection(db, "users");
    
            if (ids.length === 0) {
                
                return [];
            }
    
            // Firestore permite um máximo de 10 IDs em whereIn
            const maxIds = 10;
            let users = [];
    
            for (let i = 0; i < ids.length; i += maxIds) {
                const chunkIds = ids.slice(i, i + maxIds);
                const q = query(usersRef, where("id", "in", chunkIds));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const chunkUsers = querySnapshot.docs.map((doc: QueryDocumentSnapshot) => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    users = users.concat(chunkUsers);
                }
            }
    
            
            
            return users;
        } catch (error) {
            console.error("Erro ao buscar usuários:", error);
            return [];
        }
    }

    async getUserFilter(filters: Array<{ field: string, value: any }>) {
        try {
            const db = getFirestore(); // Supondo que você tenha uma função para obter sua instância Firestore
            const usersRef = collection(db, "users");
    
            // Inicializa a query base
            let q = query(usersRef, orderBy("points", "desc"));
    
            // Aplica os filtros
            filters.forEach(filter => {
                q = query(q, where(filter.field, "==", filter.value));
            });
    
            // Executar a query
            const querySnapshot = await getDocs(q);
            
            if (querySnapshot.empty) {
                return [];
            }
            
            // Mapear os resultados em um array de dados do usuário
            const users = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
    
            return users; // Retorna um array de usuários
        } catch (error) {
            console.error("Erro ao buscar usuários:", error);
            return [];
        }
    }


    async getUsersByName(name: string) {
        try {
            const db = getFirestore(); // Supondo que você tenha uma função para obter sua instância Firestore
            const usersRef = collection(db, "users");
    
            // Inicializa a query base
            let q = query(usersRef);
    
            // Aplica os filtros
            q = query(q, where("name", ">=", name));
    
            // Executar a query
            const querySnapshot = await getDocs(q);
            
            if (querySnapshot.empty) {
                
                return [];
            }
            
            // Mapear os resultados em um array de dados do usuário
            const users = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
    
            return users; // Retorna um array de usuários
        } catch (error) {
            console.error("Erro ao buscar usuários:", error);
            return [];
        }
    }



    async getAllAdminUsers(uid: string){

        try {
            const usersRef = collection(db, "adminUsers");
            
            const q = query(usersRef);
    
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                
                return null;
            }
    
            const users = querySnapshot.docs.map(e=>{
                const data = e.data();

                data["uid"] = e.id;
                return data;
            
            });
             
            
            
            return users;
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
            return null;
        }

    }



    async suspendUser(userId: string, action){
        try {
            
            
            const userRef = collection(db, "users");
            
            const userQuery = query(userRef, where("id", "==", userId)); 
            
                const usersQuerySnapshot = await getDocs(userQuery);

                
                

                if (!usersQuerySnapshot.empty) {
                    
                    
                    const userDocRef = doc(db, 'users', usersQuerySnapshot.docs[0].id);


                    
            
                    // Atualize o documento com a nova solução
                    await updateDoc(userDocRef, {
                        suspended: action
                    });
                    
                    
                    return { status: true };
                }
                return {status: false}
        } catch (e) {
            console.error("Error during official announcement: ", e);
            return { status: false };
        }
    }


}


export default UserServices;