import React, {useEffect} from 'react';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from './components/card.tsx';
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import WindowIcon from "../../assets/windowIcon.svg";
import ThunderIcon from "../../assets/ThunderIcon.svg";
import BinocularIcon from "../../assets/BinocularIcon.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { ReactComponent as  ExcelIcon} from "../../assets/excel.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Ticks } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../home/search"
import ProblemAccordion from "./components/problemInfo.tsx";
import ProblemReportDetail from '../../models/ProblemReportModel.ts';
import ProblemReportServices from '../../Services/ProblemReportServices.ts';
import ProblemsInfo from '../home/problemsInfo/index.jsx';
import AiChat from '../../screens/AIQueriesScreen/components/AiChat.tsx';

const problemReportService = new ProblemReportServices();

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const data = {
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
  datasets: [
    {
      label: 'Resolvidos',
      data: [10, 15, 30, 10, 15, 30, 10, 15, 30, 10, 15, 30, 30, 10, 15, 30], // Substitua estes dados pelos seus dados reais
      fill: false,
      backgroundColor: '#2D9CDB',
      borderColor: '#2D9CDB',
      borderWidth: 1,
      tension: 0.4 // Isso torna a linha um pouco curvada
    },
    {
      label: 'Em andamento',
      data: [20, 14, 10, 20, 14, 10, 20, 14, 10, 20, 14, 10, 20, 14, 10, 20, 14, 10], // Substitua estes dados pelos seus dados reais
      fill: false,
      backgroundColor: '#45EDDE',
      borderColor: '#45EDDE',
      borderWidth: 1,
      tension: 0.4 // Isso torna a linha um pouco curvada
    },
    {
      label: 'Aguardando',
      data: [10, 7, 3, 10, 7, 3, 10, 7, 3, 10, 7, 3, 10, 7, 3, 10, 7, 3], // Substitua estes dados pelos seus dados reais
      fill: false,
      backgroundColor: '#FF5B5B',
      borderColor: '#FF5B5B',
      borderWidth: 1,
      tension: 0.4 // Isso torna a linha um pouco curvada
    },

  ],
};

const options = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: true // Isso irá esconder as linhas de grade verticais
      }
    },
    y: {
      ticks: {
        stepSize: 10
      },
      grid: {
        display: false // Isso irá esconder as linhas de grade horizontais
      }
    }
  },
  plugins: {
    legend: false,
    title: false,
  },
};

const createDoughnutData = (value, color) => ({
  labels: [value >= 100 ? 'Resolvidos' : ' '], // Se o valor for 100%, label é 'Resolvidos', caso contrário, nenhuma label
  datasets: [
    {
      data: [value, 100 - value], // Valor e o restante até completar 100%
      backgroundColor: [color, 'rgba(0, 0, 0, 0.1)'], // Cor do valor e cor para o restante
      borderWidth: 0, // Sem bordas
    },
  ],
});

const options2 = {
  responsive: true,
  cutout: '70%', // Aumenta o espaço do centro para mais destaque
  plugins: {
    legend: {
      display: false, // Esconde a legenda do gráfico
    },
    tooltip: {
      enabled: false, // Desativa os tooltips
    },
  },
  maintainAspectRatio: false, // Permite que o gráfico de rosca seja redimensionado verticalmente
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20, // Arredondar os cantos
    position: 'relative',
    backgroundColor: '#383C40',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white', // Cor do texto
    padding: '5px 15px 5px 6px', // Espaçamento interno do Select
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Sobrescrever as bordas padrões
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    // Estilos para o ícone do dropdown

  },
}));




export default function ProblemReportsScreen({ generalData }) {
  const [limit, setLimit] = useState("10");
  const [dateRange, setDateRange] = useState(7);
  const [expanded, setExpanded] = useState(false);
  const [problems, setProblems] = useState<[ProblemReportDetail][]>([]);
  const [showChat, setShowChat] = useState(false);
  const [date, setDate] = useState("")
  const [currentCategory, setCurrentCategory] = useState("Todas");
  const [currentProblemsStatus, setCurrentProblemsStatus] = useState("")

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeLimit = (event: SelectChangeEvent) => {
    setLimit(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setDateRange(event.target.value);
  };

  useEffect(()=>{
    fetchByStatus()
  },[currentProblemsStatus])



  const handleExcel = ()=>{
    const workbook = XLSX.utils.book_new();

    // Criando uma planilha
    const headers = getHeaders(problems); // Obtém os cabeçalhos dinamicamente
    const rows = problems.map(item => headers.map(header => item[header] || '')); // Mapeia dinamicamente os valores para cada cabeçalho
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rows]);

    // Ajustando a largura das colunas
    const cols = headers.map(header => ({ wch: 20 })); // Define a largura padrão de 20 para todas as colunas
    worksheet['!cols'] = cols;

    // Adicionando a planilha ao livro de trabalho
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

    // Convertendo o workbook para um Blob
    const workbookBlob = new Blob([XLSX.write(workbook, { type: 'array', bookType: 'xlsx' })], { type: 'application/octet-stream' });

    // Criando uma URL para o Blob
    const workbookURL = URL.createObjectURL(workbookBlob);

    // Criando um link de download
    const downloadLink = document.createElement('a');
    downloadLink.href = workbookURL;
    downloadLink.setAttribute('download', 'dados.xlsx');

    // Simulando um clique no link para iniciar o download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Removendo o link após o download
    document.body.removeChild(downloadLink);
  }

  const getHeaders = (data) => {
    const allHeaders = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => allHeaders.add(key));
    });
    return Array.from(allHeaders);
  };

  const fetchByStatus = ()=>{
    if(currentProblemsStatus === 0){
      setProblems(problems=>{
        const data = [...problems]
        return data.filter(e=>e.status == 0)

      })
    }else if(currentProblemsStatus === 1){
      setProblems(problems=>{
        const data = [...problems]
        return data.filter(e=>e.status == 1)

      })
    }else if(currentProblemsStatus === 2){
      setProblems(problems=>{
        const data = [...problems]
        return data.filter(e=>e.status == 2)

      })
    }
  }


  const fetchProblems = async () => {
    try {
      const getProblems = date === "" ? await problemReportService.GetAllReportsInRangeDate(parseInt(limit), dateRange) : await problemReportService.getMonthlyReports(date, currentCategory);
      if(generalData.currentSecretariat === "all"){
        if(currentCategory != "Todas"){
          setProblems(prev=>{
            const data = [...getProblems || []];
            return data.filter(e=>e.category === currentCategory);
          });
        }else{
          setProblems(getProblems || []);
        }
        
      }else{
        setProblems(prev=>{
          const data = [...getProblems || []];
          return data.filter(e=>e.category === generalData.currentSecretariat);
        });
      }
      fetchByStatus()
    } catch (e) {
      setProblems([]); // If there's an error, set an empty array
    }
  };

  fetchProblems();




  return (
    <Container>

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
        {/* <Search /> */}
        <div className={styles.user}>
          <img src="./assets/icon.svg" alt="" />
          <p>{generalData.points} Pontos</p>
          <img src="./assets/notification.svg" alt="" />
          <div className={styles.userPhoto}>
            <img src="./assets/photo.svg" alt="" />
          </div>
        </div>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", marginTop: 5, marginLeft: 2 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 19 }}>
          Problemas relatados no período
        </Typography>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-customized-select-label"></InputLabel>

          <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 4px" }}>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={dateRange}
              onChange={handleChange}
              input={<BootstrapInput
                startAdornment={
                  <InputAdornment position="start">
                    <img src={CalendarIcon} height="19" alt="Calendar" />
                  </InputAdornment>
                }
              />} // Utiliza o novo componente de input
              IconComponent={ArrowDownIcon} // Ícone personalizado
              sx={{
                // Sobrescrever estilos específicos para o ícone
                '& .MuiSvgIcon-root': {
                  color: 'lime', // Cor do ícone
                },
                '&:focus': {
                  backgroundColor: 'black', // Mantém o fundo escuro quando focado
                },
              }}
            >
              <MenuItem value={7}>Semana</MenuItem>
              <MenuItem value={30}>Mês</MenuItem>
              <MenuItem value={365}>Ano</MenuItem>
            </Select>
          </Stack>
        </FormControl>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        <Card allowExpand={true} categories={generalData.categories} icon={"/assets/icon _mobile phone.svg"} number={generalData.reports} title={"Relatados (all)"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
        <Card allowExpand={false} categories={generalData.categories} icon={"/assets/problemSolvedIcon.svg"} number={problems.filter(e => e.status === 2).length} title={"Resolvidos"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
        <Card allowExpand={false} categories={generalData.categories} icon={"/assets/fireIcon.svg"} number={problems.filter(e => e.status === 1).length} title={"Em andamento"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
        <Card allowExpand={false} categories={generalData.categories} icon={"/assets/waitingIcon.svg"} number={problems.filter(e => e.status === 0).length} title={"Aguardando"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
      </Box>


      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 4, mt: 4 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 19, mr: 3 }}>Problemas por setores</Typography>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-customized-select-label"></InputLabel>

          <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 4px" }}>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={date}
              onChange={(e)=>setDate(e.target.value)}
              input={<BootstrapInput
                startAdornment={
                  <InputAdornment position="start">
                    <img src={CalendarIcon} height="19" alt="Calendar" />
                  </InputAdornment>
                }
              />} // Utiliza o novo componente de input
              IconComponent={ArrowDownIcon} // Ícone personalizado
              sx={{
                // Sobrescrever estilos específicos para o ícone
                '& .MuiSvgIcon-root': {
                  color: 'lime', // Cor do ícone
                },
                '&:focus': {
                  backgroundColor: 'black', // Mantém o fundo escuro quando focado
                },
              }}
            >
              
              <MenuItem value={1}>Jan - Fev</MenuItem>
              <MenuItem value={2}>Fev - Mar</MenuItem>
              <MenuItem value={3}>Mar - Abr</MenuItem>
              <MenuItem value={4}>Abr - Mai</MenuItem>
              <MenuItem value={5}>Mai - Jun</MenuItem>
              <MenuItem value={6}>Jun - Jul</MenuItem>
              <MenuItem value={7}>Jul - Ago</MenuItem>
              <MenuItem value={8}>Ago - Set</MenuItem>
              <MenuItem value={9}>Set - Out</MenuItem>
              <MenuItem value={10}>Out - Nov</MenuItem>
              <MenuItem value={11}>Nov - Dez</MenuItem>
              <MenuItem value={12}>Dez - Jan</MenuItem>
            </Select>
          </Stack>
        </FormControl>

        {
          generalData.currentSecretariat === "all" &&
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-customized-select-label"></InputLabel>

          <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 10px" }}>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={currentCategory}
              onChange={(e)=>setCurrentCategory(e.target.value)}
              input={<BootstrapInput
                startAdornment={
                  <InputAdornment position="start">
                    <img src={WindowIcon} height="19" alt="Calendar" />
                  </InputAdornment>
                }
              />} // Utiliza o novo componente de input
              IconComponent={ArrowDownIcon} // Ícone personalizado
              sx={{
                // Sobrescrever estilos específicos para o ícone
                '& .MuiSvgIcon-root': {
                  color: 'lime', // Cor do ícone
                },
                '&:focus': {
                  backgroundColor: 'black', // Mantém o fundo escuro quando focado
                },
              }}
            >
              
                    <MenuItem value={"Todas"}>Todas</MenuItem>
              {generalData.categories?.map(e=>{
                return <MenuItem value={e}>{e}</MenuItem>
              })}
              
            </Select>
          </Stack>
        </FormControl>
        }

        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-customized-select-label"></InputLabel>

          <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 10px" }}>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={currentProblemsStatus}
              onChange={(e)=>setCurrentProblemsStatus(e.target.value)}
              input={<BootstrapInput
                startAdornment={
                  <InputAdornment position="start">
                    <img src={ThunderIcon} height="19" alt="Calendar" />
                  </InputAdornment>
                }
              />} // Utiliza o novo componente de input
              IconComponent={ArrowDownIcon} // Ícone personalizado
              sx={{
                // Sobrescrever estilos específicos para o ícone
                '& .MuiSvgIcon-root': {
                  color: 'lime', // Cor do ícone
                },
                '&:focus': {
                  backgroundColor: 'black', // Mantém o fundo escuro quando focado
                },
              }}
            >
              <MenuItem value={0}>Em aberto</MenuItem>
              <MenuItem value={1}>Em andamento</MenuItem>
              <MenuItem value={2}>Solucionado</MenuItem>
            </Select>
          </Stack>
        </FormControl>

       
      </Box>




      <Stack sx={{ display: "flex", flexDirection: "row", mb: 5 }}>
        <Paper
          component="form"
          elevation={0}
          sx={{ backgroundColor: "#D9D9D9", p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, borderRadius: "25px" }}
        >
          <IconButton sx={{ p: '10px' }} aria-label="menu">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder=""
            inputProps={{ 'aria-label': 'search google maps' }}
          />

        </Paper>


        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-customized-select-label"></InputLabel>

          <Stack sx={{ backgroundColor: '#383C40', borderRadius: "20px", padding: "0px 10px 0 10px" }}>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={limit}
              onChange={handleChangeLimit}
              input={<BootstrapInput />} // Utiliza o novo componente de input
              IconComponent={ArrowDownIcon} // Ícone personalizado
              sx={{
                // Sobrescrever estilos específicos para o ícone
                '& .MuiSvgIcon-root': {
                  color: 'lime', // Cor do ícone
                },
                '&:focus': {
                  backgroundColor: 'black', // Mantém o fundo escuro quando focado
                },
              }}
            >
              <MenuItem value={"10"}>Mostrar: 10</MenuItem>
              <MenuItem value={"20"}>Mostrar: 20</MenuItem>
              <MenuItem value={"30"}>Mostrar: 30</MenuItem>
              <MenuItem value={"10000"}>Todos</MenuItem>
            </Select>
          </Stack>
        </FormControl>
      </Stack>



      <Stack sx={{ width: "95%",display: "flex", flexDirection: "row-reverse", alignItems: "center", cursor: "pointer"}}>
        <Typography onClick={handleExcel} sx={{":hover":{borderBottom: "1px solid black"}}}>Exportar Excel</Typography>
        <ExcelIcon height={25} />
      </Stack>


      {problems.map(e => <ProblemAccordion key={e.id} problemInfo={e} />)}

      <Box sx={{ display: "flex", flexDirection: "row", mb: 50 }}>


        <Box sx={{
          display: 'flex',
          flexDirection: "column",
        }}>

          <Box sx={{ display: "flex" }}>
            {!showChat &&
              <div onClick={() => setShowChat(true)}  className={styles.doubts}>
                <div className={styles.ia}>
                  <div className={styles.robot}>
                    <img src="./assets/robot.svg" alt="" />
                  </div>
                  <p>Fale com a nossa I.A</p>
                  <h2>Consultar I.A.</h2>
                </div>

                <div className={styles.middleColumn}></div> {/* Coluna do meio */}

                <div className={styles.ia}>
                  <div className={styles.answer}>
                    <img src="./assets/answer.svg" alt="" />
                  </div>
                  <p>Tire suas dúvidas</p>
                  <h2>Consultar I.A.</h2>
                </div>
              </div>
            }


            {
              showChat && <AiChat />
            }
          </Box>
        </Box>



      </Box>





    </Container>
  );
}