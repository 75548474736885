import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';





const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20, // Arredondar os cantos
    position: 'relative',
    backgroundColor: '#383C40',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white', // Cor do texto
    padding: '5px 15px 5px 6px', // Espaçamento interno do Select
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Sobrescrever as bordas padrões
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    // Estilos para o ícone do dropdown

  },
}));




export default function CarpoolingScreen({ generalData }) {



  return (
    <Container>

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
        {/* <Search /> */}
        <div className={styles.user}>
          <img src="./assets/icon.svg" alt="" />
          <p>{generalData.points} Pontos</p>
          <img src="./assets/notification.svg" alt="" />
          <div className={styles.userPhoto}>
            <img src="./assets/photo.svg" alt="" />
          </div>
        </div>
      </Box>

      <Box sx={{ mt: 10 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
          Caronas solidárias
        </Typography>



        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Card icon={"/assets/carpoolingArrow.svg"} number={18} title={"Caronas oferecidas"} iconBgcolor={"#FFD35C"} />
          <Card icon={"/assets/carpoolingIcon.svg"} number={15} title={"Caronas finalizadas"} iconBgcolor={"#FFD35C"} />
          <Card icon={"/assets/canceledCarpooling.svg"} number={0} title={"Caronas canceladas"} iconBgcolor={"#FFD35C"} />
        </Box>

      </Box>

    </Container>
  );
}