import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';
import FilterField from '../registersScreen/components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminServices from '../../Services/AdminServices.ts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import PinMap from "../../assets/pinMap.svg"
import CheckIcon from '@mui/icons-material/Check';
import { Add, Edit } from '@mui/icons-material';
import Highlight from './components/highlight.tsx';




const adminService = new AdminServices();





export default function HighlightScreen({ generalData }) {

    const [currentHighlight, setCurrentHighlight] = useState("primeiro");




    const userServices = new UserServices();




    const addHighlight = async()=>{
        try {
            
            const res = await adminService.addHighlight();

            if (res.status) {
                alert("Destaque adicionado")
                window.location.reload();
            } else {
                alert("não foi possível atualizar destaque")
            }

        } catch (e) {
            alert("não foi possível atualizar destaque")
        }
    }



    return (
        <Container>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
                {/* <Search /> */}
                <div className={styles.user}>
                    <img src="./assets/icon.svg" alt="" />
                    <p>{generalData.points} Pontos</p>
                    <img src="./assets/notification.svg" alt="" />
                    <div className={styles.userPhoto}>
                        <img src="./assets/photo.svg" alt="" />
                    </div>
                </div>
            </Box>

            <Box sx={{ mt: 10 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                    Cadastrar Destaque
                </Typography>






                <Box sx={{ display: "flex", flexDirection: "row" }}>


                    {
                        generalData.highlights &&
                        generalData.highlights.map((e, index) => {
                            return (
                                <Highlight key={index} id={index} handleCurrentHighlight={(handle) => setCurrentHighlight(handle)} color={"#6AF2B1"} highlightInformation={e}  />
                            )
                        })
                    }



                    {
                        generalData.highlights &&
                        generalData.highlights.length<4 &&
                        <Stack onClick={addHighlight}
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "start" }}
                    >
                        <Stack sx={{ p: 0.5, m: 1, height: 80, width: 80, borderRadius: 50, border: "1px solid #D3F26A" }}>
                            <Stack sx={{ height: "100%", width: "100%", borderRadius: 50, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Add sx={{fontSize: 50}} />
                            </Stack>
                        </Stack>

                    </Stack>
                    }


                    {/* <Highlight handleCurrentHighlight={(handle)=>setCurrentHighlight(handle)} color={"#F2A36A"}/>

                    <Highlight handleCurrentHighlight={(handle)=>setCurrentHighlight(handle)} color={"#D3F26A"}/>

                    <Highlight handleCurrentHighlight={(handle)=>setCurrentHighlight(handle)} color={"#1B85D2"}/> */}





                </Box>


                {
                    currentHighlight && 
                    <Box sx={{ mt: 5, mb: 5 }}>
                    {/* Componente para exibir o destaque atual */}
                    <Stack sx={{ p: 0.5, width: 200, height: 356, borderRadius: 2, border: "5px solid #D3F26A" }}>
                        <Stack
                            sx={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(255,255,255,0.3)",
                                borderRadius: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundImage: currentHighlight ? `url('${currentHighlight}')` : "none",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                                {/* Conteúdo a ser exibido dentro do destaque */}
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
                }




            </Box>

        </Container>
    );
}