import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import {TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale,ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import SendIcon from '@mui/icons-material/Send';
import AdminServices from '../../../Services/AdminServices.ts';
import CircularProgress from '@mui/material/CircularProgress';


export default function AiChat(){

    const [chat, setChat] = useState([]);
    const [msg, setMsg] = useState("");
    const [numMsgs, setNumMsgs] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {

        
        if(chat.length>numMsgs){
            if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
            setNumMsgs(chat.length);            
        }

    }, [chat]);
    

    const adminServices = new AdminServices();

    useEffect(()=>{
        fetchChat()
    }, [chat])

    const sendIaMsg = async()=>{
        
        if(msg.length>0 && msg.trim()){
            setIsLoading(true);
            try{
                const res = await adminServices.sendMsgToIa(msg);
                if(res.status){
                    setMsg("");
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                    return
                }
            }catch(e){
                return
            }
        }

    }
    
    const handleMsg = async(event)=>{
        setMsg(event.target.value);
    }

    const fetchChat = async()=>{
        const getChat = await adminServices.getIAChat();
        setChat(getChat);
    }

    fetchChat()



    return(
        <Box sx={{ height: 300, width: 230, backgroundColor: "white", mt:2 }}>
        <Stack sx={{ height: 50, width: "100%", backgroundColor: "#383C40", display: "flex", alignItems: "center", flexDirection: "row", borderRadius: "5px 5px 0 0" }}>
          <Stack sx={{ ml: 1, backgroundColor: "#D3F26A", height: 30, width: 30, display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 50 }}>
            <img src="./assets/robot.svg" height={20} alt="" />
          </Stack>
          <Typography sx={{ color: "#D3F26A", fontSize: 12, fontWeight: 500, ml: 2 }}>CID</Typography>
        </Stack>
        <Stack sx={{ display: "flex", overflowY: "auto", height: 240 }} ref={scrollRef}>
            {chat && chat.map(e=>{
                return(
                    <Stack sx={{ wordBreak: "break-all", padding: "10px", fontSize: 12, color: e.senderType == "user"?"white": "black", backgroundColor: e.senderType == "user"? "#383C40" : "#D3F26A", margin: 1, alignSelf: e.senderType == "user"? 'flex-end':"flex-start" }}>
                        {e.msg}
                    </Stack>
                )
            })}
        </Stack>
        <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: 50, backgroundColor: "#383C40", borderRadius: "0 0 5px 5px" }}>
          <input type="text" style={{ outline: "none", border: "none", height: 25, width: 220, borderRadius: 5,marginLeft:4 }} value={msg} onChange={handleMsg} />
          <IconButton>{!isLoading && <SendIcon onClick={()=>sendIaMsg()} sx={{ color: "#D3F26A" }} />} {isLoading && <CircularProgress />}</IconButton>
        </Stack>
      </Box>
    )
}