import React, { useEffect, useState } from 'react'
import { Box, Container, Stack, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import FilterField from '../../../screens/registersScreen/components/filterField.tsx';
import { Pie } from 'react-chartjs-2';
import './style.css';
import AdminServices from '../../../Services/AdminServices.ts';

ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const adminServices = new AdminServices();


function calculateAge(birthdate) {

    const birthDateParts = birthdate.split("/");
    const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

function generateRandomRGBA(opacity) {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Gerar uma lista de cores aleatórias
const randomColors = Array.from({ length: 12 }, () => generateRandomRGBA(0.2));



export default function RegisterCard({ icon, number, title, iconBgcolor, categories, allowExpand, generalData }) {


    const [expanded, setExpanded] = useState(false)
    const [height, setHeight] = useState(125)
    const [width, setWidth] = useState(218);
    const [filter, setFilter] = useState();
    const [currentCategory, setCurrentCategory] = useState("Todas");
    const [activeTab, setActiveTab] = useState(0);
    const [chartKey, setChartKey] = useState(Date.now());



    const [genderData, setGenderData] = useState({
        labels: ['Mulheres', 'Homens', 'Não informados'], // Rótulos das barras
        datasets: [{
            label: 'Gênero',
            data: [generalData.female, generalData.male, (generalData.users - (generalData.female + generalData.male))], // Dados para cada barra
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
        },

        ]
    })

    const [raceData, setRaceData] = useState({
        labels: ['Branca', 'Preta', 'Parda', "Amarela", "Indígena", "Outra"], // Rótulos das barras
        datasets: [{
            label: 'Gênero',
            data: [10, 20, 30, 10, 10, 15], // Dados para cada barra
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
        },

        ]
    })

    const [incomeData, setIncomeData] = useState({
        labels: ['1 a 2 salários', '2 a 3 salários', '3 a 5 salários', "4 a 5 salários", "5 a 6 salários", "7 a 10 salários"], // Rótulos das barras
        datasets: [{
            label: 'Renda',
            data: [10, 20, 30, 10, 10, 15], // Dados para cada barra
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
        },

        ]
    });

    const [employmentData, setEmploymentData] = useState({
        labels: ["Desempregado", "Empregado", "Autônomo", "Empresário", "Não informado"], // Rótulos das barras
        datasets: [{
            label: 'Situação Empregatícia',
            data: [10, 20, 30, 10], // Dados para cada barra
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
        },

        ]
    })

    const [educationData, setEducationData] = useState({
        labels: ['Analfabeto',
            'Ensino Fundamental Incompleto',
            'Ensino Fundamental Completo',
            'Ensino Médio Incompleto',
            'Ensino Médio Completo',
            'Ensino Técnico',
            'Ensino Superior Incompleto',
            'Ensino Superior Completo',
            'Pós-Graduação ou Superior'], // Rótulos das barras
        datasets: [{
            label: 'Situação educacional',
            data: [10, 20, 30, 10, 10, 10, 10, 10, 10], // Dados para cada barra
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
        },

        ]
    });

    const [ageData, setAgeData] = useState({
        labels: ["10 a 15 anos", "16 a 20 anos", "21 a 30 anos", "31 a 40 anos", "41 a 50 anos", "51 a 60 anos", "61 a 70 anos", "acima de 70"], // Rótulos das barras
        datasets: [{
            label: 'Faixa etária',
            data: [10, 20, 30, 10, 10, 10, 10, 10], // Dados para cada barra
            backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
        },

        ]
    })


    const [chartsGenderInfo, setChartsInfo] = useState([
        { title: 'Mulheres', value: parseInt((generalData.female / generalData.users) * 100), color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Homens', value: parseInt((generalData.male / generalData.users) * 100), color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Não informados', value: parseInt(((generalData.users - (generalData.male + generalData.female)) / generalData.users) * 100), color: 'rgba(75, 192, 192, 0.5)' },
    ]);


    const [chartsRaceInfo, setChartsRaceInfo] = useState([
        { title: 'Branca', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Preta', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Parda', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Amarela', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Indígena', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Outra', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
    ]);

    const [chartsIncomeInfo, setChartsIncomeInfo] = useState([
        { title: 'Entre 1 e 2 sálarios', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Entre 2 e 3 sálarios', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Entre 3 e 5 sálarios', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Entre 5 e 7 sálarios', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Entre 7 e 10 sálarios', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Mais de 10 salários mínimos', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
    ]);



    const [chartsEmploymentInfo, setChartsEmploymentInfo] = useState([
        { title: 'Empregado', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Desempregado', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Autônomo', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Empresário', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
    ]);


    const [chartsEducationInfo, setChartsEducationInfo] = useState([
        { title: 'Analfabeto', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Ensino Fundamental Incompleto', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Ensino Fundamental Completo', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Ensino Médio Incompleto', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Ensino Médio Completo', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Ensino Técnico', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Ensino Superior Incompleto', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Ensino Superior Completo', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Pós-Graduação ou Superior', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
    ]);


    const [chartsAgeInfo, setChartsAgeInfo] = useState([
        { title: '10 a 15 anos', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: '16 a 20 anos', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: '21 a 30 anos', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: '31 a 40 anos', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: '41 a 50 anos', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: '51 a 60 anos', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
        { title: '61 a 70 anos', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'acima de 70 anos', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
    ]);




    const fetchRaceChart = async () => {
        try {

            const raceChartData = await adminServices.getRaceRegistersDataToChart();

            setChartsRaceInfo((prev) => {
                const data = [...prev];

                data[0].value = raceChartData?.races?.branca;
                data[1].value = raceChartData?.races?.preta;
                data[2].value = raceChartData?.races?.parda;
                data[3].value = raceChartData?.races?.amarela;
                data[4].value = raceChartData?.races?.indigena;
                data[5].value = raceChartData?.races?.outra;
                return data;
            })


            setRaceData(prev => {

                const data = { ...prev };

                data.datasets[0].data = [
                    raceChartData?.races.branca,
                    raceChartData?.races.preta,
                    raceChartData?.races.parda,
                    raceChartData?.races.amarela,
                    raceChartData?.races.indigena,
                    generalData.users - (raceChartData?.races.branca + raceChartData?.races.preta + raceChartData?.races.parda + raceChartData?.races.amarela + raceChartData?.races.indigena)
                ];

                return data;
            })



        } catch (e) {
            alert("erro ao recuperar dados do gráfico");
        }
    }


    const fetchEducationChart = async () => {
        try {
            const educationChartData = await adminServices.getEducationRegistersDataToChart();

            // Calcula a soma dos valores das categorias de educação informadas
            const totalInformado = Object.values(educationChartData).reduce((acc, curr) => acc + curr, 0);

            // Calcula o número de usuários não informados
            const naoInformado = generalData.users - totalInformado;

            setChartsEducationInfo((prev) => {
                const data = [...prev];
                data[0].value = educationChartData.analfabeto;
                data[1].value = educationChartData.ens_fund_inc;
                data[2].value = educationChartData.ens_fund_com;
                data[3].value = educationChartData.ens_med_inc;
                data[4].value = educationChartData.ens_med_com;
                data[5].value = educationChartData.ens_tecnico;
                data[6].value = educationChartData.ens_sup_inc;
                data[7].value = educationChartData.ens_sup_com;
                data[8].value = educationChartData.pos_grad_sup;
                const naoInformadoIndex = data.findIndex(item => item.title === "Não informado");
                if (naoInformadoIndex !== -1) {
                    data[naoInformadoIndex].value = naoInformado;
                } else {
                    data.push({ title: "Não informado", value: naoInformado, color: "rgba(0, 0, 0, 0.5)" }); // Adiciona o item "Não informado"
                }
                return data;
            });

            setEducationData((prev) => {
                const data = { ...prev };
                data.datasets[0].data = [
                    educationChartData.analfabeto,
                    educationChartData.ens_fund_inc,
                    educationChartData.ens_fund_com,
                    educationChartData.ens_med_inc,
                    educationChartData.ens_med_com,
                    educationChartData.ens_tecnico,
                    educationChartData.ens_sup_inc,
                    educationChartData.ens_sup_com,
                    educationChartData.pos_grad_sup,
                ];
                return data;
            });
        } catch (error) {
            alert("Erro ao recuperar dados do gráfico de educação");
        }
    };


    const fetchEmploymentChart = async () => {
        try {
            const employmentChartData = await adminServices.getEmploymentRegistersDataToChart();

            // Atualiza o estado chartsEmploymentInfo
            setChartsEmploymentInfo([
                { title: 'Desempregado', value: employmentChartData.desempregado, color: 'rgba(255, 99, 132, 0.5)' },
                { title: 'Empregado', value: employmentChartData.empregado, color: 'rgba(54, 162, 235, 0.5)' },
                { title: 'Autônomo', value: employmentChartData.autonomo, color: 'rgba(75, 192, 192, 0.5)' },
                { title: 'Empresário', value: employmentChartData.empresario, color: 'rgba(255, 206, 86, 0.5)' },
                { title: 'Não informado', value: generalData.users - (employmentChartData.desempregado + employmentChartData.empregado + employmentChartData.autonomo + employmentChartData.empresario), color: 'rgba(153, 102, 255, 0.5)' },
            ]);

            // Atualiza o estado employmentData
            setEmploymentData(prevData => ({
                ...prevData,
                datasets: [{
                    ...prevData.datasets[0],
                    data: [employmentChartData.desempregado, employmentChartData.empregado, employmentChartData.autonomo, employmentChartData.empresario, generalData.users - (employmentChartData.desempregado + employmentChartData.empregado + employmentChartData.autonomo + employmentChartData.empresario)]
                }]
            }));
        } catch (error) {
            console.error("Erro ao recuperar dados de situação de emprego:", error);
            alert("Erro ao recuperar dados de situação de emprego.");
        }
    };

    const fetchAgeChart = async () => {
        try {
            const ageChartData = await adminServices.getAgeRegistersDataToChart();
            console.log("Dados de idade:", ageChartData);

            // Calcula o total de usuários
            const totalUsers = Object.values(ageChartData).reduce((acc, cur) => acc + cur, 0);
            console.log("Total de usuários:", totalUsers);

            // Atualiza o estado ageData com os novos dados
            setAgeData(prev => ({
                ...prev,
                datasets: [{
                    ...prev.datasets[0],
                    data: [
                        ageChartData["10_to_15"],
                        ageChartData["16_to_20"],
                        ageChartData["21_to_30"],
                        ageChartData["31_to_40"],
                        ageChartData["41_to_50"],
                        ageChartData["51_to_60"],
                        ageChartData["61_to_70"],
                        ageChartData["above_70"]
                    ]
                }]
            }));

            // Atualiza o estado chartsAgeInfo com as porcentagens
            setChartsAgeInfo([
                { title: '10 a 15 anos', value: parseInt((ageChartData["10_to_15"] / totalUsers) * 100), color: 'rgba(255, 99, 132, 0.5)' },
                { title: '16 a 20 anos', value: parseInt((ageChartData["16_to_20"] / totalUsers) * 100), color: 'rgba(75, 192, 192, 0.5)' },
                { title: '21 a 30 anos', value: parseInt((ageChartData["21_to_30"] / totalUsers) * 100), color: 'rgba(75, 192, 192, 0.5)' },
                { title: '31 a 40 anos', value: parseInt((ageChartData["31_to_40"] / totalUsers) * 100), color: 'rgba(255, 99, 132, 0.5)' },
                { title: '41 a 50 anos', value: parseInt((ageChartData["41_to_50"] / totalUsers) * 100), color: 'rgba(75, 192, 192, 0.5)' },
                { title: '51 a 60 anos', value: parseInt((ageChartData["51_to_60"] / totalUsers) * 100), color: 'rgba(75, 192, 192, 0.5)' },
                { title: '61 a 70 anos', value: parseInt((ageChartData["61_to_70"] / totalUsers) * 100), color: 'rgba(255, 99, 132, 0.5)' },
                { title: 'acima de 70 anos', value: parseInt((ageChartData["above_70"] / totalUsers) * 100), color: 'rgba(255, 99, 132, 0.5)' },
            ]);

        } catch (error) {
            console.error("Erro ao recuperar dados de idade:", error);
            alert("Erro ao recuperar dados de idade.");
        }
    };




    useEffect(() => {
        fetchRaceChart();
        fetchEducationChart();
        fetchEmploymentChart();
        fetchAgeChart();
    }, [])



    const changeTab = (index) => {
        setActiveTab(index); 
    };

    const colors = ["#E53761", "#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735", "#E53761", "#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735", "#E53761", "#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735"];




    const optionsLine = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: true // Isso irá esconder as linhas de grade verticais
                }
            },
            y: {
                ticks: {
                    stepSize: 10
                },
                grid: {
                    display: false // Isso irá esconder as linhas de grade horizontais
                }
            }
        },
        plugins: {
            legend: false,
            title: false,
        },
    };


    const optionsGenderAge = {
        indexAxis: 'y', // Configura as barras para serem horizontais
        scales: {
            x: {
                beginAtZero: true // Inicia o eixo X do zero
            }
        },
        plugins: {
            legend: {
                position: 'right' // Posiciona a legenda à direita
            }
        }
    };





    const options = {
        indexAxis: 'y', // Configura as barras para serem horizontais
        scales: {
            x: {
                beginAtZero: true // Inicia o eixo X do zero
            }
        },
        plugins: {
            legend: {
                position: 'right' // Posiciona a legenda à direita
            }
        }
    };



    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };



    const createDoughnutData = (value, color) => ({
        labels: [value >= 100 ? 'Resolvidos' : ' '], // Se o valor for 100%, label é 'Resolvidos', caso contrário, nenhuma label
        datasets: [
            {
                data: [value, 100 - value], // Valor e o restante até completar 100%
                backgroundColor: [color, 'rgba(0, 0, 0, 0.1)'], // Cor do valor e cor para o restante
                borderWidth: 0, // Sem bordas
            },
        ],
    });

    const optionsDoughnutData = {
        responsive: true,
        cutout: '70%', // Aumenta o espaço do centro para mais destaque
        plugins: {
            legend: {
                display: false, // Esconde a legenda do gráfico
            },
            tooltip: {
                enabled: false, // Desativa os tooltips
            },
        },
        maintainAspectRatio: false, // Permite que o gráfico de rosca seja redimensionado verticalmente
    };





    const tabContent = [
        {
            title: "Gênero",
            content: <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowY: "auto", // Ative a rolagem vertical
                    maxHeight: "800px",
                    width: "100%"
                }}
            >


                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 4,
                        flexWrap: "wrap",
                        alignItems: "center",
                        backgroundColor: "white",
                        width: "600px",
                        borderRadius: 5,
                        boxShadow: "1px 1px 5px black",
                        marginTop: 12,
                        marginBottom: 5
                    }}
                >
                    {chartsGenderInfo.map((chart, index) => (
                        <Box
                            key={index}
                            sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }} // Ajuste a largura e alinhamento
                        >
                            <Box sx={{ width: "75%", height: "50%" }}>
                                <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
                            </Box>
                            <Box component="span" sx={{ fontWeight: "bold", mt: 0 }}>
                                {chart.title}
                            </Box>
                            <Box component="span">
                                {chart.value}%
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        width: 600,
                        height: 310,
                        borderRadius: 5,
                        boxShadow: "1px 1px 5px black",
                        padding: 5,
                        marginBottom: 2, // Adicione um espaço entre os gráficos
                    }}
                >
                    <Bar
                        data={genderData}
                        options={options}
                        key={chartKey}
                        style={{ height: "100%", width: "100%" }} // Ajuste para que o Bar preencha o Box
                    />
                </Box>


            </Box>,
        },
        {
            title: "Etnia",
            content:
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowY: "auto", // Ative a rolagem vertical
                        maxHeight: "1000px",
                        width: "100%"
                    }}
                >


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 4,
                            flexWrap: "wrap",
                            alignItems: "center",
                            backgroundColor: "white",
                            width: "600px",
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            marginTop: 5,
                            marginBottom: 5
                        }}
                    >
                        {chartsRaceInfo.map((chart, index) => (
                            <Box
                                key={index}
                                sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }} // Ajuste a largura e alinhamento
                            >
                                <Box sx={{ width: "75%", height: "50%" }}>
                                    <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
                                </Box>
                                <Box component="span" sx={{ fontWeight: "bold", mt: 0 }}>
                                    {chart.title}
                                </Box>
                                <Box component="span">
                                    {chart.value}%
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            height: 310,
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            padding: 5,
                            marginBottom: 2, // Adicione um espaço entre os gráficos
                        }}
                    >
                        <Bar
                            data={raceData}
                            options={options}
                            key={chartKey}
                            style={{ height: "100%", width: "100%" }} // Ajuste para que o Bar preencha o Box
                        />
                    </Box>


                </Box>,
        },
        // {
        //     title: "Renda",
        //     content:
        //         <Box
        //             sx={{
        //                 display: "flex",
        //                 flexDirection: "column",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 overflowY: "auto", // Ative a rolagem vertical
        //                 maxHeight: "600px",
        //                 width: "100%"
        //             }}
        //         >


        //             <Box
        //                 sx={{
        //                     display: "flex",
        //                     justifyContent: "space-between",
        //                     padding: 4,
        //                     flexWrap: "wrap",
        //                     alignItems: "center",
        //                     backgroundColor: "white",
        //                     width: "600px",
        //                     borderRadius: 5,
        //                     boxShadow: "1px 1px 5px black",
        //                     marginTop: 40,
        //                     marginBottom: 5
        //                 }}
        //             >
        //                 {chartsIncomeInfo.map((chart, index) => (
        //                     <Box
        //                         key={index}
        //                         sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }} // Ajuste a largura e alinhamento
        //                     >
        //                         <Box sx={{ width: "75%", height: "50%" }}>
        //                             <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
        //                         </Box>
        //                         <Box component="span" sx={{ fontWeight: "bold", mt: 0 }}>
        //                             {chart.title}
        //                         </Box>
        //                         <Box component="span">
        //                             {chart.value}%
        //                         </Box>
        //                     </Box>
        //                 ))}
        //             </Box>
        //             <Box
        //                 sx={{
        //                     width: 600,
        //                     height: 310,
        //                     borderRadius: 5,
        //                     boxShadow: "1px 1px 5px black",
        //                     padding: 5,
        //                     marginBottom: 2, // Adicione um espaço entre os gráficos
        //                 }}
        //             >
        //                 <Bar
        //                     data={incomeData}
        //                     options={options}
        //                     key={chartKey}
        //                     style={{ height: "100%", width: "100%" }} // Ajuste para que o Bar preencha o Box
        //                 />
        //             </Box>


        //         </Box>,
        // },
        {
            title: "Situação empregatícia",
            content:

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowY: "auto", // Ative a rolagem vertical
                        maxHeight: "1000px",
                        width: "100%"
                    }}
                >


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 4,
                            flexWrap: "wrap",
                            alignItems: "center",
                            backgroundColor: "white",
                            width: "600px",
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            marginTop: 5,
                            marginBottom: 5
                        }}
                    >
                        {chartsEmploymentInfo.map((chart, index) => (
                            <Box
                                key={index}
                                sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }} // Ajuste a largura e alinhamento
                            >
                                <Box sx={{ width: "75%", height: "50%" }}>
                                    <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
                                </Box>
                                <Box component="span" sx={{ fontWeight: "bold", mt: 0 }}>
                                    {chart.title}
                                </Box>
                                <Box component="span">
                                    {chart.value}%
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            height: 310,
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            padding: 5,
                            marginBottom: 2, // Adicione um espaço entre os gráficos
                        }}
                    >
                        <Bar
                            data={employmentData}
                            options={options}
                            key={chartKey}
                            style={{ height: "100%", width: "100%" }} // Ajuste para que o Bar preencha o Box
                        />
                    </Box>


                </Box>,
        },
        {
            title: "Educação",
            content:

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowY: "auto", // Ative a rolagem vertical
                        maxHeight: "1500px",
                        width: "100%"
                    }}
                >


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 4,
                            flexWrap: "wrap",
                            alignItems: "center",
                            backgroundColor: "white",
                            width: "600px",
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            marginTop: 5,
                            marginBottom: 5
                        }}
                    >
                        {chartsEducationInfo.map((chart, index) => (
                            <Box
                                key={index}
                                sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }} // Ajuste a largura e alinhamento
                            >
                                <Box sx={{ width: "75%", height: "50%" }}>
                                    <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
                                </Box>
                                <Box component="span" sx={{ fontWeight: "bold", mt: 0, textAlign: "center" }}>
                                    {chart.title}
                                </Box>
                                <Box component="span">
                                    {chart.value}%
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            height: 310,
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            padding: 5,
                            marginBottom: 2, // Adicione um espaço entre os gráficos
                        }}
                    >
                        <Bar
                            data={educationData}
                            options={options}
                            key={chartKey}
                            style={{ height: "100%", width: "100%" }} // Ajuste para que o Bar preencha o Box
                        />
                    </Box>


                </Box>,
        },
        {
            title: "Faixa etária",
            content:

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowY: "auto", // Ative a rolagem vertical
                        maxHeight: "1200px",
                        width: "100%"
                    }}
                >


                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 4,
                            flexWrap: "wrap",
                            alignItems: "center",
                            backgroundColor: "white",
                            width: "600px",
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            marginTop: 5,
                            marginBottom: 5
                        }}
                    >
                        {chartsAgeInfo.map((chart, index) => (
                            <Box
                                key={index}
                                sx={{ width: "30%", display: "flex", flexDirection: "column", alignItems: "center" }} // Ajuste a largura e alinhamento
                            >
                                <Box sx={{ width: "75%", height: "50%" }}>
                                    <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
                                </Box>
                                <Box component="span" sx={{ fontWeight: "bold", mt: 0, textAlign: "center" }}>
                                    {chart.title}
                                </Box>
                                <Box component="span">
                                    {chart.value}%
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: 600,
                            height: 310,
                            borderRadius: 5,
                            boxShadow: "1px 1px 5px black",
                            padding: 5,
                            marginBottom: 2, // Adicione um espaço entre os gráficos
                        }}
                    >
                        <Bar
                            data={ageData}
                            options={options}
                            key={chartKey}
                            style={{ height: "100%", width: "100%" }} // Ajuste para que o Bar preencha o Box
                        />
                    </Box>


                </Box>,
        }
    ];


    return (
        <Box>

            <Stack sx={{ backgroundColor: "white", minHeight: height, minWidth: width, display: "flex", justifyContent: expanded ? "flex-start" : "center", alignItems: expanded ? "flex-start" : "center", borderRadius: 3, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", margin: 2, cursor: "pointer" }}>

                <Stack onClick={() => {
                    if (allowExpand) {
                        if (!expanded) {
                            setWidth(1000); setExpanded(true)
                        } else {
                            setExpanded(false)
                        }

                    }
                }} sx={{ m: expanded ? 2 : 0, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Stack sx={{ backgroundColor: iconBgcolor, borderRadius: "50%", height: 50, width: 50, justifyContent: "center", alignItems: "center" }}>
                        <img height={35} src={icon} />
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}>
                        <Typography sx={{ fontSize: 46, fontWeight: 700 }}>{number}</Typography>
                        <Typography>{title}</Typography>
                    </Stack>
                </Stack>
                {expanded && (
                    <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>




                        <Box sx={{ display: "flex", height: "100%", width: "100%" }}>


                            <div className="tabs-container">
                                {/* Botões das guias */}
                                <div className="tab-buttons">
                                    {tabContent.map((content, index) => (
                                        <button
                                            key={index}
                                            onClick={() => changeTab(index)}
                                            className={index === activeTab ? 'active-tab' : ''}
                                        >
                                            {tabContent[index].title}
                                        </button>
                                    ))}
                                </div>

                                {/* Renderiza o conteúdo da guia ativa */}
                                <div className="tab-content">
                                    {/* Renderiza o conteúdo da guia ativa */}
                                    {tabContent[activeTab].content}
                                </div>
                            </div>


                        </Box>



                    </Box>
                )}
            </Stack>


        </Box>
    );
}