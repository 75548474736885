import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search"
import FilterField from './components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import CarpoolingServices from '../../Services/CarpoolingServices.ts';
import Carpooling from '../../models/CarpoolingModel.ts';




const createDoughnutData = (value, color) => ({
  labels: [value >= 100 ? 'Resolvidos' : ' '], // Se o valor for 100%, label é 'Resolvidos', caso contrário, nenhuma label
  datasets: [
    {
      data: [value, 100 - value], // Valor e o restante até completar 100%
      backgroundColor: [color, 'rgba(0, 0, 0, 0.1)'], // Cor do valor e cor para o restante
      borderWidth: 0, // Sem bordas
    },
  ],
});

const options2 = {
  responsive: true,
  cutout: '70%', // Aumenta o espaço do centro para mais destaque
  plugins: {
    legend: {
      display: false, // Esconde a legenda do gráfico
    },
    tooltip: {
      enabled: false, // Desativa os tooltips
    },
  },
  maintainAspectRatio: false, // Permite que o gráfico de rosca seja redimensionado verticalmente
};



const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 20, // Arredondar os cantos
    position: 'relative',
    backgroundColor: '#383C40',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white', // Cor do texto
    padding: '5px 15px 5px 6px', // Espaçamento interno do Select
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Sobrescrever as bordas padrões
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    // Estilos para o ícone do dropdown

  },
}));



interface Filter {
  field: string;
  value: string | boolean;
}



export default function AllCarpooling({ generalData }) {

  const [filters, setFilters] = useState<Filter[]>([]);
  const [carpoolings, setCarpoolings] = useState<Carpooling[]>([]);
  const [didFetch, setDidFetch] = useState(false);


  const carpoolingServices = new CarpoolingServices();


  function calculateAge(birthdate) {

    const birthDateParts = birthdate.split("/");
    const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
    
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }


  



  const fetchCarpoolings = async () => {

    try {

      const getCarpoolings = await carpoolingServices.GetAllCarpooling(100);
      setCarpoolings(getCarpoolings);

    } catch (error) {
      console.error('Failed to fetch users:', error);
      // Optionally handle the error, e.g., by setting an error state or an empty array
      setCarpoolings([]);
    }

  }



  if (!didFetch) {
    fetchCarpoolings();
    setDidFetch(true);
  }



  return (
    <Container>

      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
        {/* <Search /> */}
        <div className={styles.user}>
          <img src="./assets/icon.svg" alt="" />
          <p>{generalData.points} Pontos</p>
          <img src="./assets/notification.svg" alt="" />
          <div className={styles.userPhoto}>
            <img src="./assets/photo.svg" alt="" />
          </div>
        </div>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 5, marginLeft: 2 }}>
        <Typography sx={{ fontWeight: 600, fontSize: 32 }}>
          Relatório de cadastros
        </Typography>
        <Box sx={{ display: "none", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: 600, fontSize: 19 }}>
            Cadastros  realizados  no período.
          </Typography>
          <Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-customized-select-label"></InputLabel>

              <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 4px" }}>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={"week"}
                  input={<BootstrapInput
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={CalendarIcon} height="19" alt="Calendar" />
                      </InputAdornment>
                    }
                  />} // Utiliza o novo componente de input
                  IconComponent={ArrowDownIcon} // Ícone personalizado
                  sx={{
                    // Sobrescrever estilos específicos para o ícone
                    '& .MuiSvgIcon-root': {
                      color: 'lime', // Cor do ícone
                    },
                    '&:focus': {
                      backgroundColor: 'black', // Mantém o fundo escuro quando focado
                    },
                  }}
                >
                  <MenuItem value={"week"}>Jan - 2024</MenuItem>
                  <MenuItem value={"mounth"}>Feb - 2024</MenuItem>
                  <MenuItem value={"year"}>Mar - 2024</MenuItem>
                </Select>
              </Stack>
            </FormControl>


            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-customized-select-label"></InputLabel>

              <Stack sx={{ backgroundColor: '#383C40', borderRadius: "10px", padding: "0px 10px 0 4px" }}>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={"week"}
                  input={<BootstrapInput
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={CalendarIcon} height="19" alt="Calendar" />
                      </InputAdornment>
                    }
                  />} // Utiliza o novo componente de input
                  IconComponent={ArrowDownIcon} // Ícone personalizado
                  sx={{
                    // Sobrescrever estilos específicos para o ícone
                    '& .MuiSvgIcon-root': {
                      color: 'lime', // Cor do ícone
                    },
                    '&:focus': {
                      backgroundColor: 'black', // Mantém o fundo escuro quando focado
                    },
                  }}
                >
                  <MenuItem value={"week"}>Jan - 2024</MenuItem>
                  <MenuItem value={"mounth"}>Feb - 2024</MenuItem>
                  <MenuItem value={"year"}>Mar - 2024</MenuItem>
                </Select>
              </Stack>
            </FormControl>

          </Box>
        </Box>
        <Stack></Stack>
      </Box>




      <Box sx={{ mt: 8 }}>
        <Typography sx={{ fontWeight: 500, fontSize: 19 }}>
          Após sua busca foram encontrados esses registros
        </Typography>

        {carpoolings && carpoolings.map((e) => {
          return (
            <Stack sx={{ mt: 2, display: "flex", flexDirection: "row", backgroundColor: "#D9D9D9", p: 2, justifyContent: "space-between" }}>
              <Typography>Id motorista: {e.driverUid}</Typography>
              <Typography>De: {e.fromWhere}</Typography>
              <Typography>Para: {e.toWhere}</Typography>
              <Typography>Telefone: </Typography>
              <img src="./assets/eye.svg" alt="" />
            </Stack>
          )
        })

        }

      </Box>

    </Container>
  );
}