import React from 'react'
import styles from './style.module.css'

const Search = () => {
  return (
    <div className={styles.searchContainer}>
      <input className={styles.search} type="text" placeholder='Faça sua busca rápida' name="" id="" />
      <img className={styles.searchIcon} src="./assets/search.svg" alt="" />
    </div>
  )
}

export default Search