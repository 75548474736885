import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography, FormControl, InputLabel, styled, MenuItem, InputBase, Collapse, Button } from "@mui/material";
import Select from '@mui/material/Select';
import Delete from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import AdminServices from '../../../Services/AdminServices.ts';


const adminServices = new AdminServices();


export default function ComunicateListItem({ comunicateInfo, changeCurrentAnnouncement }) {
    const [expanded, setExpanded] = useState(false)
    const [faqExpanded, setFaqExpanded] = useState([]);

    useEffect(() => {


        // Verifica se comunicateInfo.faqs não está vazio
        if (comunicateInfo.faq && comunicateInfo.faq.length > 0) {
            // Inicializa o estado com base no comprimento de comunicateInfo.faqs
            setFaqExpanded(Array(comunicateInfo.faq.length).fill(false));
        } else {
            // Caso comunicateInfo.faqs esteja vazio, inicializa o estado com uma array vazia
            setFaqExpanded([]);
        }
    }, [comunicateInfo.faq]);


    const deleteComunicate = async()=>{
        try{
            const res = await adminServices.deleteComunicate(comunicateInfo.id);

            if(res.status){
                alert("Comunicado deletado com sucesso")
                window.location.reload();
            }else{
                alert("não foi possível deletar!");
            }
        }catch(e){
            alert("não foi possível deletar!");
        }
        
    }



    return (
        <Box sx={{mt:2, height: expanded ? "auto" : 80, width: 370, backgroundColor: "white", borderRadius: 3 }}>
            <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", height: 80, }}>
                <Stack onClick={() => setExpanded(!expanded)} sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", }}>
                    <div style={{ marginLeft: 1, height: 60, width: 60, backgroundImage: comunicateInfo.iconPhoto ? `url('${comunicateInfo.iconPhoto}')` : "none", backgroundSize: "cover", borderRadius: 50 }}>
                        
                    </div>
                    <Stack sx={{ ml: 1, height: 60 }}>
                        <Typography>
                            Comunicado
                        </Typography>
                        <Typography>
                            {comunicateInfo.title}
                        </Typography>
                    </Stack>
                    <Stack sx={{ ml: 1, height: 60 }}>
                        <Typography>
                            Data
                        </Typography>
                        <Typography>
                            {comunicateInfo.createdAt.toLocaleDateString()}
                        </Typography>
                    </Stack>
                </Stack>
                <Delete onClick={()=>{deleteComunicate()}} sx={{ mr: 3 }} />
                <Edit onClick={()=>changeCurrentAnnouncement(comunicateInfo)} sx={{mr:1}} />
            </Stack>


            <Box sx={{ m: 2 }}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Descrição:
                        </Typography>
                        <Typography sx={{ wordBreak: "break-all" }}>
                            {comunicateInfo.announcement}
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Contato:
                        </Typography>
                        <Typography sx={{ wordBreak: "break-all" }}>
                            {comunicateInfo.contact}
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Endereço:
                        </Typography>
                        <Typography sx={{ wordBreak: "break-all" }}>
                            {comunicateInfo.location}
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            faq:
                        </Typography>
                        {
                            comunicateInfo.faq &&
                            comunicateInfo.faq.map((e, index) => {
                                return (
                                    <Stack onClick={() => setFaqExpanded(prev => {
                                        const data = [...prev];
                                        data[index] = !data[index];
                                        return data;
                                    })}>
                                        <Typography sx={{ fontWeight: 600, ":hover": { color: "blue", cursor: "pointer" } }}>
                                            {e.title}
                                        </Typography>
                                        <Collapse in={faqExpanded[index]} timeout="auto" unmountOnExit>
                                            {e.description}
                                        </Collapse>
                                    </Stack>
                                );
                            })
                        }
                    </Stack>
                </Collapse>
            </Box>
        </Box>
    );
}
