import * as React from 'react';
import { Box, Container, Stack, Typography, FormControl, InputLabel, styled, MenuItem, InputBase } from "@mui/material";
import Select from '@mui/material/Select';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4, // Cantos mais quadrados
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da', // Borda cinza (ajuste conforme necessário)
        fontSize: 16, // Tamanho da fonte um pouco maior
        padding: '10px 26px 10px 12px', // Ajuste de padding para maior altura
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Ajuste para que o ícone (setinha) esteja alinhado corretamente verticalmente e tenha espaço adequado
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export default function FilterField({ title, value, options, handleChange, disabled }) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
            <Typography sx={{ fontSize: 12}}>{title}</Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel shrink htmlFor="bootstrap-input"></InputLabel>
                <Select
                    disabled={disabled}
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={value}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                    displayEmpty
                    renderValue={
                        value !== '' ? undefined : () => <Typography color="text.secondary">Choose...</Typography>
                    }
                    sx={{
                        '& .MuiSvgIcon-root': {
                            color: 'black', // Cor do ícone preta
                        },
                    }}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option === "null" ? "null" : option}>
                            {option === "null" ? "Selecione" : option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
