import React, { useEffect, useState } from 'react'
import { Box, Container, Stack, Typography } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import FilterField from '../../../screens/registersScreen/components/filterField.tsx';
import { Pie } from 'react-chartjs-2';
import './style.css';
import AdminServices from '../../../Services/AdminServices.ts';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const adminServices = new AdminServices();


function calculateAge(birthdate) {

  const birthDateParts = birthdate.split("/");
  const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

function generateRandomRGBA(opacity) {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

// Gerar uma lista de cores aleatórias
const randomColors = Array.from({ length: 12 }, () => generateRandomRGBA(0.2));



export default function Card({ icon, number, title, iconBgcolor, categories, allowExpand }) {


  const [expanded, setExpanded] = useState(false)
  const [height, setHeight] = useState(125)
  const [width, setWidth] = useState(218);
  const [filter, setFilter] = useState();
  const [currentCategory, setCurrentCategory] = useState("Todas");
  const [activeTab, setActiveTab] = useState(0);
  const [chartKey, setChartKey] = useState(Date.now());


  const [genderAgeData, setGenderAgeData] = useState({
    labels: ['M até 25 anos', 'H até 25 anos', 'M acima de 25 anos', 'H acima de 25 anos', "Outros"], // Rótulos das barras
    datasets: [{
      label: 'Gênero/Idade',
      data: [12, 12, 12, 12, 12],
      backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
    },

    ]
  })

  const [genderData, setGenderData] = useState({
    labels: ['Por mulheres', 'Por homens', 'Outros'], // Rótulos das barras
    datasets: [{
      label: 'Gênero',
      data: [10, 20, 30], // Dados para cada barra
      backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(75, 192, 192, 0.5)'] // Cores das barras
    },

    ]
  })

  const [secretariats, setSecretariats] = useState();


  const [categoriesChartData, setCategoriesChartData] = useState({
    labels: [""],
    datasets: [
      {
        label: 'My First Dataset',
        data: [0],
        backgroundColor: randomColors,
        borderColor: randomColors.map(color => color.replace('0.2', '1')),
        borderWidth: 1,
      },
    ],
  })


  const [generalChartData, setGeneralChartData] = useState({
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    datasets: [
      {
        label: 'Resolvidos',
        data: [0], // Substitua estes dados pelos seus dados reais
        fill: false,
        backgroundColor: '#2D9CDB',
        borderColor: '#2D9CDB',
        borderWidth: 1,
        tension: 0.4 // Isso torna a linha um pouco curvada
      },
      {
        label: 'Em andamento',
        data: [0], // Substitua estes dados pelos seus dados reais
        fill: false,
        backgroundColor: '#45EDDE',
        borderColor: '#45EDDE',
        borderWidth: 1,
        tension: 0.4 // Isso torna a linha um pouco curvada
      },
      {
        label: 'Aguardando',
        data: [], // Substitua estes dados pelos seus dados reais
        fill: false,
        backgroundColor: '#FF5B5B',
        borderColor: '#FF5B5B',
        borderWidth: 1,
        tension: 0.4 // Isso torna a linha um pouco curvada
      },

    ],
  })


  const [chartsInfo, setChartsInfo] = useState([
    { title: 'Não resolvidos', value: 81, color: 'rgba(255, 99, 132, 0.5)' },
    { title: 'Usuários', value: 22, color: 'rgba(75, 192, 192, 0.5)' },
    { title: 'Gestão', value: 62, color: 'rgba(54, 162, 235, 0.5)' },
    { title: 'Em aberto', value: 62, color: 'rgba(54, 162, 235, 0.5)' },
    { title: 'Em andamento', value: 62, color: 'rgba(54, 162, 235, 0.5)' },
  ]);

  // Conteúdo para cada guia


  useEffect(() => {
    fetchGenderAndAgeChartData();
    fetchGeneralChartData();
    fetchOnGoingChartData();
  }, [currentCategory])

  useEffect(() => {
    if (categories) {
      setCategoriesChartData({
        labels: categories,
        datasets: [
          {
            label: 'Num. de problemas',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: randomColors,
            borderColor: randomColors.map(color => color.replace('0.2', '1')),
            borderWidth: 1,
          },
        ],
      })
      fetchSecretariatsAndAgeChartData();
    }
  }, [categories]);



  const fetchGenderAndAgeChartData = async () => {
    try {
      // Obter dados de idade e gênero
      const genderAndAge = await adminServices.getAgeGenderData(100, currentCategory);


      // Atualizar o estado com os novos dados
      setGenderAgeData((prevData) => {
        // Criar uma cópia dos dados anteriores
        const data = { ...prevData };

        // Atualizar os valores dos datasets com os novos dados
        data.datasets[0].data = [
          genderAndAge?.womenLessThan25 ?? 0,
          genderAndAge?.menLessThan25 ?? 0,
          genderAndAge?.womenOlderThan25 ?? 0,
          genderAndAge?.menOlderThan25 ?? 0,
          genderAndAge?.others ?? 0
        ];



        // Retornar os novos dados
        return data;
      });

      setChartKey(Date.now());


      setGenderData((prevData) => {
        const data = { ...prevData };

        // Atualizar os valores dos datasets com os novos dados
        data.datasets[0].data = [
          genderAndAge?.women ?? 0,
          genderAndAge?.men ?? 0,
          genderAndAge?.others ?? 0,
        ];



        // Retornar os novos dados
        return data;
      });


    } catch (error) {
      return
    }
  };


  const fetchSecretariatsAndAgeChartData = async () => {
    try {
      // Obter dados de idade e gênero
      const secretariatsData = await adminServices.getSecretariatsData(100, categories);

      setSecretariats(secretariatsData);

      setCategoriesChartData((prevData) => {
        const data = prevData;

        data.datasets[0].data = categories.map(e => {
          return secretariatsData[e]
        })

        return data

      })

    } catch (error) {
      return
    }
  };

  const fetchGeneralChartData = async () => {
    try {
      // Obter dados gerais de status
      const generalStatusData = await adminServices.getGeneralDataToChart(100, currentCategory);


      setGeneralChartData((prevData) => {
        // Certifique-se de não mutar diretamente o prevData
        const data = { ...prevData };

        // Mapeia os dados para cada dataset
        data.datasets[0].data = generalStatusData?.map(month => month.finalizado) ?? [];
        data.datasets[1].data = generalStatusData?.map(month => month.andamento) ?? [];
        data.datasets[2].data = generalStatusData?.map(month => month.aberto) ?? [];

        return data;
      });

      setChartKey(Date.now());

    } catch (error) {
      console.error("Erro ao buscar dados gerais:", error);
    }
  }

  const fetchOnGoingChartData = async () => {
    try {
      // Obter dados de relatórios em andamento
      const onGoingData = await adminServices.getOnGoingDataToChart(100, currentCategory);
  
      // Supondo que onGoingData.num seja o número total de problemas
      const totalProblems = onGoingData.num; 
  
      // Estrutura os novos dados para o gráfico baseado nos dados recebidos
      const newChartsInfo = [
        { title: 'Não resolvidos', value: parseInt((onGoingData.notSolved / totalProblems) * 100), color: 'rgba(255, 99, 132, 0.5)' },
        { title: 'Resolvidos por Usuários', value: parseInt((onGoingData.solvedByUser / totalProblems) * 100), color: 'rgba(75, 192, 192, 0.5)' },
        { title: 'Resolvidos pela Gestão', value: parseInt((onGoingData.solvedByAdmin / totalProblems) * 100), color: 'rgba(54, 162, 235, 0.5)' },
        { title: 'Em aberto', value: parseInt((onGoingData.open / totalProblems) * 100), color: 'rgba(255, 206, 86, 0.5)' },
        { title: 'Em andamento', value: parseInt((onGoingData.onGoing / totalProblems) * 100), color: 'rgba(153, 102, 255, 0.5)' },
      ];
  
      // Atualiza o estado com os novos valores para o gráfico
      setChartsInfo(newChartsInfo);
  
    } catch (error) {
      console.error("Erro ao buscar dados dos relatórios em andamento:", error);
    }
  }
  




  const changeTab = (index) => {
    setActiveTab(index); // Atualiza o índice da guia ativa
  };

  const colors = ["#E53761", "#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735", "#E53761", "#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735", "#E53761", "#27A468", "#F2A735", "#64E537", "#F4159B", "#3788E5", "#E53761", "#27A468", "#F2A735"];




  const optionsLine = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true // Isso irá esconder as linhas de grade verticais
        }
      },
      y: {
        ticks: {
          stepSize: 10
        },
        grid: {
          display: false // Isso irá esconder as linhas de grade horizontais
        }
      }
    },
    plugins: {
      legend: false,
      title: false,
    },
  };


  const optionsGenderAge = {
    indexAxis: 'y', // Configura as barras para serem horizontais
    scales: {
      x: {
        beginAtZero: true // Inicia o eixo X do zero
      }
    },
    plugins: {
      legend: {
        position: 'right' // Posiciona a legenda à direita
      }
    }
  };





  const options = {
    indexAxis: 'y', // Configura as barras para serem horizontais
    scales: {
      x: {
        beginAtZero: true // Inicia o eixo X do zero
      }
    },
    plugins: {
      legend: {
        position: 'right' // Posiciona a legenda à direita
      }
    }
  };



  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
  };



  const createDoughnutData = (value, color) => ({
    labels: [value >= 100 ? 'Resolvidos' : ' '], // Se o valor for 100%, label é 'Resolvidos', caso contrário, nenhuma label
    datasets: [
      {
        data: [value, 100 - value], // Valor e o restante até completar 100%
        backgroundColor: [color, 'rgba(0, 0, 0, 0.1)'], // Cor do valor e cor para o restante
        borderWidth: 0, // Sem bordas
      },
    ],
  });

  const optionsDoughnutData = {
    responsive: true,
    cutout: '70%', // Aumenta o espaço do centro para mais destaque
    plugins: {
      legend: {
        display: false, // Esconde a legenda do gráfico
      },
      tooltip: {
        enabled: false, // Desativa os tooltips
      },
    },
    maintainAspectRatio: false, // Permite que o gráfico de rosca seja redimensionado verticalmente
  };





  const tabContent = [
    {
      title: "Por gênero",
      content: <Box>

        <FilterField
          title={""}
          value={currentCategory}
          options={["Todas", ...(categories || [])]}
          handleChange={(h) => setCurrentCategory(h.target.value)}
          disabled={false}
        />

        <Box sx={{ width: 600, height: 310 }}>
          <Bar
            data={genderData}
            options={options}
            key={chartKey}
            style={{ height: '100%', width: '100%' }} // Ajuste para que o Bar preencha o Box
          />

        </Box>

        <Box sx={{ width: 600, height: 310 }}>
          <Bar
            data={genderAgeData}
            options={optionsGenderAge}
            key={chartKey}
            style={{ height: '100%', width: '100%' }} // Ajuste para que o Bar preencha o Box
          />

        </Box>

      </Box>,
    },
    {
      title: "Secretaria",
      content:
        <Box>

          <Box sx={{ width: 500, height: 400 }}>
            <Pie data={categoriesChartData} options={options2} style={{ height: '100%', width: '100%' }} />
          </Box>

          <Box sx={{ mt: 2 }}>
            {categories &&
              categories.map((e) => {
                return (
                  <Box sx={{ mt: 0 }}>
                    <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Stack sx={{ height: 5, width: 5, backgroundColor: "red", mr: 1, borderRadius: 50 }}></Stack>
                      <Typography>{e}: {secretariats != null ? secretariats[e] : ""}</Typography>
                    </Stack>
                  </Box>
                )
              })
            }
          </Box>

        </Box>,
    },
    {
      title: "Geral",
      content:
        <Box sx={{ height: 400, width: 700, padding: 2, backgroundColor: "white", borderRadius: "20px", marginTop: 2, marginRight: 2 }}>

          <FilterField
            title={""}
            value={currentCategory}
            options={["Todas", ...(categories || [])]}
            handleChange={(h) => setCurrentCategory(h.target.value)}
            disabled={false}
          />

          <Stack sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: 700, fontSize: 24 }}>Problemas Relatados</Typography>

            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: 2 }}>
                <Stack sx={{ height: 10, width: 10, borderRadius: "50%", backgroundColor: "#2D9CDB", mr: 1 }}></Stack>
                <Typography>Resolvidos</Typography>
              </Stack>
              <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: 2 }}>
                <Stack sx={{ height: 10, width: 10, borderRadius: "50%", backgroundColor: "#45EDDE", mr: 1 }}></Stack>
                <Typography>Em andamento</Typography>
              </Stack>
              <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center", m: 2 }}>
                <Stack sx={{ height: 10, width: 10, borderRadius: "50%", backgroundColor: "#FF5B5B", mr: 1 }}></Stack>
                <Typography>Aguardando</Typography>
              </Stack>
            </Box>


          </Stack>

          <Line data={generalChartData} key={chartKey} options={optionsLine} />
        </Box>
    },
    {
      title: "Andamentos",
      content:

        <Box>
          <FilterField
            title={""}
            value={currentCategory}
            options={["Todas", ...(categories || [])]}
            handleChange={(h) => setCurrentCategory(h.target.value)}
            disabled={false}
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 1,
            flexWrap: "wrap",
            alignItems: "center",
            backgroundColor: 'white',
            borderRadius: '20px',
            marginTop: 2,
            height: 170,
            width: '100%',
          }}>

            {chartsInfo.map((chart, index) => (
              <Box key={index} sx={{ width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '75%', height: '50%' }}>
                  <Doughnut data={createDoughnutData(chart.value, chart.color)} options={optionsDoughnutData} />
                </Box>
                <Box component="span" sx={{ fontWeight: 'bold', mt: 0 }}>
                  {chart.title}
                </Box>
                <Box component="span">
                  {chart.value}%
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
    }
  ];


  return (
    <Box>

      <Stack onClick={() => {
        if(allowExpand){
          setHeight(1000); setWidth(1000); setExpanded(true) 
      } }} sx={{ backgroundColor: "white", height: height, width: width, display: "flex", justifyContent: expanded ? "flex-start" : "center", alignItems: expanded ? "flex-start" : "center", borderRadius: 3, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", margin: 2, cursor: "pointer" }}>

        <Stack sx={{ m: expanded ? 2 : 0, display: "flex", flexDirection: "row", alignItems:"center" }}>
          <Stack sx={{ backgroundColor: iconBgcolor, borderRadius: "50%", height: 50, width: 50, justifyContent: "center", alignItems: "center" }}>
            <img height={35} src={icon} />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "column", marginLeft: 2 }}>
            <Typography sx={{ fontSize: 46, fontWeight: 700 }}>{number}</Typography>
            <Typography>{title}</Typography>
          </Stack>
        </Stack>
        {expanded && (
          <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>




            <Box sx={{ display: "flex", height: "100%", width: "100%" }}>


              <div className="tabs-container">
                {/* Botões das guias */}
                <div className="tab-buttons">
                  {tabContent.map((content, index) => (
                    <button
                      key={index}
                      onClick={() => changeTab(index)}
                      className={index === activeTab ? 'active-tab' : ''}
                    >
                      {tabContent[index].title}
                    </button>
                  ))}
                </div>

                {/* Renderiza o conteúdo da guia ativa */}
                <div className="tab-content">
                  {tabContent[activeTab].content}
                </div>
              </div>


            </Box>



          </Box>
        )}
      </Stack>


    </Box>
  );
}