import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';
import FilterField from '../registersScreen/components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminServices from '../../Services/AdminServices.ts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

const adminService = new AdminServices();


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        backgroundColor: '#383C40',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white', // Cor do texto
        padding: '5px 15px 5px 6px', // Espaçamento interno do Select
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Sobrescrever as bordas padrões
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

const SelectFieldsStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        fontSize: 14,
        fontWeight: 'normal',
        color: 'black', // Cor do texto
        padding: '5px 15px 5px 6px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

interface Filter {
    field: string;
    value: string | boolean;
}

interface User {
    id: string;
    name: string;
    phone: string;
    gender: string;
    birthday: string;
    // Add other user properties as needed
}




export default function RegisterNotice({ generalData }) {


    const [age, setAge] = useState("null");
    const [expanded, setExpanded] = useState(false);
    const [faq, setFaq] = useState([]);

    const [filters, setFilters] = useState<Filter[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [didFetch, setDidFetch] = useState(false);
    const [numbers, setNumbers] = useState(Array);
    const [isLoading, setIsLoading] = useState(false);
    const [bannerFileName, setBannerFileName] = useState("");


    const handleBannerFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBannerFileName(file.name.slice(0, 20) + '...');
        }
    };

    

    const userServices = new UserServices();


    function calculateAge(birthdate) {

        const birthDateParts = birthdate.split("/");
        const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
        
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }


    




    const CreateNotice = async () => {

        setIsLoading(true);
        const cep = document.getElementById("cep").value;
        const road = document.getElementById("road").value;
        const number = document.getElementById("number").value;
        //const complement = document.getElementById("complement").value,
        const neighborhood = document.getElementById("neighborhood").value;
        const city = document.getElementById("city").value;

        const location = `${road}, ${neighborhood}, ${city}, ${number},- ${cep}`;

        const contact = document.getElementById("contact").value;
        const workTime = document.getElementById("workTime").value;
        const iconFileInput = document.getElementById("iconFile");
        const bannerFileInput = document.getElementById("bannerFile");

        if (bannerFileInput && bannerFileInput.files.length > 0) {
            const bannerFile = bannerFileInput.files[0]


            const ServiceBody = {
                "title": document.getElementById("title").value,
                "content": document.getElementById("msg").value,
                "location": location,
                "contact": contact,
                "workTime": workTime,
                "faq": faq
            };

            const arrayUids = users.map(e => e.id);
            

            try {
                const res = await adminService.createNotice(ServiceBody, bannerFile)
                if (res.status) {
                    alert("Notícia criada")
                    setIsLoading(false);
                } else {
                    alert("não foi possível criar notícia")
                    setIsLoading(false);
                }

            } catch (e) {
                alert("não foi possível criar notícia")
                setIsLoading(false);
            }

        } else {
            alert("selecione alguma imagem!");
        }





    }




    return (
        <Container>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
                {/* <Search /> */}
                <div className={styles.user}>
                    <img src="./assets/icon.svg" alt="" />
                    <p>{generalData.points} Pontos</p>
                    <img src="./assets/notification.svg" alt="" />
                    <div className={styles.userPhoto}>
                        <img src="./assets/photo.svg" alt="" />
                    </div>
                </div>
            </Box>

            <Box sx={{ mt: 10 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                    Cadastrar notícia
                </Typography>




                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", mt: 10 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            borderRadius: 3,
                            width: 700,
                            mb: 3
                        }}
                    >
                        <Stack sx={{ m: 0 }}>
                            <Typography>Título</Typography>
                            <input id="title" className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='título do serviço' />
                        </Stack>
                        <TextareaAutosize
                            id="msg"
                            minRows={3}
                            placeholder="Digite aqui o conteúdo"
                            style={{
                                width: '80%',
                                border: 'none',
                                height: "70px",
                                padding: '12px',
                                borderRadius: '0 12px 12px 12px',
                                boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                                resize: 'none',
                                marginBottom: '16px',
                                outline: "none"
                            }}
                        />


                        <Button onClick={() => setFaq((prev) => {
                            return [...prev, {
                                titulo: "",
                                description: ""
                            }]
                        })}>Adicionar FAQ</Button>


                    </Box>






                    <Box sx={{ ml: 5, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                        <Stack sx={{ m: 1 }}>
                            <Typography>CEP</Typography>
                            <input id="cep" className={styles.sendMsgInput} type="text" />
                        </Stack>
                        <Stack sx={{ m: 1 }}>
                            <Typography>Rua/Avenida</Typography>
                            <input id="road" className={styles.sendMsgInput} type="text" />
                        </Stack>
                        <Stack sx={{ m: 1 }}>
                            <Typography>Número</Typography>
                            <input id="number" className={styles.sendMsgInput} type="text" />
                        </Stack>
                        <Stack sx={{ m: 1 }}>
                            <Typography>Complemento</Typography>
                            <input id="complement" className={styles.sendMsgInput} type="text" />
                        </Stack>
                        <Stack sx={{ m: 1 }}>
                            <Typography>Bairro</Typography>
                            <input id="neighborhood" className={styles.sendMsgInput} type="text" />
                        </Stack>
                        <Stack sx={{ m: 1 }}>
                            <Typography>Cidade</Typography>
                            <input id="city" className={styles.sendMsgInput} type="text" />
                        </Stack>

                        <Stack sx={{ ml: 1 }}>
                            <Typography>Data e hora do funcionamento</Typography>
                            <input id="workTime" className={styles.sendMsgInput} type="text" placeholder='inserir' />
                        </Stack>

                    </Box>


                </Box>


                {faq.length > 0 &&
                    <Box sx={{ mt: 5 }}>
                        <Typography>
                            Dúvidas frequentes
                        </Typography>
                        {faq.map((e, index) => {
                            return (
                                <Box>
                                    <Stack sx={{ mt: 4, width: 300 }}>
                                        <input
                                            value={faq[index].title}
                                            onChange={(handle) => {
                                                setFaq((prev) => {

                                                    const prevData = [...prev];

                                                    prevData[index].title = handle.target.value;

                                                    return prevData;
                                                })
                                            }}
                                            className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='Título da pergunta' />
                                    </Stack>
                                    <TextareaAutosize
                                        value={faq[index].description}
                                        onChange={(handle) => {
                                            setFaq((prev) => {

                                                const prevData = [...prev];

                                                prevData[index].description = handle.target.value;

                                                return prevData;
                                            })
                                        }}
                                        minRows={3}
                                        placeholder="Digite aqui a resposta da pergunta"
                                        style={{
                                            width: '92%',
                                            border: 'none',
                                            height: "70px",
                                            padding: '12px',
                                            borderRadius: '0px 12px 12px 12px',
                                            boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, 0.1)',
                                            resize: 'none',
                                            marginBottom: '16px',
                                            outline: "none"
                                        }}
                                    />
                                </Box>
                            )
                        })}


                    </Box>

                }
                <Stack sx={{ mb: 5, width: 300 }}>
                    <input id="contact" className={styles.sendMsgInput} type="text" placeholder='Contato' />
                </Stack>

                <Stack onClick={() => document.getElementById("bannerFile")?.click()} sx={{ mb: 5, width: 200, backgroundColor: "#D3F26A", height: 50, borderRadius: 2, display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row" }}>
                    <Typography sx={{ mr: 1 }}>{bannerFileName !== "" ? bannerFileName : "Adicionar Banner"}</Typography>
                    <CloudUploadIcon sx={{ mr: 2 }} />
                    <input onChange={handleBannerFileChange} id="bannerFile" style={{ display: "none" }} type="file" />
                </Stack>

                {isLoading && <CircularProgress />}
                {!isLoading &&
                    <Stack direction="column" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>

                        <Button
                            variant="contained"
                            onClick={() => {
                                CreateNotice();
                            }}
                            sx={{
                                bgcolor: 'yellowgreen',
                                ':hover': { bgcolor: 'darkolivegreen' },
                                borderRadius: 20,
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                fontWeight: 'regular',
                                paddingX: 4
                            }}
                        >
                            Criar notícia
                        </Button>
                    </Stack>
                }

            </Box>

        </Container>
    );
}