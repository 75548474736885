import { Box, Stack } from "@mui/material";
import React from "react";


export default function SpecificReports(){
    return (
        <Box sx={{width: "100vw"}}>
            <iframe width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/e496e3f9-ad84-444a-b0b4-53dadc3f2615/page/2MezD" frameborder="0" style={{"border": 0}} allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
            <Stack sx={{display: "flex", flexDirection: "row"}}>
                atualizar url:
                <input type="text" style={{outline: "none", width: 300}} />
                <button>Atualizar</button>
            </Stack>
        </Box>
    )
}