import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';
import FilterField from '../registersScreen/components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminServices from '../../Services/AdminServices.ts';
import CircularProgress from '@mui/material/CircularProgress';


const adminService = new AdminServices();


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        backgroundColor: '#383C40',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white', // Cor do texto
        padding: '6px 15px 6px 6px', // Espaçamento interno do Select
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Sobrescrever as bordas padrões
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

const SelectFieldsStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        fontSize: 14,
        fontWeight: 'normal',
        color: 'black', // Cor do texto
        padding: '5px 15px 5px 6px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

interface Filter {
    field: string;
    value: string | boolean;
}

interface User {
    id: string;
    name: string;
    phone: string;
    gender: string;
    birthday: string;
    // Add other user properties as needed
}




export default function DirectMessage({ generalData }) {


    const [age, setAge] = useState("null");
    const [expanded, setExpanded] = useState(false);
    const [gender, setGender] = useState("null");
    const [maritalStatus, setMaritalStatus] = useState("null");
    const [children, setChildren] = useState("null");
    const [familyIncome, setFamilyIncome] = useState("null");
    const [govProgram, setGovProgram] = useState("null");
    const [cadUnico, setCadUnico] = useState("null");
    const [healthProfessional, setHealthProfessional] = useState("null");
    const [preIllnesses, setPreIllnesses] = useState("null");
    const [deficiency, setDeficiency] = useState("null");
    const [employmentStatus, setEmploymentStatus] = useState("null");
    const [msgType, setMsgType] = useState("sms");
    const [filters, setFilters] = useState<Filter[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [didFetch, setDidFetch] = useState(false);
    const [numbers, setNumbers] = useState(Array);
    const [isLoading, setIsLoading] = useState(false);
    const [msgBody, setMsgBody] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [race, setRace] = useState("null");
    const [education, setEducation] = useState("null");

    

    const userServices = new UserServices();


    function calculateAge(birthdate) {

        const birthDateParts = birthdate.split("/");
        const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
        
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }


    

    const filterByAge = () => {
        if (age === "10 a 15 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 10 && age <= 15;
                });
                return newData;
            });
        } else if (age === "16 a 20 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 16 && age <= 20;
                });
                return newData;
            });
        } else if (age === "21 a 30 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 21 && age <= 30;
                });
                return newData;
            });
        } else if (age === "31 a 40 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 31 && age <= 40;
                });
                return newData;
            });
        } else if (age === "41 a 50 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 41 && age <= 50;
                });
                return newData;
            });
        } else if (age === "51 a 60 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 51 && age <= 60;
                });
                return newData;
            });
        } else if (age === "61 a 70 anos") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age >= 61 && age <= 70;
                });
                return newData;
            });
        } else if (age === "acima de 70") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const age = calculateAge(e.birthday);
                    return age > 70;
                });
                return newData;
            });
        }
    };

    const filterByIncome = () => {

        if (familyIncome === "até 1 salário") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const income = parseInt(e.income);
                    return income <= 1412;
                });
                return newData;
            });
        } else if (familyIncome === "1 a 2 salários") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const income = parseInt(e.income);
                    return income >= 1412 && income <= (1412 * 2);
                });
                return newData;
            });
        } else if (familyIncome === "3 a 4 salários") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const income = parseInt(e.income);
                    return income >= (1412 * 3) && income <= (1412 * 4);
                });
                return newData;
            });
        } else if (familyIncome === "acima de 5 salários") {
            setUsers((prevUsers) => {
                const data = [...prevUsers];
                const newData = data.filter((e) => {
                    const income = parseInt(e.income);
                    return income >= (1412 * 5);
                });
                return newData;
            });
        }



    };


    const handleFamilyIncome = (event: SelectChangeEvent)=>{
        setFamilyIncome(event.target.value)
      }

    const handleMsgBody = (handle) => {
        setMsgBody(handle.target.value);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value;
        setAge(event.target.value);

    };
    const handleAge = (event: SelectChangeEvent) => {
        const value = event.target.value;
        setAge(event.target.value);

    };

    const handleEducation = (event: SelectChangeEvent) => {
        const newEducation = event.target.value as string;
        setEducation(newEducation);
    
        // Atualize o array de filtros
        if (newEducation !== "null") {
          setFilters((currentFilters) => [
            ...currentFilters.filter((filter) => filter.field !== "education"),
            { field: "education", value: newEducation }
          ]);
        } else {
          // Remove o filtro de gender se o valor for "null"
          setFilters((currentFilters) =>
            currentFilters.filter((filter) => filter.field !== "education")
          );
        }
      }

    const handleRace = (event: SelectChangeEvent) => {
        const newRace = event.target.value as string;
        setRace(newRace);
    
        // Atualize o array de filtros
        if (newRace !== "null") {
          setFilters((currentFilters) => [
            ...currentFilters.filter((filter) => filter.field !== "race"),
            { field: "race", value: newRace }
          ]);
        } else {
          // Remove o filtro de gender se o valor for "null"
          setFilters((currentFilters) =>
            currentFilters.filter((filter) => filter.field !== "race")
          );
        }
      }

    const handleGender = (event: SelectChangeEvent) => {
        const newGender = event.target.value as string;
        setGender(newGender);

        // Atualize o array de filtros
        if (newGender !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "gender"),
                { field: "gender", value: newGender }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "gender")
            );
        }
    }

    const handleChildren = (event: SelectChangeEvent) => {
        const newChildren = event.target.value as string;
        setChildren(newChildren);

        if (newChildren !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "children"),
                { field: "children", value: newChildren }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "children")
            );
        }

    }

    // const handleAge = (event: SelectChangeEvent) => {
    //     const newAge = event.target.value as string;
    //     setAge(newAge);

    //     if (newAge !== "null") {
    //         setFilters((currentFilters) => [
    //             ...currentFilters.filter((filter) => filter.field !== "age"),
    //             { field: "age", value: newAge }
    //         ]);
    //     } else {
    //         // Remove o filtro de gender se o valor for "null"
    //         setFilters((currentFilters) =>
    //             currentFilters.filter((filter) => filter.field !== "age")
    //         );
    //     }

    // }

    const handleGovProgram = (event: SelectChangeEvent) => {
        const newGovProgram = event.target.value as string;
        setAge(newGovProgram);

        if (newGovProgram !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "govProgram"),
                { field: "govProgram", value: newGovProgram }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "govProgram")
            );
        }



    }

    const handleCadUnico = (event: SelectChangeEvent) => {
        const newCadUnico = event.target.value as string;
        setCadUnico(newCadUnico);


        if (newCadUnico !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "cadunico"),
                { field: "cadunico", value: newCadUnico === "true" }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "cadunico")
            );
        }

    }

    const handleHealthProfessional = (event: SelectChangeEvent) => {
        const newHealthProfessional = event.target.value as string;
        setHealthProfessional(newHealthProfessional);

        if (newHealthProfessional !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "healthProfessional"),
                { field: "healthProfessional", value: newHealthProfessional }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "healthProfessional")
            );
        }


    }

    const handlePreIllnesses = (event: SelectChangeEvent) => {
        const newPreIllnesses = event.target.value as string;
        setPreIllnesses(newPreIllnesses);

        if (newPreIllnesses !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "preIllnesses"),
                { field: "preIllnesses", value: newPreIllnesses }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "preIllnesses")
            );
        }



    }

    const handleDeficiency = (event: SelectChangeEvent) => {
        const Deficiency = event.target.value as string;
        setDeficiency(Deficiency);

        if (Deficiency !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "deficiency"),
                { field: "deficiency", value: Deficiency }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "deficiency")
            );
        }

    }


    const handleMaritalStatus = (event: SelectChangeEvent) => {
        const newMaritalStatus = event.target.value as string;
        setMaritalStatus(newMaritalStatus)

        if (newMaritalStatus !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "maritalStatus"),
                { field: "maritalStatus", value: newMaritalStatus }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "maritalStatus")
            );
        }
    }

    const handleEmploymentStatus = (event: SelectChangeEvent) => {
        const newEmploymentStatus = event.target.value as string;
        setEmploymentStatus(newEmploymentStatus)

        if (newEmploymentStatus !== "null") {
            setFilters((currentFilters) => [
                ...currentFilters.filter((filter) => filter.field !== "employmentSituation"),
                { field: "employmentSituation", value: newEmploymentStatus }
            ]);
        } else {
            // Remove o filtro de gender se o valor for "null"
            setFilters((currentFilters) =>
                currentFilters.filter((filter) => filter.field !== "employmentSituation")
            );
        }
    }


    const sendMsg = async () => {
        setIsLoading(true);
        

        if (msgType === "sms") {
            try {
                const res = await adminService.directMessage(numbers, msgBody);
                if (res.status) {
                    alert("comunicado enviado com sucesso!");
                    setIsLoading(false);
                }
            } catch (e) {
                alert("Não foi possível enviar mensagem!");
                
            }
        } else {
            try {


                const AnnouncementBody = {
                    "title": "Mensagem da administração",
                    "announcement": msgBody,
                    "location": "",
                    "contact": "Administração",
                };

                const res = await adminService.officialAnnouncement(users.map(e => e.id), AnnouncementBody);
                if (res.status) {
                    alert("comunicado enviado com sucesso!");
                    setIsLoading(false);
                }
                setIsLoading(false);
            } catch (e) {
                alert("Não foi possível enviar mensagem!");
                
            }
        }
    }

    const fetchRegisters = async () => {

        try {
            const getUsers = await userServices.getUserFilter(filters);
            setUsers(getUsers);
            filterByAge();
            filterByIncome();
            setNumbers(getUsers.map(e => e.phone.replace(/[^\d]/g, "")));
            
        } catch (error) {
            console.error('Failed to fetch users:', error);
            // Optionally handle the error, e.g., by setting an error state or an empty array
            setUsers([]);
        }

    }


    return (
        <Container>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
                {/* <Search /> */}
                <div className={styles.user}>
                    <img src="./assets/icon.svg" alt="" />
                    <p>{generalData.points} Pontos</p>
                    <img src="./assets/notification.svg" alt="" />
                    <div className={styles.userPhoto}>
                        <img src="./assets/photo.svg" alt="" />
                    </div>
                </div>
            </Box>

            <Box sx={{ mt: 10 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                    Mensagens diretas
                </Typography>


                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", mt: 5, mb: 5 }}>

                    <Typography sx={{ fontWeight: 600, fontSize: 12 }}>Escolha a forma de distribuição do comunicado</Typography>

                    <FormControl variant="standard" sx={{ mr: 2, minWidth: 120 }}>
                        <InputLabel id="demo-customized-select-label"></InputLabel>

                        <Stack sx={{ borderRadius: "10px", padding: "0px 10px 0 4px", border: "1px solid black" }}>
                            <Select
                                labelId="demo-customized-select-label"
                                id="demo-customized-select"
                                value={msgType}
                                onChange={handleChange}
                                input={<SelectFieldsStyle
                                />}
                                sx={{
                                    // Sobrescrever estilos específicos para o ícone
                                    '& .MuiSvgIcon-root': {
                                        color: 'white', // Cor do ícone
                                    }
                                }}
                            >
                                <MenuItem value={"sms"}>SMS</MenuItem>
                                <MenuItem value={"comunicado"}>Comunicado</MenuItem>

                            </Select>
                        </Stack>
                    </FormControl>

                    

                </Box>


                {

                    !disabled &&
                    <>

                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: 700, justifyContent: "flex-start" }}>

                            <FilterField disabled={disabled} options={["null", "Feminino", "Masculino"]} value={gender} handleChange={handleGender} title={"Gênero"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Solteiro", "Casado", "União estável", "Viuvo(a)"]} value={maritalStatus} handleChange={handleMaritalStatus} title={"Estado cívil"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "1", "2", "3", "4+"]} value={children} handleChange={handleChildren} title={"Filhos"}></FilterField>
                            <FilterField disabled={disabled} options={["10 a 15 anos", "16 a 20 anos", "21 a 30 anos", "31 a 40 anos", "41 a 50 anos", "51 a 60 anos", "61 a 70 anos", "acima de 70"]} value={age} handleChange={handleAge} title={"Idade"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "1 salário", "1 a 2 salários", "3 a 4 salários", "acima de 5 salários"]} value={familyIncome} handleChange={handleFamilyIncome} title={"Renda Familiar"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Bolsa família", "Casa verde amarela"]} value={govProgram} handleChange={handleGovProgram} title={"Programa gov"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "true", "false"]} value={cadUnico} handleChange={handleCadUnico} title={"CAD Único"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Sim", "Não"]} value={healthProfessional} handleChange={handleHealthProfessional} title={"Profissional da saúde"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Diabetes", "Hipertensão", "Soropositivo"]} value={preIllnesses} handleChange={handlePreIllnesses} title={"Doença pré-existente"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Cadeirante", "Cego", "Outra"]} value={deficiency} handleChange={handleDeficiency} title={"Possui deficiência?"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Desempregado", "Empregado"]} value={employmentStatus} handleChange={handleEmploymentStatus} title={"Situação de trabalho"}></FilterField>
                            <FilterField disabled={disabled} options={["null", "Branca", "Preta", "Parda", "Amarela", "Indígena", "Outra"]} value={race} handleChange={handleRace} title={"Etnia"}></FilterField>
                            <FilterField disabled={disabled} options={["null", 'Analfabeto',
                                'Ensino Fundamental Incompleto',
                                'Ensino Fundamental Completo',
                                'Ensino Médio Incompleto',
                                'Ensino Médio Completo',
                                'Ensino Técnico',
                                'Ensino Superior Incompleto',
                                'Ensino Superior Completo',
                                'Pós-Graduação ou Superior']} value={education} handleChange={handleEducation} title={"Educação"}></FilterField>

                        </Box>




                    </>
                }
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <FormControl variant="standard" sx={{ ml: 2, minWidth: 120 }}>
                        <InputLabel shrink htmlFor="bootstrap-input"></InputLabel>
                        <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={disabled}
                            onChange={(e) => {
                                if (e.target.value) {
                                    setDisabled(e.target.value)
                                    setFilters([])
                                    fetchRegisters()
                                } else {
                                    setFilters([])
                                    fetchRegisters()
                                    setDisabled(e.target.value)
                                }
                            }}
                            input={<BootstrapInput />}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    color: 'black', // Cor do ícone preta
                                },
                            }}
                        >
                            <MenuItem value={true}>
                                Todos
                            </MenuItem>
                            <MenuItem value={false}>
                                Filtrar
                            </MenuItem>

                        </Select>
                    </FormControl>

                    <Box sx={{ml: 1}}>
                        <Button onClick={fetchRegisters} sx={{ backgroundColor: "#D3F26A", color: "black", mt: 3, borderRadius: "10px" }}>Aplicar filtros</Button>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", mt: 10 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            borderRadius: 3,
                            width: 400,
                        }}
                    >
                        <TextareaAutosize
                            onChange={handleMsgBody}
                            value={msgBody}
                            minRows={3}
                            placeholder="Digite aqui sua mensagem"
                            style={{
                                width: '100%',
                                border: 'none',
                                height: "70px",
                                padding: '12px',
                                borderRadius: '12px',
                                boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                                resize: 'none',
                                marginBottom: '16px',
                                outline: "none",
                                border: "1px solid #D3F26A"
                            }}
                        />
                        {isLoading && <CircularProgress />}
                        {!isLoading &&
                            <Stack direction="column" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>

                                <Button
                                    disabled={users.length == 0}
                                    variant="contained"
                                    onClick={sendMsg}
                                    sx={{
                                        bgcolor: 'yellowgreen',
                                        ':hover': { bgcolor: 'darkolivegreen' },
                                        borderRadius: 20,
                                        textTransform: 'none',
                                        fontSize: '0.875rem',
                                        fontWeight: 'regular',
                                        paddingX: 4
                                    }}
                                >
                                    Enviar mensagem
                                </Button>
                            </Stack>
                        }
                    </Box>


                    <Box sx={{ ml: 4, height: 240, width: 300, backgroundColor: "#FFFFFF", borderRadius: "20px", overflowY: "auto" }}>

                        {users.map((e, index) => {
                            return (
                                <Stack sx={{ m: 3, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Typography>Nome: {e.name}</Typography>
                                    <Button onClick={() => {
                                        // Remove the user from the users array
                                        setUsers(currentUsers => currentUsers.filter((_, i) => i !== index));
                                        // Remove the corresponding number from the numbers array
                                        setNumbers(currentNumbers => currentNumbers.filter((_, i) => i !== index));
                                    }} sx={{ fontWeight: 600, color: "red" }}>
                                        <DeleteIcon />
                                    </Button>
                                </Stack>
                            )
                        })}

                    </Box>

                </Box>

            </Box>

        </Container>
    );
}