import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent, Collapse } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';
import FilterField from '../registersScreen/components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AdminServices from '../../Services/AdminServices.ts';
import CircularProgress from '@mui/material/CircularProgress';
import ComunicateListItem from './components/ServiceListItem.tsx';
import { Description } from '@mui/icons-material';
import ServiceListItem from './components/ServiceListItem.tsx';

const adminService = new AdminServices();


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        backgroundColor: '#383C40',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white', // Cor do texto
        padding: '7px 15px 7px 6px', // Espaçamento interno do Select
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Sobrescrever as bordas padrões
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

const SelectFieldsStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        fontSize: 14,
        fontWeight: 'normal',
        color: 'black', // Cor do texto
        padding: '5px 15px 5px 6px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

interface Filter {
    field: string;
    value: string | boolean;
}

interface User {
    id: string;
    name: string;
    phone: string;
    gender: string;
    birthday: string;
    // Add other user properties as needed
}




export default function CreatedServices({ generalData }) {
    const [cityServices, setCityServices] = useState([]);
    const [currentCityService, setCurrentCityService] = useState({
        id: null,
        title: null,
        iconPhoto: null,
        createdAt: null,
        content: null,
        contact: null,
        location: null,
        workTime: null,
        faq: []
    })
    const [faq, setFaq] = useState([]);
    const [fileName, setFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [bannerFileName, setBannerFileName] = useState("");
    const [iconFileName, setIconFileName] = useState("");


    const handleBannerFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setBannerFileName(file.name.slice(0, 20) + '...');
        }
    };

    const handleIconFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setIconFileName(file.name.slice(0, 20) + '...');
        }
    };

    useEffect(() => {
        fetchCityServices()
    }, [])



    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name.slice(0, 20) + '...');
        }
    };


    const handleCityService = (data) => {
        setCurrentCityService(prev=>{
            const newData = {...data}
            newData.content = "";
            return newData;
        });
    }


    const updateCityService = async () => {

        setIsLoading(true);
        const cep = document.getElementById("cep").value;
        const road = document.getElementById("road").value;
        const number = document.getElementById("number").value;
        //const complement = document.getElementById("complement").value,
        const neighborhood = document.getElementById("neighborhood").value;
        const city = document.getElementById("city").value;

        const location = `${road}, ${neighborhood}, ${city}, ${number},- ${cep}`;

        const contact = currentCityService.contact;
        const workTime = document.getElementById("workTime").value;
        const iconFileInput = document.getElementById("iconFile");
        const bannerFileInput = document.getElementById("bannerFile");

        if (iconFileInput && bannerFileInput && iconFileInput.files.length > 0 && bannerFileInput.files.length > 0) {
            const iconFile = iconFileInput.files[0];
            const bannerFile = bannerFileInput.files[0]


            const ServiceBody = {
                "title": currentCityService.title,
                "content": currentCityService.content,
                "location": location,
                "contact": currentCityService.contact,
                "workTime": workTime,
                "faq": currentCityService.faq
            };



            try {
                const res = await adminService.updateCityService(currentCityService.id, ServiceBody, iconFile, bannerFile)
                if (res.status) {
                    alert("Serviço criado")
                    setIsLoading(false);
                } else {
                    alert("não foi possível criar serviço")
                    setIsLoading(false);
                }

            } catch (e) {
                alert("não foi possível criar serviço")
                setIsLoading(false);
            }

        } else {
            alert("selecione alguma imagem!");
        }





    }


    const fetchCityServices = async () => {

        try {
            const cityServices = await adminService.getCityServices();
            setCityServices(cityServices);
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }

    }


    return (
        <Container>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
                {/* <Search /> */}
                <div className={styles.user}>
                    <img src="./assets/icon.svg" alt="" />
                    <p>{generalData.points} Pontos</p>
                    <img src="./assets/notification.svg" alt="" />
                    <div className={styles.userPhoto}>
                        <img src="./assets/photo.svg" alt="" />
                    </div>
                </div>
            </Box>

            <Box sx={{ mt: 10 }}>
                <Typography sx={{ mb: 10, fontWeight: 600, fontSize: 24 }}>
                    Serviços
                </Typography>



                <Box sx={{display: "flex", flexDirection: "row"}}>
                {
                    cityServices.length > 0 ? (
                        cityServices.map((e, index) => (

                            <ServiceListItem key={index} serviceInfo={{
                                id: e.id,
                                title: e.title,
                                iconPhoto: e.iconPhoto,
                                bannerImage: e.bannerImage,
                                createdAt: e.createdAt,
                                content: e.content,
                                contact: e.contact,
                                location: e.location,
                                faq: Array.isArray(e.faq) ? [...e.faq] : []
                            }}
                                changeCurrentCityService={handleCityService}
                            />
                        ))
                    ) : (
                        <Typography>Nenhum serviço enviado encontrado.</Typography>
                    )
                }
                </Box>


                {
                    currentCityService &&
                    currentCityService.id &&

                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", mt: 10 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    borderRadius: 3,
                                    width: 700,
                                    mb: 3
                                }}
                            >
                                <Stack sx={{ m: 0 }}>
                                    <Typography>Serviço</Typography>
                                    <input value={currentCityService.title} onChange={(e) => setCurrentCityService(prev => {
                                        const data = { ...prev };

                                        data.title = e.target.value;
                                        return data

                                    })} className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px", border: "1px solid #D3F26A", marginBottom: 10, }} type="text" placeholder='título do serviço' />
                                </Stack>
                                <TextareaAutosize
                                    value={currentCityService.content}
                                    minRows={3}
                                    placeholder="Digite aqui sua mensagem"
                                    onChange={(e) => setCurrentCityService(prev => {
                                        const data = { ...prev };

                                        data.content = e.target.value;
                                        return data

                                    })}
                                    style={{
                                        width: '500px',
                                        border: 'none',
                                        height: "70px",
                                        padding: '12px',
                                        borderRadius: '0 12px 12px 12px',
                                        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                                        resize: 'none',
                                        marginBottom: '16px',
                                        outline: "none",
                                        border: "1px solid #D3F26A"
                                    }}
                                />

                                <Button onClick={() => setCurrentCityService((prev) => {
                                    const data = {...prev};
                                    data.faq.push({title: "", description: ""});
                                    return data;
                                })}>Adicionar FAQ</Button>


                            </Box>






                            <Box sx={{ mt: 7,ml: 5, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                <Stack sx={{ m: 1 }}>
                                    <Typography sx={{fontSize: 11}}>CEP</Typography>
                                    <input id="cep" className={styles.sendMsgInput} type="text" />
                                </Stack>
                                <Stack sx={{ m: 1 }}>
                                    <Typography sx={{fontSize: 12}}>Rua/Avenida</Typography>
                                    <input id="road" className={styles.sendMsgInput} type="text" />
                                </Stack>
                                <Stack sx={{ m: 1 }}>
                                    <Typography sx={{fontSize: 12}}>Número</Typography>
                                    <input id="number" className={styles.sendMsgInput} type="text" />
                                </Stack>
                                <Stack sx={{ m: 1 }}>
                                    <Typography sx={{fontSize: 12}}>Complemento</Typography>
                                    <input id="complement" className={styles.sendMsgInput} type="text" />
                                </Stack>
                                <Stack sx={{ m: 1 }}>
                                    <Typography sx={{fontSize: 12}}>Bairro</Typography>
                                    <input id="neighborhood" className={styles.sendMsgInput} type="text" />
                                </Stack>
                                <Stack sx={{ m: 1 }}>
                                    <Typography sx={{fontSize: 12}}>Cidade</Typography>
                                    <input id="city" className={styles.sendMsgInput} type="text" />
                                </Stack>

                                <Stack sx={{ ml: 1 }}>
                                    <Typography sx={{fontSize: 12}}>Data e hora do funcionamento</Typography>
                                    <input id="workTime" className={styles.sendMsgInput} type="text" placeholder='inserir' />
                                </Stack>

                            </Box>


                        </Box>


                        {currentCityService.faq.length > 0 &&
                            <Box sx={{ mt: 5 }}>
                                <Typography>
                                    Dúvidas frequentes
                                </Typography>
                                {currentCityService.faq.map((e, index) => {
                                    return (
                                        <Box>
                                            <Stack sx={{ mt: 4, width: 300 }}>
                                                <input
                                                    value={currentCityService.faq[index].title}
                                                    onChange={(handle) => {
                                                        setCurrentCityService((prev) => {

                                                            const prevData = { ...prev };

                                                            prevData.faq[index].title = handle.target.value;

                                                            return prevData;
                                                        })
                                                    }}
                                                    className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='Título da pergunta' />
                                            </Stack>
                                            <TextareaAutosize
                                                value={currentCityService.faq[index].description}
                                                onChange={(handle) => {
                                                    setCurrentCityService((prev) => {

                                                        const prevData = { ...prev };
                                                        prevData.faq[index].description = handle.target.value;
                                                        return prevData;

                                                    })
                                                }}
                                                minRows={3}
                                                placeholder="Digite aqui a resposta da pergunta"
                                                style={{
                                                    width: '92%',
                                                    border: 'none',
                                                    height: "70px",
                                                    padding: '12px',
                                                    borderRadius: '0px 12px 12px 12px',
                                                    boxShadow: 'inset 0 2px 2px rgba(0, 0, 0, 0.1)',
                                                    resize: 'none',
                                                    marginBottom: '16px',
                                                    outline: "none"
                                                }}
                                            />
                                        </Box>
                                    )
                                })}


                            </Box>

                        }

                        <Stack sx={{ mb: 5, width: 400 }}>
                            <Typography>Contato: </Typography>
                            <input value={currentCityService.contact} onChange={(e) => setCurrentCityService(prev => {
                                const data = { ...prev };

                                data.contact = e.target.value;
                                return data

                            })} className={styles.sendMsgInput} type="text" placeholder='Contato' />
                        </Stack>
                        <Stack onClick={() => document.getElementById("iconFile")?.click()} sx={{ mb: 5, width: 400, backgroundColor: "#D3F26A", height: 50, borderRadius: 2, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                            <Typography sx={{ mr: 1 }}>{iconFileName !== "" ? iconFileName : "Adicionar ícone"}</Typography>
                            <CloudUploadIcon sx={{ mr: 2 }} />
                            <input onChange={handleIconFileChange} id="iconFile" style={{ display: "none" }} type="file" />
                        </Stack>

                        <Stack onClick={() => document.getElementById("bannerFile")?.click()} sx={{ mb: 5, width: 400, backgroundColor: "#D3F26A", height: 50, borderRadius: 2, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                            <Typography sx={{ mr: 1 }}>{bannerFileName !== "" ? bannerFileName : "Adicionar Banner"}</Typography>
                            <CloudUploadIcon sx={{ mr: 2 }} />
                            <input onChange={handleBannerFileChange} id="bannerFile" style={{ display: "none" }} type="file" />
                        </Stack>
                        {isLoading && <CircularProgress />}
                        {!isLoading &&
                            <Stack direction="column" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        updateCityService();

                                    }}
                                    sx={{
                                        bgcolor: 'yellowgreen',
                                        ':hover': { bgcolor: 'darkolivegreen' },
                                        borderRadius: 20,
                                        textTransform: 'none',
                                        fontSize: '0.875rem',
                                        fontWeight: 'regular',
                                        paddingX: 4,
                                        width: 400
                                    }}
                                >
                                    Atualizar serviço municipal
                                </Button>
                            </Stack>
                        }
                    </Box>

                }



            </Box>

        </Container>
    );
}