import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';
import FilterField from '../registersScreen/components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminServices from '../../Services/AdminServices.ts';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';

const adminService = new AdminServices();
const usersServices = new UserServices();

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        backgroundColor: '#383C40',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white', // Cor do texto
        padding: '5px 15px 5px 6px', // Espaçamento interno do Select
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Sobrescrever as bordas padrões
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

const SelectFieldsStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        fontSize: 14,
        fontWeight: 'normal',
        color: 'black', // Cor do texto
        padding: '5px 15px 5px 6px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

interface Filter {
    field: string;
    value: string | boolean;
}

interface User {
    id: string;
    name: string;
    phone: string;
    gender: string;
    birthday: string;
    // Add other user properties as needed
}




export default function EditUser({ generalData }) {

    const [currentCategory, setCurrentCategory] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState();



    useEffect(() => {
        fetchAdminUsers();

    }, [])

    useEffect(() => {
        setPassword("");
    }, [currentUser])

    useEffect(() => {
        if (generalData && Array.isArray(generalData.categories) && generalData.categories.length > 0) {
            setCurrentCategory(generalData.categories[0]);
        }
    }, [generalData]);


    const userServices = new UserServices();



    const fetchAdminUsers = async () => {
        try {

            const getUsers = await usersServices.getAllAdminUsers();
            setUsers(getUsers);


        } catch (e) {
            console.log(e);
        }
    }


    const updateUser = async () => {

        setIsLoading(true);


        try {
            const res = await adminService.updateUser(users[currentUser].uid, users[currentUser].name, users[currentUser].email, password, currentCategory);
            if (res.status) {
                alert("usuário atualizado")
                setIsLoading(false);
            } else {
                alert("não foi possível atualizar usuário")
                setIsLoading(false);
            }

        } catch (e) {
            alert("não foi possível atualizar usuário")
            setIsLoading(false);
        }





    }




    return (
        <Container>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
                {/* <Search /> */}
                <div className={styles.user}>
                    <img src="./assets/icon.svg" alt="" />
                    <p>{generalData.points} Pontos</p>
                    <img src="./assets/notification.svg" alt="" />
                    <div className={styles.userPhoto}>
                        <img src="./assets/photo.svg" alt="" />
                    </div>
                </div>
            </Box>

            <Box sx={{ mt: 10 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                    Cadastrar usuário
                </Typography>


                <Box sx={{ mt: 3, display: "flex", flexDirection: "column" }}>
                    <Typography>Usuários: </Typography>
                    {users && users.map((e, index) => {
                        return (
                            <Stack sx={{ p: 1, width: "fit-content", display: "flex", flexDirection: "row", alignItems: "center" }}>{e.email} <IconButton onClick={() => setCurrentUser(index)}><EditIcon /></IconButton></Stack>

                        )
                    })}
                </Box>


                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", mt: 10 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            borderRadius: 3,
                            width: 700,
                            mb: 3
                        }}
                    >
                        <Stack sx={{ m: 1 }}>
                            <Typography>Nome</Typography>
                            <input value={users[currentUser] != null ? users[currentUser].name : ""} onChange={(e) => {
                                setUsers((prev) => {
                                    const data = [...prev];

                                    data[currentUser].name = e.target.value
                                    return data;

                                })
                            }} className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='Nome do usuário' />
                        </Stack>

                        <Stack sx={{ m: 1 }}>
                            <Typography>Email</Typography>
                            <input value={users[currentUser] != null ? users[currentUser].email : ""} onChange={(e) => {
                                setUsers((prev) => {
                                    const data = [...prev];

                                    data[currentUser].email = e.target.value
                                    return data;

                                })
                            }} className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='Email do usuário' />
                        </Stack>


                        <Stack sx={{ m: 1 }}>
                            <Typography>Senha</Typography>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="password" placeholder='Senha do usuário' />
                        </Stack>



                        <FilterField disabled={false} options={generalData.categories ?? []} value={currentCategory ?? ""} handleChange={(handle) => setCurrentCategory(handle.target.value)} title={"Secretaria"}></FilterField>




                    </Box>









                </Box>



                {isLoading && <CircularProgress />}
                {!isLoading &&
                    <Stack direction="column" spacing={2} sx={{ width: '300px', justifyContent: 'space-between' }}>

                        <Button
                            variant="contained"
                            onClick={() => {
                                updateUser();
                            }}
                            sx={{
                                bgcolor: 'yellowgreen',
                                ':hover': { bgcolor: 'darkolivegreen' },
                                borderRadius: 20,
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                fontWeight: 'regular',
                                paddingX: 4
                            }}
                        >
                            Atualizar usuário
                        </Button>
                    </Stack>
                }


            </Box>

        </Container>
    );
}