import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography, FormControl, InputLabel, styled, MenuItem, InputBase, Collapse, Button } from "@mui/material";
import Select from '@mui/material/Select';
import Delete from '@mui/icons-material/Delete';
import { Edit } from '@mui/icons-material';
import AdminServices from '../../../Services/AdminServices.ts';


const adminServices = new AdminServices();


export default function ServiceListItem({ serviceInfo, changeCurrentCityService }) {
    const [expanded, setExpanded] = useState(false)
    const [faqExpanded, setFaqExpanded] = useState([]);

    useEffect(() => {


        // Verifica se serviceInfo.faqs não está vazio
        if (serviceInfo.faq && serviceInfo.faq.length > 0) {
            // Inicializa o estado com base no comprimento de serviceInfo.faqs
            setFaqExpanded(Array(serviceInfo.faq.length).fill(false));
        } else {
            // Caso serviceInfo.faqs esteja vazio, inicializa o estado com uma array vazia
            setFaqExpanded([]);
        }
    }, [serviceInfo.faq]);


    const deleteComunicate = async () => {
        try {
            const res = await adminServices.deleteCityService(serviceInfo.id);

            if (res.status) {
                alert("Serviço deletado com sucesso")
                window.location.reload();
            } else {
                alert("não foi possível deletar!");
            }
        } catch (e) {
            alert("não foi possível deletar!");
        }

    }



    return (
        <Box sx={{ m: 2, height: expanded ? "auto" : 200, width: expanded ? 300 : 200, backgroundColor: "white", borderRadius: 3 }}>
            <Stack onClick={() => setExpanded(!expanded)} sx={{ borderRadius: "15px 15px 0 0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", height: expanded ? 200 : 100, backgroundImage: serviceInfo.iconPhoto ? `url('${serviceInfo.iconPhoto}')` : "none", backgroundSize: "cover", backgroundPosition: "center" }}>


            </Stack>
            <Stack sx={{ height: 50,display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
                <Typography sx={{fontWeight: 700, wordBreak: "break-all" }}>
                    {serviceInfo.title}
                </Typography>
            </Stack>

            <Box sx={{ m: 2 }}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Descrição:
                        </Typography>
                        <Typography sx={{ wordBreak: "break-all" }}>
                            {serviceInfo.content}
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Contato:
                        </Typography>
                        <Typography sx={{ wordBreak: "break-all" }}>
                            {serviceInfo.contact}
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Endereço:
                        </Typography>
                        <Typography sx={{ wordBreak: "break-all" }}>
                            {serviceInfo.location}
                        </Typography>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            Banner:
                        </Typography>
                        <Stack sx={{ borderRadius: 4, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around", height: 90, width: 250, backgroundImage: serviceInfo.bannerImage ? `url('${serviceInfo.bannerImage}')` : "none", backgroundSize: "cover", backgroundPosition: "center" }}>


                        </Stack>
                    </Stack>
                    <Stack sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography sx={{ fontWeight: 700, mr: 1 }}>
                            faq:
                        </Typography>
                        {
                            serviceInfo.faq &&
                            serviceInfo.faq.map((e, index) => {
                                return (
                                    <Stack onClick={() => setFaqExpanded(prev => {
                                        const data = [...prev];
                                        data[index] = !data[index];
                                        return data;
                                    })}>
                                        <Typography sx={{ fontWeight: 600, ":hover": { color: "blue", cursor: "pointer" } }}>
                                            {e.title}
                                        </Typography>
                                        <Collapse in={faqExpanded[index]} timeout="auto" unmountOnExit>
                                            {e.description}
                                        </Collapse>
                                    </Stack>
                                );
                            })
                        }
                    </Stack>

                </Collapse>
            </Box>
            <Stack sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", padding: 1 }}>
                <Delete onClick={() => { deleteComunicate() }} sx={{ mr: 3 }} />
                <Edit onClick={() => changeCurrentCityService(serviceInfo)} sx={{ mr: 1 }} />
            </Stack>
        </Box>
    );
}
