import * as React from 'react';
import { useEffect, useState } from 'react';
import { TextareaAutosize, Button, Accordion, AccordionSummary, AccordionDetails, Box, Container, Typography, FormControl, Select, MenuItem, InputLabel, InputBase, styled, Stack, InputAdornment, Paper, TextField, IconButton, Icon, SelectChangeEvent } from "@mui/material";
import Card from "../../components/problemReportsScreen/components/card.tsx";
import SearchIcon from '@mui/icons-material/Search';
import CalendarIcon from "../../assets/calendar.svg";
import PrinterIcon from '@mui/icons-material/Print';
import { ReactComponent as ArrowDownIcon } from "../../assets/chevron.svg";
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import styles from "./style.module.css";
import Search from "../../components/home/search/index.jsx"
import Problems from '../../components/home/problems/index.jsx';
import FilterField from '../registersScreen/components/filterField.tsx';
import UserServices from '../../Services/UserServices.ts';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AdminServices from '../../Services/AdminServices.ts';
import CircularProgress from '@mui/material/CircularProgress';

const adminService = new AdminServices();


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        backgroundColor: '#383C40',
        fontSize: 14,
        fontWeight: 'bold',
        color: 'white', // Cor do texto
        padding: '5px 15px 5px 6px', // Espaçamento interno do Select
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Sobrescrever as bordas padrões
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

const SelectFieldsStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20, // Arredondar os cantos
        position: 'relative',
        fontSize: 14,
        fontWeight: 'normal',
        color: 'black', // Cor do texto
        padding: '5px 15px 5px 6px',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        // Estilos para o ícone do dropdown

    },
}));

interface Filter {
    field: string;
    value: string | boolean;
}

interface User {
    id: string;
    name: string;
    phone: string;
    gender: string;
    birthday: string;
    // Add other user properties as needed
}




export default function SecretariatComunicate({ generalData }) {

    const [faq, setFaq] = useState([]);

    const [filters, setFilters] = useState<Filter[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    const [isLoading, setIsLoading] = useState(false);


    const userServices = new UserServices();





    useEffect(() => {

        console.log(filters)

    }, [filters])


    const sendAnnouncement = async () => {
        if (filters.length == 0) {
            await fetchRegisters()
        }
        setIsLoading(true);
        const cep = document.getElementById("cep").value;
        const road = document.getElementById("road").value;
        const number = document.getElementById("number").value;
        //const complement = document.getElementById("complement").value,
        const neighborhood = document.getElementById("neighborhood").value;
        const city = document.getElementById("city").value;

        const location = `${road}, ${neighborhood}, ${city}, ${number},- ${cep}`;

        const contact = document.getElementById("contact").value;
        const fileInput = document.getElementById("file");
        if (fileInput && fileInput.files.length > 0) {
            const file = fileInput.files[0];
            const AnnouncementBody = {
                "title": document.getElementById("title").value,
                "announcement": document.getElementById("msg").value,
                "location": location,
                "contact": contact,
                "faq": faq
            };

            const arrayUids = users.map(e => e.id);
            console.log(arrayUids);

            try {
                const res = await adminService.officialAnnouncement(arrayUids, AnnouncementBody, file)
                if (res.status) {
                    alert("Comunicado enviado")
                    setIsLoading(false);
                } else {
                    alert("não foi possível enviar comunicado")
                    setIsLoading(false);
                }

            } catch (e) {
                alert("não foi possível enviar comunicado")
                setIsLoading(false);
            }

        } else {
            alert("selecione alguma imagem!");
        }





    }



    const fetchRegisters = async () => {

        try {
            const getUsers = await userServices.getUserFilter(filters);
            setUsers(getUsers);
        } catch (error) {
            console.error('Failed to fetch users:', error);
            // Optionally handle the error, e.g., by setting an error state or an empty array
            setUsers([]);
        }

    }


    return (
        <Container>

            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3, }}>
                {/* <Search /> */}
                <div className={styles.user}>
                    <img src="./assets/icon.svg" alt="" />
                    <p>{generalData.points} Pontos</p>
                    <img src="./assets/notification.svg" alt="" />
                    <div className={styles.userPhoto}>
                        <img src="./assets/photo.svg" alt="" />
                    </div>
                </div>
            </Box>

            <Box sx={{ mt: 10 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
                    Comunicado secretaria
                </Typography>



                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "start", mt: 10 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            borderRadius: 3,
                            width: 700,
                            mb: 3
                        }}
                    >
                        <Stack sx={{ m: 0 }}>
                            <Typography>Comunicado</Typography>
                            <input id="title" className={styles.sendMsgInput} style={{ borderRadius: "5px 5px 0px 0px" }} type="text" placeholder='título do comunicado' />
                        </Stack>
                        <TextareaAutosize
                            id="msg"
                            minRows={3}
                            placeholder="Digite aqui sua mensagem"
                            style={{
                                width: '80%',
                                border: 'none',
                                height: "70px",
                                padding: '12px',
                                borderRadius: '0 12px 12px 12px',
                                boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                                resize: 'none',
                                marginBottom: '16px',
                                outline: "none"
                            }}
                        />


                    </Box>







                </Box>

                {isLoading && <CircularProgress />}
                {!isLoading &&
                    <Stack direction="column" spacing={2} sx={{ width: '580px', justifyContent: 'space-between' }}>

                        <Button
                            variant="contained"
                            onClick={() => {
                                sendAnnouncement();

                            }}
                            sx={{
                                bgcolor: 'yellowgreen',
                                ':hover': { bgcolor: 'darkolivegreen' },
                                borderRadius: 20,
                                textTransform: 'none',
                                fontSize: '0.875rem',
                                fontWeight: 'regular',
                                paddingX: 4
                            }}
                        >
                            Enviar mensagem
                        </Button>
                    </Stack>
                }

            </Box>

        </Container>
    );
}