import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography, FormControl, InputLabel, styled, MenuItem, InputBase, Collapse, IconButton } from "@mui/material";
import { TextareaAutosize, Button } from "@mui/material";
import Select from '@mui/material/Select';
import styles from "./style.module.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PrintIcon from '@mui/icons-material/Print';
import { WhatsApp } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Star from "../../../assets/Star.svg";

import ProblemReportServices from '../../../Services/ProblemReportServices.ts';

const problemReportService = new ProblemReportServices();

function calculateAge(birthdate) {

  const birthDateParts = birthdate.split("/");
  const birthDate = new Date(birthDateParts[2], birthDateParts[1] - 1, birthDateParts[0]);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  // Verifica se o mês atual é antes do mês de nascimento ou se estamos no mês do aniversário mas o dia ainda não chegou
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export default function UserRegisterComponent({ userInfo }) {

  const [expanded, setExpanded] = useState(false);
  const [problems, setProblems] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [problemStatus, setProblemStatus] = useState();
  const [currentGalleryImage, setCurrentGalleryImage] = useState(0);
  const [sendMsgToReporterModal, setSendMsgToReporterModal] = useState(false);
  const [msgToReporter, setMsgToReporter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    ListProblemsByUserId();
    console.log("mudou")
  }, [userInfo])

  const renderUserFields = (filter) => {

    //filter: 0 = secundaryInfo
    //filter: 1 = mainInfo

    const fields = [];

    if (filter === 1 && userInfo["profile_photo"] === null) {
      console.log("é null")
      fields.push(
        <Stack sx={{ display: "flex", flexDirection: "row", height: 80, width: 80, backgroundColor: "blue", borderRadius: 50, backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAANlBMVEXm5uampqajo6Pj4+O8vLzp6emgoKDg4OCqqqra2tq4uLitra3AwMDHx8ewsLC1tbXNzc3T09PfW5RvAAAEUElEQVR4nO2c2XbjIAxAWSy8b///s4OdNpPEToqNQIqP7lP7lnuEQQiBUoIgCIIgCIIgCIIgCIIgCIIgCIIgCILADPAoW3rs7e/vxdZTN2jjVoweuqm21L/pBLCINJV30A/4f6tmEfqmCIHyJtoZozcY47T3+Rodr9K3eyL/hdr+S3Sg7oZPKjedoav524D9HJXH6FjmOlCPOkBl1dEj7+BAHybyS8/XBqAJjMo9Og3XdRTKyv39+59xVcnSBurqYFzW2FQcPxyo2xMu3qblZwP1cMrF2wzsbOrxpIu3GWvqX/9MeXQee7JpSurf/0QX4eJtOurf/wDMUS7eZmb02cSpLFAb3IHm8GL5imuYhAbmaBdvw2WgtfEuWrfUFiswRX79N8zEIjQDhovWA7WHWgKD46I1h9CMWDIjtYmfyiosmYp8QoMOy0XrjlqmPJ8tv2JG4nzz7JZsV4Z6m3a0HPMZ6mJNXO7/DPFOAKI2ZRuZhrRUc37nvytDWw1AXGUWaFcavFzmBm1G0yOOMj/OekqXS8lYzJl5mZspz29th7Bj/o8TGaYy1MPsQt/MpWYzhVOZuctMtDKYLj4DoHS5Vm52qawZsQSwFgFIE03UuZl4ZkbeAxDXNGHGrM5QVwGvVDe7VkUTapSjpgXqGuBigzXOzEjucrHzGUAaZy0Dl4udaSqM/MxwONJUV+sDgCLaxhRMXJbmzNhGIEatmjCH9GV/cGHV1aT6qIHmaAsZr9iYz8YVvG7V+C3naRtHvMHccv7YmfyQeYeztQ2GncDqrA1Pl/W6yWEbw/baCdju2HpjTMf3QhCo6cg0YNqJ982zuggNjjEF1yH2iw9OYPm5Yh6WZfHsgzeebc9tsXwCyuBB9jPQ2OpA2biDOY1ztL0/byk7cyI9c6ajrmNuADsdvz33o1NNrBYbUHNzfntmTDPzmdjA9nH7ZlOxuRoMdURY7sHhsYDCjFM3Y1AEBIgcYnebqqe+GgzqYKL8wcZ0tNMAYLcCE9pAVFFmiysIJzVkF8qiEyB3m602ZFtP3GazG4bmlBbiSrLvcBS3G2KL5e+gKKKfe5chyCb72w2gMNuZXmzGzMsNZmPGlryTAHIH4Ct5OwJRe+a25G0JQr1ptke+ozTMK4D7ZDyzSZDGvJLvVsCcWmVhzuNii8SDbMFkyp/rDC7eJs/LOvHPf4TgmhwuZZbA+NBkWGsg/VR2w2VIaiCTi7dJLoPSXBYokzxDQ2uW/Zv07bQ2m4u3SbzUIHWXBsokLj8fflwySibxa1Q26abslSrpOIM6p4vWSTcCgHsv8y9M0hoaNDldtE760VikF7NCGVJ+NCXaZZkw2oTJZuIK05aUNScI7VhCk0m4bOI+ZhRCwuksbVF2j4R7GshRyngk6eWNnJnZKpOyEHApmXw7sx+ZhI8e2vwy6VIAOzqTFZdQRvVFZlIebdjsJJQRBEEQBEEQBEEQBEEQhLf8A3phP/Llv413AAAAAElFTkSuQmCC")`, backgroundSize: "cover" }}>
        </Stack>
      );
    }

    for (const key in userInfo) {



      if (typeof userInfo[key] === 'string' && userInfo[key].trim() !== '') {
        if (filter == 0) {
          if (key !== 'token' && key !== 'profile_photo' && key !== 'name') {
            fields.push(
              <Stack key={key} sx={{ display: "flex", flexDirection: "row" }}>
                <Typography sx={{ fontWeight: 700 }}>{key}: </Typography>
                <Typography>{userInfo[key]}</Typography>
              </Stack>
            );
          }
        } if (filter == 1) {
          if (key === 'profile_photo') {
            fields.unshift(
              <Stack key={key} sx={{ display: "flex", flexDirection: "row", height: 80, width: 80, backgroundColor: "blue", borderRadius: 50, backgroundImage: `url(${userInfo[key]})`, backgroundSize: "cover" }}>
              </Stack>
            );
          } else if (key === 'name') {
            fields.push(
              <Stack key={key} sx={{ display: "flex", flexDirection: "row" }}>
                <Typography>{userInfo[key]}</Typography>
              </Stack>
            );
          } else if (key === 'birthday') {
            fields.push(
              <Stack key={key} sx={{ display: "flex", flexDirection: "row" }}>
                <Typography>{userInfo[key] ? calculateAge(userInfo[key]) : "Not"} anos</Typography>
              </Stack>
            );
          } else if (key === 'employmentSituation') {
            fields.push(
              <Stack key={key} sx={{ display: "flex", flexDirection: "row" }}>
                <Typography>{userInfo[key]}</Typography>
              </Stack>
            );
          }
        }
      }
    }
    return fields;
  };


  const ListProblemsByUserId = async (uid) => {

    const problemsGet = await problemReportService.getReportsByUserId(userInfo.id);
    console.log(problemsGet)
    setProblems(problemsGet);


  }
  const handleChange = (handle) => {
    setProblemStatus(handle.target.value)
  }

  const solveProblem = async (id) => {
    setIsLoading(true);
    const fileInput = document.getElementById("file");
    const file = fileInput?.files[0] ?? null;

    if (!file || !textAreaValue.trim()) {
      setIsLoading(false);
      // Tratamento adequado, como exibir uma mensagem de erro para o usuário
      alert("Arquivo ou solução não fornecidos.");
      return;
    }

    try {
      const result = await problemReportService.SolveProblem(id, file, textAreaValue);
      // Lidar com a resposta, como atualizar a UI ou informar o usuário de sucesso
      if (result.status) {
        setProblemStatus(2);
        setIsLoading(false);
      }
    } catch (error) {
      // Manipulação de erros, como exibir uma mensagem para o usuário
      console.error("Erro ao resolver o problema:", error);
      setIsLoading(false);
    }

  }



  const handleMsgToReporter = (handle) => {
    setMsgToReporter(handle.target.value)
  }


  const containerStyle = {
    width: "340px", height: "134px", borderRadius: "8px"
  };



  const sendSMStoReporter = async () => {


    try {

      const res = await adminServices.directMessage([problemInfo.creatorData.phone], msgToReporter);

      if (res.status) {
        alert("mensagem enviada com sucesso!");
      }
    } catch (e) {
      alert("error:" + e);
    }


  }






  return (
    <Stack sx={{ wordBreak: "break-all", mt: 2, display: "flex", flexDirection: "column", backgroundColor: "white", p: 2 }}>
      <Stack sx={{ display: "flex", flexDirection: "row" }}>
        {
          !expanded &&
          <Stack sx={{ display: "flex", flexDirection: "row", width: "100%", mr: 3 }}>
            <Stack sx={{ width: 230 }}>
              <Typography>Nome: {userInfo.name}</Typography>
            </Stack>
            <Stack sx={{ width: 150 }}>
              <Typography>Idade: {userInfo.birthday ? calculateAge(userInfo.birthday) : "Not"}</Typography>
            </Stack>
            <Stack sx={{ width: 150 }}>
              <Typography>Sexo: {userInfo.gender}</Typography>
            </Stack>
            <Stack sx={{ width: 230 }}>
              <Typography>Telefone: {userInfo.phone}</Typography>
            </Stack>
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Typography>Pontos: </Typography>
              <Typography sx={{ color: "green", fontWeight: 700 }}>{userInfo.points}</Typography>
            </Stack>

            <Stack sx={{ width: 50, display: "flex", justifyContent: "flex-end", flexDirection: "row" }}>
              <IconButton onClick={() => {
                window.location.href = `https://api.whatsapp.com/send?phone=${userInfo.phone}&text=Ola%2C%20aqui%20%C3%A9%20da%20prefeitura `
              }} sx={{ m: 0, p: 0 }}>
                <WhatsApp sx={{ color: "green" }} />
              </IconButton>
            </Stack>



          </Stack>


        }
        <Stack sx={{ alignItems: 'center', cursor: 'pointer' }} onClick={handleExpand}>
          <img src="./assets/eye.svg" alt="" />
        </Stack>
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* <Stack>
            {renderUserFields(0)}
          </Stack> */}
          <Stack sx={{ display: "flex", alignItems: "flex-start", mt: 3 }}>
            {renderUserFields(1)}
            {problems !== null && problems.length > 0 &&
              <Typography sx={{ fontSize: 14, mt: 5, fontWeight: 600 }}> Problemas reportados: </Typography>}
            {problems !== null &&
              problems.map((problemInfo) => {
                if(problemInfo.status == 2)return;
                return (
                  <>
                    <Stack onClick={() => setModalIsOpen(true)} sx={{ fontSize: 12, mt: 1, ":hover": { color: "blue", cursor: "pointer" } }}>{problemInfo.title}</Stack>
                    {modalIsOpen &&
                      <div className={styles.modal} >

                        <div className={styles.modal} onClick={() => setModalIsOpen(false)}></div>

                        <Box sx={{ zIndex: 10, display: "flex", flexDirection: "column", background: "white", width: 1000, padding: 5, borderRadius: "20px", boxShadow: "1px 1px 1px black" }}>
                          <Typography sx={{ fontSize: 12 }}>Descrição do problema</Typography>
                          <Typography sx={{ fontSize: 12 }}>{problemInfo.description}</Typography>

                          <Box sx={{ marginTop: 5, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box>
                              <Stack sx={{ height: 150, width: 225, borderRadius: "20px", background: "url(" + problemInfo.images[currentGalleryImage] + ")", backgroundSize: "cover" }}>

                              </Stack>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {problemInfo.images.map((e, index) => {
                                  if (index !== currentGalleryImage) {
                                    return (
                                      <Stack
                                        onClick={() => { setCurrentGalleryImage(index) }}
                                        key={index} // Não esqueça de usar uma key única para cada item quando estiver renderizando listas
                                        sx={{
                                          height: 50,
                                          width: 75,
                                          borderRadius: "10px",
                                          background: `url(${e})`,
                                          backgroundSize: "cover",
                                          margin: "3px"
                                        }}
                                      ></Stack>
                                    );
                                  }
                                  return null; // Se o index for 0, não renderizar nada.
                                })}
                              </Box>
                              <Stack sx={{ margin: 2 }}>
                                <Typography sx={{ fontSize: 12 }}>Data e hora do reporte</Typography>
                                <Typography sx={{ fontSize: 12 }}>13/01/2024 às 14:30</Typography>
                              </Stack>

                              <Stack sx={{ margin: 2, display: "flex", flexDirection: "row" }}>
                                <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Stack sx={{ height: 80, width: 80, borderRadius: 50, background: problemInfo.creatorData.profile_photo != null ? "url(" + problemInfo.creatorData.profile_photo + ")" : "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAANlBMVEXm5uampqajo6Pj4+O8vLzp6emgoKDg4OCqqqra2tq4uLitra3AwMDHx8ewsLC1tbXNzc3T09PfW5RvAAAEUElEQVR4nO2c2XbjIAxAWSy8b///s4OdNpPEToqNQIqP7lP7lnuEQQiBUoIgCIIgCIIgCIIgCIIgCIIgCIIgCILADPAoW3rs7e/vxdZTN2jjVoweuqm21L/pBLCINJV30A/4f6tmEfqmCIHyJtoZozcY47T3+Rodr9K3eyL/hdr+S3Sg7oZPKjedoav524D9HJXH6FjmOlCPOkBl1dEj7+BAHybyS8/XBqAJjMo9Og3XdRTKyv39+59xVcnSBurqYFzW2FQcPxyo2xMu3qblZwP1cMrF2wzsbOrxpIu3GWvqX/9MeXQee7JpSurf/0QX4eJtOurf/wDMUS7eZmb02cSpLFAb3IHm8GL5imuYhAbmaBdvw2WgtfEuWrfUFiswRX79N8zEIjQDhovWA7WHWgKD46I1h9CMWDIjtYmfyiosmYp8QoMOy0XrjlqmPJ8tv2JG4nzz7JZsV4Z6m3a0HPMZ6mJNXO7/DPFOAKI2ZRuZhrRUc37nvytDWw1AXGUWaFcavFzmBm1G0yOOMj/OekqXS8lYzJl5mZspz29th7Bj/o8TGaYy1MPsQt/MpWYzhVOZuctMtDKYLj4DoHS5Vm52qawZsQSwFgFIE03UuZl4ZkbeAxDXNGHGrM5QVwGvVDe7VkUTapSjpgXqGuBigzXOzEjucrHzGUAaZy0Dl4udaSqM/MxwONJUV+sDgCLaxhRMXJbmzNhGIEatmjCH9GV/cGHV1aT6qIHmaAsZr9iYz8YVvG7V+C3naRtHvMHccv7YmfyQeYeztQ2GncDqrA1Pl/W6yWEbw/baCdju2HpjTMf3QhCo6cg0YNqJ982zuggNjjEF1yH2iw9OYPm5Yh6WZfHsgzeebc9tsXwCyuBB9jPQ2OpA2biDOY1ztL0/byk7cyI9c6ajrmNuADsdvz33o1NNrBYbUHNzfntmTDPzmdjA9nH7ZlOxuRoMdURY7sHhsYDCjFM3Y1AEBIgcYnebqqe+GgzqYKL8wcZ0tNMAYLcCE9pAVFFmiysIJzVkF8qiEyB3m602ZFtP3GazG4bmlBbiSrLvcBS3G2KL5e+gKKKfe5chyCb72w2gMNuZXmzGzMsNZmPGlryTAHIH4Ct5OwJRe+a25G0JQr1ptke+ozTMK4D7ZDyzSZDGvJLvVsCcWmVhzuNii8SDbMFkyp/rDC7eJs/LOvHPf4TgmhwuZZbA+NBkWGsg/VR2w2VIaiCTi7dJLoPSXBYokzxDQ2uW/Zv07bQ2m4u3SbzUIHWXBsokLj8fflwySibxa1Q26abslSrpOIM6p4vWSTcCgHsv8y9M0hoaNDldtE760VikF7NCGVJ+NCXaZZkw2oTJZuIK05aUNScI7VhCk0m4bOI+ZhRCwuksbVF2j4R7GshRyngk6eWNnJnZKpOyEHApmXw7sx+ZhI8e2vwy6VIAOzqTFZdQRvVFZlIebdjsJJQRBEEQBEEQBEEQBEEQhLf8A3phP/Llv413AAAAAElFTkSuQmCC')", backgroundSize: "cover" }}></Stack>
                                  <Typography sx={{mt:1, fontSize: 12, display: "flex", alignItems: "center", justifyContent:"center"}}>
                                    <img src={Star} />
                                    ({problemInfo.creatorData.points})
                                    </Typography>
                                </Stack>
                                <Stack sx={{ marginLeft: 3, display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                  <Typography onClick={() => setSendMsgToReporterModal(true)} sx={{ fontSize: 12, color: "#196A63", fontWeight: 700, width: 100 }}>Enviar mensagem para reportadora</Typography>
                                </Stack>
                              </Stack>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <Stack sx={{ width: 340, height: 134, backgroundColor: "grey", borderRadius: "8px" }}>

                                <LoadScript
                                  googleMapsApiKey="AIzaSyB6IN3OTFx7YSpTRVaM2uBKzIl-dzzTJEk"
                                >
                                  <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={{
                                      lat: problemInfo.coordinates[0],
                                      lng: problemInfo.coordinates[1]
                                    }}
                                    zoom={18}
                                  >
                                    <Marker
                                      key={problemInfo.id}
                                      position={{
                                        lat: problemInfo.coordinates[0],
                                        lng: problemInfo.coordinates[1]
                                      }}
                                    />
                                  </GoogleMap>

                                </LoadScript>

                              </Stack>
                              <Typography sx={{ marginTop: 2 }}>Status do problema</Typography>
                              <Stack sx={{ width: 150 }}>
                                <FormControl>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={problemStatus}
                                    label="Status do problema"
                                    onChange={handleChange}
                                  >
                                    <MenuItem value={0}>Em aberto</MenuItem>
                                    <MenuItem value={1}>Em andamento</MenuItem>
                                    <MenuItem value={2}>Finalizado</MenuItem>
                                  </Select>
                                </FormControl>
                              </Stack>

                              {
                                problemInfo.peopleSolving.count > 0 &&
                                <Stack sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                                  <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                                    Pesssoas participando dessa solução
                                  </Typography>

                                  <Stack sx={{ display: "flex", flexDirection: "row", overflowX: "auto", width: 300 }}>
                                    {
                                      problemInfo.peopleSolving.usersData.map((e, index) => {
                                        return (
                                          <Stack sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}>
                                            <Stack sx={{ mt: 1, display: "flex", alignItems: "center" }}>
                                              <Stack sx={{ backgroundSize: "cover", height: 50, width: 50, backgroundImage: e.profile_photo !== null ? `url('${e.profile_photo}')` : `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAANlBMVEXm5uampqajo6Pj4+O8vLzp6emgoKDg4OCqqqra2tq4uLitra3AwMDHx8ewsLC1tbXNzc3T09PfW5RvAAAEUElEQVR4nO2c2XbjIAxAWSy8b///s4OdNpPEToqNQIqP7lP7lnuEQQiBUoIgCIIgCIIgCIIgCIIgCIIgCIIgCILADPAoW3rs7e/vxdZTN2jjVoweuqm21L/pBLCINJV30A/4f6tmEfqmCIHyJtoZozcY47T3+Rodr9K3eyL/hdr+S3Sg7oZPKjedoav524D9HJXH6FjmOlCPOkBl1dEj7+BAHybyS8/XBqAJjMo9Og3XdRTKyv39+59xVcnSBurqYFzW2FQcPxyo2xMu3qblZwP1cMrF2wzsbOrxpIu3GWvqX/9MeXQee7JpSurf/0QX4eJtOurf/wDMUS7eZmb02cSpLFAb3IHm8GL5imuYhAbmaBdvw2WgtfEuWrfUFiswRX79N8zEIjQDhovWA7WHWgKD46I1h9CMWDIjtYmfyiosmYp8QoMOy0XrjlqmPJ8tv2JG4nzz7JZsV4Z6m3a0HPMZ6mJNXO7/DPFOAKI2ZRuZhrRUc37nvytDWw1AXGUWaFcavFzmBm1G0yOOMj/OekqXS8lYzJl5mZspz29th7Bj/o8TGaYy1MPsQt/MpWYzhVOZuctMtDKYLj4DoHS5Vm52qawZsQSwFgFIE03UuZl4ZkbeAxDXNGHGrM5QVwGvVDe7VkUTapSjpgXqGuBigzXOzEjucrHzGUAaZy0Dl4udaSqM/MxwONJUV+sDgCLaxhRMXJbmzNhGIEatmjCH9GV/cGHV1aT6qIHmaAsZr9iYz8YVvG7V+C3naRtHvMHccv7YmfyQeYeztQ2GncDqrA1Pl/W6yWEbw/baCdju2HpjTMf3QhCo6cg0YNqJ982zuggNjjEF1yH2iw9OYPm5Yh6WZfHsgzeebc9tsXwCyuBB9jPQ2OpA2biDOY1ztL0/byk7cyI9c6ajrmNuADsdvz33o1NNrBYbUHNzfntmTDPzmdjA9nH7ZlOxuRoMdURY7sHhsYDCjFM3Y1AEBIgcYnebqqe+GgzqYKL8wcZ0tNMAYLcCE9pAVFFmiysIJzVkF8qiEyB3m602ZFtP3GazG4bmlBbiSrLvcBS3G2KL5e+gKKKfe5chyCb72w2gMNuZXmzGzMsNZmPGlryTAHIH4Ct5OwJRe+a25G0JQr1ptke+ozTMK4D7ZDyzSZDGvJLvVsCcWmVhzuNii8SDbMFkyp/rDC7eJs/LOvHPf4TgmhwuZZbA+NBkWGsg/VR2w2VIaiCTi7dJLoPSXBYokzxDQ2uW/Zv07bQ2m4u3SbzUIHWXBsokLj8fflwySibxa1Q26abslSrpOIM6p4vWSTcCgHsv8y9M0hoaNDldtE760VikF7NCGVJ+NCXaZZkw2oTJZuIK05aUNScI7VhCk0m4bOI+ZhRCwuksbVF2j4R7GshRyngk6eWNnJnZKpOyEHApmXw7sx+ZhI8e2vwy6VIAOzqTFZdQRvVFZlIebdjsJJQRBEEQBEEQBEEQBEEQhLf8A3phP/Llv413AAAAAElFTkSuQmCC')`, borderRadius: 50 }}></Stack>
                                              <Typography sx={{ fontSize: 12 }}>
                                                {e.name.split(" ")[0]}
                                              </Typography>
                                              <Typography sx={{fontSize: 10, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                              <img src={Star} />
                                                ({e.points})
                                              </Typography>
                                            </Stack>
                                          </Stack>
                                        )
                                      })

                                    }
                                  </Stack>

                                </Stack>
                              }

                            </Box>

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius: 3,
                                padding: 2,
                                width: 300,
                                marginLeft: 5
                              }}
                            >
                              <TextareaAutosize
                                disabled={problemInfo.status === 2}
                                value={textAreaValue}
                                onChange={(h) => setTextAreaValue(h.target.value)}
                                minRows={3}
                                placeholder="Colocar aqui sobre a solução do problema. Essa mensagem aparecerá para todos os moderadores."
                                style={{
                                  width: '100%',
                                  border: 'none',
                                  height: "70px",
                                  padding: '12px',
                                  borderRadius: '12px',
                                  boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                                  resize: 'none',
                                  marginBottom: '16px',
                                  outline: "none",
                                  border: "1px solid black"
                                }}
                              />
                              {
                                !isLoading &&
                                <Stack direction="column" spacing={2} sx={{ width: '100%', justifyContent: 'space-between' }}>

                                  <Button
                                    disabled={problemInfo.status === 2}
                                    onClick={() => document.getElementById("file")?.click()}
                                    variant="contained"
                                    startIcon={<AttachFileIcon />}
                                    sx={{
                                      bgcolor: 'lightgray',
                                      ':hover': { bgcolor: 'gray' },
                                      borderRadius: 20,
                                      textTransform: 'none',
                                      fontSize: '0.875rem',
                                      fontWeight: 'regular',
                                      paddingX: 4
                                    }}
                                  >

                                    <input type="file" id="file" accept="video/mp4" style={{ display: "none" }} />
                                    Anexar arquivo
                                  </Button>
                                  <Button
                                    disabled={problemInfo.status === 2}
                                    onClick={() => solveProblem(problemInfo.id)}
                                    variant="contained"
                                    sx={{
                                      bgcolor: 'yellowgreen',
                                      ':hover': { bgcolor: 'darkolivegreen' },
                                      borderRadius: 20,
                                      textTransform: 'none',
                                      fontSize: '0.875rem',
                                      fontWeight: 'regular',
                                      paddingX: 4
                                    }}
                                  >
                                    Solucionar Problema
                                  </Button>
                                </Stack>
                              }
                              {isLoading && <CircularProgress />}
                            </Box>

                          </Box>
                        </Box>

                      </div>}

                    {sendMsgToReporterModal &&

                      <div style={{ display: "flex", zIndex: 10, justifyContent: "center", alignItems: "center", position: "fixed", top: "0px", left: "0px", height: "100%", width: "100%" }}>
                        <div onClick={() => setSendMsgToReporterModal(false)} style={{ zIndex: 11, display: "flex", position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%" }}>
                        </div>

                        <div style={{ zIndex: 12, alignItems: "center", flexDirection: "column", border: "1px solid black", boxShadow: "1px 2px 5px 2px black", display: "flex", width: 300, height: 300, borderRadius: 20, backgroundColor: "white" }}>

                          <Typography>
                            Digite sua mensagem
                          </Typography>

                          <TextareaAutosize
                            minRows={3}
                            placeholder="Digite a mensagem que você deseja enviar para a moderadora do caso"
                            value={msgToReporter}
                            onChange={handleMsgToReporter}
                            style={{
                              width: '90%',
                              border: 'none',
                              height: "70px",
                              padding: '12px',
                              borderRadius: '12px',
                              boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                              resize: 'none',
                              marginBottom: '16px',
                              outline: "none"
                            }}
                          />

                          <Button onClick={() => { sendSMStoReporter() }} sx={{ backgroundColor: "#D3F26A", color: "black", m: 1 }}>Enviar</Button>

                        </div>

                      </div>

                    }

                  </>
                )
              })

            }
          </Stack>
        </Box>
      </Collapse>
    </Stack>
  );
}
