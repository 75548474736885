import React, { useState, useEffect } from 'react';
import { Stack, Typography, IconButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Edit from '@mui/icons-material/Edit';
import PinMap from "../../../assets/pinMap.svg";
import AdminServices from '../../../Services/AdminServices.ts';
import CircularProgress from '@mui/material/CircularProgress';

const adminService = new AdminServices();

export default function Highlight({ id, color, highlightInformation, handleCurrentHighlight }) {

    const [isEditting, setIsEditting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightInfo, setHighlightInfo] = useState({
        title: "",
        imageUrl: "",
    })

    useEffect(() => {
        setHighlightInfo({ ...highlightInformation })
    }, [highlightInformation])

    const editHighlightTitle = async () => {
        setIsLoading(true);
        try {
            const res = await adminService.updateHighlightTitle(id, highlightInfo.title);

            if (res.status) {
                setIsEditting(false)
                alert("Título atualizado")
            } else {
                alert("Não foi possível atualizar o título")
            }
        } catch (e) {
            alert("Não foi possível atualizar o título")
        }
        setIsLoading(false);
    }

    const editHighlightImage = async () => {
        setIsLoading(true);
        try {
            const imageFileInput = document.getElementById("file"+id);
            if (imageFileInput && imageFileInput.files.length > 0) {
                const image = imageFileInput.files[0];

                const res = await adminService.updateHighlightImage(id, image);

                if (res.status) {
                    setIsEditting(false)
                    window.location.reload();
                    alert("Destaque atualizado")
                } else {
                    alert("Não foi possível atualizar o destaque")
                }
            }
        } catch (e) {
            alert("Não foi possível atualizar o destaque")
        }
        setIsLoading(false);
    }

    return (
        <Stack
            sx={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "center" }}
        >
            {
                !isLoading &&
                <Stack onMouseOver={() => handleCurrentHighlight(highlightInfo.imageUrl)} onMouseOut={() => { handleCurrentHighlight(null) }} onClick={() => document.getElementById('file'+id)?.click()} sx={{ p: 0.5, m: 1, height: 80, width: 80, borderRadius: 50, border: "1px solid #D3F26A" }}>
                    <Stack sx={{ height: "100%", width: "100%", borderRadius: 50, backgroundColor: color, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={PinMap} height={50} />
                    </Stack>
                </Stack>
            }
            {
                isLoading &&
                <CircularProgress />
            }

            <input onChange={editHighlightImage} style={{ display: "none" }} id={"file"+id} type="file" />
            {
                !isEditting &&
                <Stack sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography>
                        {highlightInfo.title}
                    </Typography>
                    <IconButton onClick={() => setIsEditting(true)} sx={{ padding: 0 }}><Edit /></IconButton>
                </Stack>
            }
            {
                isEditting &&
                <Stack sx={{ flexDirection: "row" }}>
                    <input
                        value={highlightInfo.title}
                        onChange={
                            (h) => {
                                setHighlightInfo((prev) => {
                                    const data = { ...prev };
                                    data.title = h.target.value;
                                    return data;
                                })
                            }
                        }
                        style={{ width: 80, outline: "none", border: "none", padding: 4, borderRadius: 6 }} type="text" placeholder='título' />
                    <IconButton onClick={editHighlightTitle} sx={{ padding: 0 }}><CheckIcon /></IconButton>
                </Stack>
            }

        </Stack>
    );
}
