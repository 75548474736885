import React, { useEffect, useState } from "react";
import Search from "./search";
import styles from "./style.module.css";
import Problems from "./problems";
import ProblemsInfo from "./problemsInfo";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import { Typography, Box, Stack } from "@mui/material";
import Card from "../problemReportsScreen/components/card.tsx";

const Home = ({ ProblemReports, generalData }) => {
  // Armazenando as categorias e suas contagens
  const [categoryData, setCategoryData] = useState([]);
  const [problems, setProblems] = useState([]);
  const [currentProblemCoordinates, setCurrentProblemCoordinates] = useState({ lat: -8.0086, lng: -34.8557 })
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showMapCurrentProblem, setShowMapCurrentProblem] = useState(false);
  const [currentProblemInfo, setCurrentProblemInfo] = useState();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [markerIcons, setMarkerIcons] = useState({});



  useEffect(() => {
  

    if (!window.google || !window.google.maps) {
      return;
    }

  // Agrupar os problemas por categoria
  const categoryProblems = {};
  problems.forEach((problem) => {
    // Verifica se o problema tem uma categoria válida
    if (problem.category) {
      if (!categoryProblems[problem.category]) {
        categoryProblems[problem.category] = [];
      }
      categoryProblems[problem.category].push(problem);
    }
  });

  // Definir as cores disponíveis
  const colors = ['blue', 'red', 'green', 'yellow', 'purple', 'orange'];

  // Definir os ícones dos marcadores com base nas categorias
  const icons = {};
  Object.keys(categoryProblems).forEach((category, index) => {
    const colorIndex = index % colors.length; // Selecionar uma cor da lista de cores
    const color = colors[colorIndex];
    icons[category] = {
      url: `https://maps.google.com/mapfiles/ms/icons/${color}-dot.png`, // URL da imagem do ícone
      scaledSize: new window.google.maps.Size(40, 40), // Tamanho do ícone
    };
  });

  // Define os ícones dos marcadores no estado
  setMarkerIcons(icons);
}, [problems]);





  useEffect(() => {
    const updateMousePosition = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    // Adiciona listeners para eventos de movimento do mouse
    window.addEventListener("mousemove", updateMousePosition);

    // Remove o listener quando o componente é desmontado
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  useEffect(() => {
    setForceUpdate(prevState => !prevState);
  }, [problems, currentProblemCoordinates]);



  useEffect(() => {
    const categoryCounts = {}; // Armazenar contagens de cada categoria

    // Contar ocorrências de cada categoria
    if (ProblemReports != null) {
      ProblemReports.forEach((report) => {
        if (categoryCounts[report.category]) {
          categoryCounts[report.category] += 1;
        } else {
          categoryCounts[report.category] = 1;
        }
      });
      if (generalData != null && generalData.currentSecretariat !== "all") {
        setProblems(prev => {
          console.log("filtrando")
          const data = [...ProblemReports];
          return data.filter(e => e.category === generalData.currentSecretariat);
        });
      } else {
        console.log("filtrando")
        setProblems(ProblemReports);
      }
      const totalReports = ProblemReports.length;
      const categories = Object.keys(categoryCounts).map((category) => ({
        name: category,
        count: categoryCounts[category],
        percentage: ((categoryCounts[category] / totalReports) * 100).toFixed(2) // Calcula a porcentagem e arredonda para duas casas decimais
      }));

      setCategoryData(categories);
      console.log(categories);
    }


    // Calcular total de relatórios para porcentagem
    // Debugging para ver os dados calculados
  }, [ProblemReports, generalData]);


  const handleProblemMap = (index) => {
    setCurrentProblemCoordinates({ lat: problems[index].coordinates[0], lng: problems[index].coordinates[1] });
    setCurrentProblemInfo(problems[index]);
    setShowMapCurrentProblem(true);
  }

  return (
    <div className={styles.home}>
      <div className={styles.main}>

        <img className={styles.banner} src="./assets/DashboardMunicipal.png" alt="" />
        {generalData != null && generalData.currentSecretariat === "all" && false &&
          <div className={styles.problems}>
            <h1>Resumo de problemas relatados por departamento</h1>
            <div className={styles.grid}>
              {categoryData.map((category, index) => (
                <Problems key={category.name} color={index} percentage={`${category.percentage}%`} number={category.count.toString()} content={category.name} />
              ))}
            </div>
          </div>
        }

        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <Card allowExpand={false} categories={generalData.categories} icon={"/assets/icon _mobile phone.svg"} number={problems.length} title={"Relatados (all)"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
          <Card allowExpand={false} categories={generalData.categories} icon={"/assets/problemSolvedIcon.svg"} number={problems.filter(e => e.status === 2).length} title={"Resolvidos"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />
          <Card allowExpand={false} categories={generalData.categories} icon={"/assets/fireIcon.svg"} number={problems.filter(e => e.status === 1).length} title={"Em andamento"} iconBgcolor={"rgba(0, 176, 116, 0.15)"} />

        </Box>

        <LoadScript
          googleMapsApiKey="AIzaSyB6IN3OTFx7YSpTRVaM2uBKzIl-dzzTJEk"
        >
          <GoogleMap
            id="map"
            mapContainerStyle={{ height: "400px", width: "100%" }}
            center={currentProblemCoordinates}
            zoom={14}
            options={{
              suppressMarkers: true
            }}
          >
            {/* Renderiza os marcadores no mapa */}
            
            {problems.map((problem, index) => {
              const icon = markerIcons && markerIcons[problem.category] ? markerIcons[problem.category] : null;
              return (
                <Marker
                  key={`marker-${index}`}
                  icon={icon} // Usa o ícone correspondente à categoria se existir, senão usa null
                  position={{ lat: problem.coordinates[0], lng: problem.coordinates[1] }}
                  // onMouseOver={() => handleProblemMap(index)}
                  // onMouseOut={() => setShowMapCurrentProblem(false)}
                  title={`Problema: ${problem.title} \nCategoria: ${problem.category}`}
                />
              );
            })}
          </GoogleMap>
        </LoadScript>


        <div className={styles.problemsInfo}>
          <div className={styles.problemsButton}>
            <p>Últimos problemas relatados</p>
            <button onClick={(() => window.location.href = "/problemas-relatados")}>VER TODOS</button>
          </div>
          {problems.map(e => <ProblemsInfo key={e.id} problemInfo={e} />)}
        </div>
      </div>

      {showMapCurrentProblem &&
        <div style={{ padding: 5, top: mousePosition.y - 160, left: mousePosition.x, position: "fixed", height: 100, width: 170, backgroundColor: "white", borderRadius: 10 }}>

          <Typography>
            {currentProblemInfo.title}
          </Typography>
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ mr: 0.5 }}>Categoria: </Typography>
            <Typography sx={{ fontWeight: 600 }}>
              {currentProblemInfo.category}
            </Typography>
          </Stack>
        </div>
      }


    </div >
  );
};

export default Home;
