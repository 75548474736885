import React, {useState} from 'react'
import styles from './style.module.css'
import Navlink from './navlink'
import CircularProgress from '@mui/material/CircularProgress';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';


const Sidebar = ({login, isLoadingProp, generalData}) => {


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (event) => {
    setIsLoading(true)
    event.preventDefault(); // Prevenir o recarregamento da página

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Login bem-sucedido
        window.location.reload();
        setIsLoading(false)
        // Redirecione o usuário ou atualize o estado da aplicação conforme necessário
      })
      .catch((error) => {
        setIsLoading(false)
        setError('Falha no login: ' + error.message);
        console.error('Falha no login', error);
      });
  };


  return (
    
    
    
    <div className={styles.sidebar}>
      {(!isLoadingProp) && 
      <div>
        <div className={styles.folder}>
        <img src="/assets/logo.png" alt="" height={150}/>
        
        <p>Seja bem vindo</p>
      </div>
      <div>
        {!login && <h1 className={styles.title}> <img src="./assets/grid.svg" alt=""/><a href="/home">Dashboard</a></h1>}

        {login &&
        <div style={{display: "flex", flexDirection: "column"}}>
        <input onChange={(e)=>{setEmail(e.target.value)}} value={email} style={{height: 30, marginBottom: 10, borderRadius: 5, outline: "none", border: "none"}} type="text" placeholder='Email' />
        
        <input onChange={(e)=>{setPassword(e.target.value)}} value={password} style={{height: 30, borderRadius: 5, outline: "none", border: "none"}} type="password" placeholder='Senha' />
      
        {!isLoading && <button onClick={handleLogin} style={{marginTop: 10, padding: 5, borderRadius: 10, outline: "none", border: "none", backgroundColor: "#D3F26A"}}>Logar</button>}
        {isLoading && <CircularProgress />}
      </div>
        }

        <Navlink generalData={generalData} login={login}/>
        
      </div>
      </div>
      }
      {isLoadingProp && <CircularProgress />}
  
    </div>
      
  )
}

export default Sidebar