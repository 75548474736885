import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Aside from './components/aside/index.jsx';
import Home from './components/home/index.jsx';
import Sidebar from './components/sidebar/index.jsx';
import ProblemReportServices from "./Services/ProblemReportServices.ts";
import AdminServices from "./Services/AdminServices.ts";
import ProblemReportsScreen from "./components/problemReportsScreen/index.tsx";
import Registers from "./screens/registersScreen/index.tsx";
import SuspendUser from "./screens/registersScreen/suspendUser.tsx";
import CityServicesScreen from './screens/CityServicesScreen/index.tsx';
import AIQueriesScreen from "./screens/AIQueriesScreen/index.tsx";
import CarpoolingScreen from './screens/carpoolingScreen/index.tsx';
import DirectMessage from './screens/ComunicatingScreens/DirectMessage.tsx';
import OfficialComunicate from './screens/ComunicatingScreens/OfficialComunicate.tsx';
import AllCarpooling from './screens/carpoolingScreen/allCarpooling.tsx';
import UrgentAnnouncement from './screens/ComunicatingScreens/UrgentAnnouncement.tsx';
import SuspendDriver from './screens/carpoolingScreen/suspendUser.tsx';
import SuspendService from './screens/carpoolingScreen/suspendService.tsx';
import RegisterCityService from './screens/CityServicesScreen/registerCityService.tsx';
import RegisterNotice from './screens/CityServicesScreen/registerNotice.tsx';
import RegisterSecretariats from './screens/Secretariats/registerSecretariats.tsx';
import RegisterUser from './screens/Secretariats/registerUser.tsx';
import EditUser from './screens/Secretariats/editUser.tsx';
import EditSecretariats from './screens/Secretariats/editSecretariats.tsx';
import SecretariatComunicate from './screens/Secretariats/secretariatComunicate.tsx';
import OpenProblems from './components/problemReportsScreen/OpenProblems.tsx';
import OnGoingProblems from './components/problemReportsScreen/OnGoingProblems.tsx';
import ByNeighborhood from './components/problemReportsScreen/ByNeighborhood.tsx';
import { getAuth, onAuthStateChanged, signOut  } from "firebase/auth";
import SentComunicates from './screens/ComunicatingScreens/SentComunicates.tsx';
import CreatedServices from './screens/CityServicesScreen/CreatedServices.tsx';
import HighlightScreen from './screens/HighlightsScreen/index.tsx';
import SpecificReports from './components/problemReportsScreen/SpecificReports.tsx';
const adminService = new AdminServices();
function App() {
  const [problemReports, setProblemReports] = useState([]);
  const [adminData, setAdminData] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    
  }, []);

  useEffect(() => {

    const fetchAll = async () => {
      const problemReportService = new ProblemReportServices();
      const reports = await problemReportService.GetAllReports(9);
      
      adminService.clearChat();

      const auth = getAuth();
      try {
        const user = await new Promise((resolve, reject) => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            unsubscribe();
            resolve(user);
          }, reject);
        });
        setCurrentUser(user);
        const generalData = await adminService.GetGeneralData(auth.currentUser.uid);
        setProblemReports(reports);
        setAdminData(generalData);
      } catch (error) {
        console.error('Erro ao verificar usuário:', error);
      } finally {
        setIsLoading(false);
      }

  };

    fetchAll();

  }, []);

  if (isLoading) {
    // Renderiza um indicador de carregamento enquanto verifica a autenticação do usuário
    return (
      <Router>
      <div style={{display: "flex", flexDirection: "row", backgroundColor: "#ECECEC"}}>
        <Sidebar login={true} isLoadingProp={true}/>
      </div>
    </Router>
    )
  }



  

  

  if (currentUser) {
    return (
      <Router>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "#ECECEC"}}>
          <Sidebar generalData={adminData}/>
          <Routes>
            <Route path="/" element={
              <>
                <div style={{width: "100%",display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Home generalData={adminData} ProblemReports={problemReports}/>
                  <Aside adminData={adminData}/>
                </div>
              </>
            } />
            <Route path="/home" element={
              <>
                <div style={{width: "100%",display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Home generalData={adminData} ProblemReports={problemReports}/>
                  <Aside adminData={adminData}/>
                </div>
              </>
            } />
            <Route path="/problemas-relatados" element={<ProblemReportsScreen generalData={adminData}/>} />
            <Route path="/cadastros" element={<Registers generalData={adminData}/>} />
            <Route path="/suspender-usuario" element={<SuspendUser generalData={adminData}/>} />
            <Route path="/cityServices" element={<CityServicesScreen generalData={adminData}/>} />
            <Route path="/consultas-ia" element={<AIQueriesScreen generalData={adminData}/>} />
            <Route path="/carona-solidaria" element={<CarpoolingScreen generalData={adminData}/>} />
            <Route path="/mensagens-diretas" element={<DirectMessage generalData={adminData}/>} />
            <Route path="/comunicado-oficial" element={<OfficialComunicate generalData={adminData}/>} />
            <Route path="/caronas-solidarias" element={<AllCarpooling generalData={adminData}/>} />
            <Route path="/comunicado-urgente" element={<UrgentAnnouncement generalData={adminData}/>} />
            <Route path="/suspender-motorista" element={<SuspendDriver generalData={adminData}/>} />
            <Route path="/suspender-carona-solidaria" element={<SuspendService generalData={adminData}/>} />
            <Route path="/registrar-servico-municipal" element={<RegisterCityService generalData={adminData}/>} />
            <Route path="/cadastrar-noticias" element={<RegisterNotice generalData={adminData}/>} />
            <Route path="/registrar-secretaria" element={<RegisterSecretariats generalData={adminData}/>} />
            <Route path="/cadastrar-usuario" element={<RegisterUser generalData={adminData}/>} />
            <Route path="/editar-usuario" element={<EditUser generalData={adminData}/>} />
            <Route path="/editar-secretaria" element={<EditSecretariats generalData={adminData}/>} />
            <Route path="/comunicado-secretaria" element={<SecretariatComunicate generalData={adminData}/>} />
            <Route path="/problemas-abertos" element={<OpenProblems generalData={adminData}/>} />
            <Route path="/problemas-em-andamento" element={<OnGoingProblems generalData={adminData}/>} />
            <Route path="/por-bairro" element={<ByNeighborhood generalData={adminData}/>} />
            <Route path="/relatorios-especificos" element={<SpecificReports/>} />
            <Route path="/comunicados-enviados" element={<SentComunicates generalData={adminData}/>} />
            <Route path="/servicos" element={<CreatedServices generalData={adminData}/>} />
            <Route path="/cadastrar-destaques" element={<HighlightScreen generalData={adminData}/>} />
            
          </Routes>
        </div>
      </Router>
    );
  } else {
      return (
      <Router>
        <div style={{display: "flex", flexDirection: "row", backgroundColor: "#ECECEC"}}>
          <Sidebar login={true}/>
        </div>
      </Router>
    );
  }


  
}

export default App;
